import { Facility } from "../axios";

export const viewallFacility = async (data) => {
  var viewallFacility = await Facility.post(`/viewallFacility`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewallFacility;
};

export const createFacility = async (data) => {
  var createFacility = await Facility.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return createFacility;
};

export const updateFacility = async (data) => {
  var updateFacility = await Facility.post(`/update`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return updateFacility;
};

export const destroyFacility = async (data) => {
  var destroyFacility = await Facility.post(`/destroy`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return destroyFacility;
};
