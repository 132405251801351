import React, { useEffect, useState } from "react";
import "react-rangeslider/lib/index.css";
import WestIcon from "@mui/icons-material/West";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { firebase } from "../database/firebase";
import { IoMdTrash } from "react-icons/io";
import { createCover, destroyCover, viewallCover } from "../Api/cover";

const CoverPicture = () => {
  var adminid =
    sessionStorage.getItem("adminid") || localStorage.getItem("adminid");
  var role = sessionStorage.getItem("role") || localStorage.getItem("role");

  const [languageslistdata, setlanguageslistdata] = useState([]);
  const [addnew, setaddnew] = useState(false);
  const [formdata, setformdata] = useState({
    image: "",
  });
  const [disablebtn, setdisablebtn] = useState(false);

  useEffect(() => {
    getSelectedPractics();
  }, [adminid]);
  const getSelectedPractics = async () => {
    var alllandata = await viewallCover();
    setlanguageslistdata(alllandata);
  };

  const geturl = async (e) => {
    toast.info("Please Wait Image is uploading...", {
      autoClose: 2000,
      transition: Slide,
    });
    const fileSize = e.target.files[0].size;
    const fileMb = fileSize / 1024 ** 2;
    if (fileMb >= 2) {
      toast.error("Please select a file less than 2MB.", {
        autoClose: 5000,
        transition: Slide,
      });
    } else {
      let file = e.target.files;
      let file13 = new Promise((resolve, reject) => {
        var storageRef = firebase.storage().ref("course/" + file[0].name);
        storageRef.put(file[0]).then(function (snapshot) {
          storageRef.getDownloadURL().then(function (url) {
            setTimeout(() => resolve(url), 1000);
          });
        });
      });
      var imgurl1 = await file13;
      if (imgurl1 !== null) {
        toast.success("Image uploaded...", {
          autoClose: 5000,
          transition: Slide,
        });
        setformdata((values) => ({ ...values, image: imgurl1 }));
      }
    }
  };

  const savebtn = async () => {
    if (formdata.image.length === 0) {
      toast.error("Please Uplaod Image...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      setdisablebtn(true);
      var craeatedata = await createCover(formdata);
      if (craeatedata.message === "SUCCESS") {
        toast.success("Cover Items added Successfully...", {
          autoClose: 2000,
          transition: Slide,
        });
        setdisablebtn(false);
        setTimeout(() => {
          getSelectedPractics();
          setaddnew(false);
          setdisablebtn(false);
        }, 2000);
      } else {
        setdisablebtn(false);
        toast.error(craeatedata.message, {
          autoClose: 2000,
          transition: Slide,
        });
      }
    }
  };

  const deletebtn = async (e) => {
    var deletebtn = await destroyCover({ id: e });
    if (deletebtn === "Deleted Successfully") {
      getSelectedPractics();
    }
  };
  return (
    <>
      {addnew === false ? (
        <div className="px-14 pb-5">
          <div className="flex justify-between items-center">
            <h1 className="text-2xl py-4 font-black pb-5">Cover Items</h1>
            {role === "admin" ? (
              <button
                className="bg-black text-white rounded w-[10rem] font-md py-4"
                onClick={() => {
                  setaddnew(true);
                }}
              >
                Add Cover Items
              </button>
            ) : null}
          </div>
          <hr className="py-5 mt-5 border-black" />
          <div className="grid md:grid-cols-3 grid-cols-1 gap-6">
            {languageslistdata.length !== 0
              ? languageslistdata.map((data, index) => (
                  <div
                    className="flex flex-col rounded-sm justify-evenly shadow-2xl bg-white rounded-xl cursor-pointer py-5"
                    key={index}
                  >
                    <img
                      src={data.image}
                      alt="Logo"
                      className="rounded w-full object-contain h-[25rem]"
                    />
                    <div className="px-3 pb-2 border-t mt-5 border-black flex justify-between items-center gap-2">
                      {role === "admin" ? (
                        <IoMdTrash
                          className="text-xl text-red-600 cursor-pointer text-right my-2"
                          onClick={() => {
                            deletebtn(data.id);
                          }}
                        />
                      ) : null}
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      ) : (
        <div className="px-14 min-h-screen">
          <div
            className="flex gap-2 items-center cursor-pointer"
            onClick={() => {
              setaddnew(false);
              setformdata({
                image: "",
              });
            }}
          >
            <WestIcon />
            <h1 className="text-2xl py-4 font-black">Add Cover Items</h1>
          </div>
          <div className="w-full bg-white rounde shadow-2xl p-7 my-5 ">
            <div className="grid grid-cols-1 md:grid-cols-1 gap-8 mb-5">
              <div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Image
                  </h1>
                  <input
                    placeholder="Last Name"
                    type="file"
                    name="file"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={geturl}
                  />
                </div>
              </div>
            </div>
            <div className="py-8">
              {disablebtn === true ? (
                <button className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5">
                  Please Wait..
                </button>
              ) : (
                <button
                  className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5"
                  onClick={savebtn}
                >
                  Save
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </>
  );
};

export default CoverPicture;
