import React, { useEffect } from "react";
import Failedimg from "../Assets/failedimg.png";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const FailStatus = () => {
  useEffect(() => {
    toast.error("Payment failed...", {
      autoClose: 2000,
      transition: Slide,
    });
    localStorage.clear();
    sessionStorage.clear();
    setTimeout(() => {
      window.location.replace("/");
    }, 2000);
  }, []);

  return (
    <>
      <div className="ErrorHandling">
        <div className="ErrorHandling_content">
          <img src={Failedimg} alt="Failed payment" className="okimg" />
          <h2 className="mt-5">Payment Failed...</h2>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default FailStatus;
