import React, { useEffect, useState } from "react";
import "./Quiz.css";
import { viewallCategory } from "../../Api/category";
import { FaInfoCircle } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import { FaRegCopy } from "react-icons/fa";
import copy from "copy-to-clipboard";

const Students = () => {
  const [categorydata, setcategorydata] = useState([]);
  useEffect(() => {
    getalldata();
  }, []);
  const getalldata = async () => {
    var allcategory = await viewallCategory();
    setcategorydata(allcategory);
  };
  const copyclipboard = async (slug) => {
    copy(`https://quiz.codepurple.in/#/${slug}`);
    alert(`Link Copied.."https://quiz.codepurple.in/#/${slug}"`);
  };
  return (
    <>
      <div className="w-full px-14 min-h-screen">
        <div className="flex justify-between py-5 items-center">
          <h1 className="text-2xl py-4 font-black">Quiz Programs</h1>
        </div>
        <div className="Quiz-grid">
          <div
            className="grid-box1 cursor-pointer"
            onClick={() => {
              window.location.replace("/#/addquiz");
            }}
          >
            <h2>+ Add</h2>
          </div>
          {categorydata.length !== 0
            ? categorydata.map((data, index) => (
                <div className="grid-box" key={index}>
                  <div className="flex justify-between w-full items-center">
                    <h1>{data.name}</h1>
                    <div className="flex gap-4 float-right mt-3">
                      <FaInfoCircle
                        className="text-xl text-black cursor-pointer"
                        onClick={() => {
                          sessionStorage.setItem("categoryid", data.id);
                          window.location.replace("/#/viewquiz");
                        }}
                      />
                      <FaEdit
                        className="text-xl text-black cursor-pointer"
                        onClick={() => {
                          sessionStorage.setItem("categoryid", data.id);
                          window.location.replace("/#/addquiz");
                        }}
                      />
                      <FaRegCopy
                        className="text-xl text-black cursor-pointer"
                        onClick={() => {
                          copyclipboard(data.slug);
                        }}
                      />
                    </div>
                  </div>
                  <p>Questions {data.Questions.length}</p>
                </div>
              ))
            : null}
        </div>
      </div>
    </>
  );
};

export default Students;
