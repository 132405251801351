import { Project } from "../axios";

export const createProject = async (data) => {
  var createProject = await Project.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return createProject;
};

export const ProjectbyStudent = async (data) => {
  var ProjectbyStudent = await Project.post(`/projectbystudent`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return ProjectbyStudent;
};

export const destroyProject = async (data) => {
  var destroyProject = await Project.post(`/destroy`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return destroyProject;
};

export const updateProject = async (data) => {
  var updateProject = await Project.post(`/update`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return updateProject;
};
