import { Payment } from "../axios";

export const viewallPayment = async (data) => {
  var viewallPayment = await Payment.post(`/viewallpayment`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewallPayment;
};

export const createPayment = async (data) => {
  var createPayment = await Payment.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return createPayment;
};

export const PaymentbyStudent = async (data) => {
  var PaymentbyStudent = await Payment.post(`/paymentbystudent`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return PaymentbyStudent;
};

export const updatePayment = async (data) => {
  var updatePayment = await Payment.post(`/update`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response;
    });
  return updatePayment;
};
export const destroyPayments = async (data) => {
  var destroyPayments = await Payment.post(`/destroy`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response;
    });
  return destroyPayments;
};
