import { Resume } from "../axios";

export const createResume = async (data) => {
  var createResume = await Resume.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return createResume;
};

export const ResumebyStudent = async (data) => {
  var ResumebyStudent = await Resume.post(`/resumebystudent`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return ResumebyStudent;
};

export const Resumeupdate = async (data) => {
  var Resumeupdate = await Resume.post(`/update`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return Resumeupdate;
};
