/* eslint-disable no-loop-func */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import WestIcon from "@mui/icons-material/West";
import { firebase } from "../../database/firebase";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { viewallLesson } from "../../Api/lesson";
import { useNavigate } from "react-router-dom";
import { createModules } from "../../Api/modules";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { IoMdCheckboxOutline } from "react-icons/io";
import { viewallstaff } from "../../Api/staff";

const Module = () => {
  const navigate = useNavigate();
  const [formdata, setformdata] = useState({
    title: "",
    description: "",
    shortdescription: "",
    duration: "",
    thumbnail: "",
    author: "",
    task: "",
  });
  const [disablebtn, setdisablebtn] = useState(false);
  const [selectoption, setselectoption] = useState(false);
  const [lessondata, setlessondata] = useState([]);
  const [selectedlesson, setselectedlesson] = useState([]);
  const [staffdata, setstaffdata] = useState([]);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setformdata((values) => ({ ...values, [name]: value }));
  };
  const geturl = async (e) => {
    toast.info("Please Wait Image is uploading...", {
      autoClose: 2000,
      transition: Slide,
    });
    const fileSize = e.target.files[0].size;
    const fileMb = fileSize / 1024 ** 2;
    if (fileMb >= 2) {
      toast.error("Please select a file less than 2MB.", {
        autoClose: 5000,
        transition: Slide,
      });
    } else {
      let file = e.target.files;
      let file13 = new Promise((resolve, reject) => {
        var storageRef = firebase.storage().ref("module/" + file[0].name);
        storageRef.put(file[0]).then(function (snapshot) {
          storageRef.getDownloadURL().then(function (url) {
            setTimeout(() => resolve(url), 1000);
          });
        });
      });
      var imgurl1 = await file13;
      if (imgurl1 !== null) {
        toast.success("Image uploaded...", {
          autoClose: 5000,
          transition: Slide,
        });
        setformdata((values) => ({ ...values, thumbnail: imgurl1 }));
        const file = document.querySelector(".file");
        const emptyFile = document.createElement("input");
        emptyFile.type = "file";
        file.files = emptyFile.files;
      }
    }
  };
  const gettask = async (e) => {
    toast.info("Please Wait Image is uploading...", {
      autoClose: 2000,
      transition: Slide,
    });
    const fileSize = e.target.files[0].size;
    const fileMb = fileSize / 1024 ** 2;
    if (fileMb >= 2) {
      toast.error("Please select a file less than 2MB.", {
        autoClose: 5000,
        transition: Slide,
      });
    } else {
      let file = e.target.files;
      let file13 = new Promise((resolve, reject) => {
        var storageRef = firebase.storage().ref("lesson/" + file[0].name);
        storageRef.put(file[0]).then(function (snapshot) {
          storageRef.getDownloadURL().then(function (url) {
            setTimeout(() => resolve(url), 1000);
          });
        });
      });
      var imgurl1 = await file13;
      if (imgurl1 !== null) {
        toast.success("File uploaded...", {
          autoClose: 5000,
          transition: Slide,
        });
        setformdata((values) => ({ ...values, task: imgurl1 }));
      }
    }
  };
  const savebtn = async () => {
    if (formdata.title.length === 0) {
      toast.error("Please enter Title...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.description.length === 0) {
      toast.error("Please enter Description...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.shortdescription.length === 0) {
      toast.error("Please enter Short Description...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.author.length === 0) {
      toast.error("Please Enter Trainer...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      setselectoption(true);
    }
  };
  useEffect(() => {
    getalllesson();
  }, []);
  const getalllesson = async () => {
    var alldata = await viewallLesson();
    setlessondata(alldata);
   
    var viewall_staff = await viewallstaff();
    if (viewall_staff.length !== 0) {
      var filterdata = await viewall_staff.filter((data) => {
        return data.role === "Developer Team";
      });
      setstaffdata(filterdata);
    }
  };
  const handlelessonSelection = (lesson) => {
    const updatedSelectedlessons = [...selectedlesson];
    if (updatedSelectedlessons.includes(lesson)) {
      updatedSelectedlessons.splice(updatedSelectedlessons.indexOf(lesson), 1);
    } else {
      updatedSelectedlessons.push(lesson);
    }
    setselectedlesson(updatedSelectedlessons);
    if (updatedSelectedlessons.length !== 0) {
      var newdata = [];
      for (var i = 0; i < updatedSelectedlessons.length; i++) {
        for (var j = 0; j < lessondata.length; j++) {
          if (lessondata[j].id == updatedSelectedlessons[i]) {
            newdata.push({
              id: updatedSelectedlessons[i].toString(),
              name: lessondata[j].title,
            });
          }
        }
      }
      setItems(newdata);
    } else {
      setItems([]);
    }
  };
  const removebtn = () => {
    setselectoption(!selectoption);
  };
  const submitbtn = async () => {
    if (selectedlesson.length === 0) {
      toast.error("Please Select Lesson...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      var durationdata = [];
      for (var i = 0; i < selectedlesson.length; i++) {
        var checkdata = await lessondata.filter((data) => {
          return data.id == selectedlesson[i];
        });
        if (checkdata.length !== 0) {
          durationdata.push(Number(checkdata[0].duration));
        }
      }
      var sumdata = durationdata.reduce((items, items2) => {
        return items + items2;
      }, 0);
      formdata["selectedlesson"] = JSON.stringify(selectedlesson);
      formdata["duration"] = sumdata;
      setdisablebtn(true);
      var createdata = await createModules(formdata);
      if (createdata.message === "SUCCESS") {
        toast.success("Modules Created Successfully...", {
          autoClose: 2000,
          transition: Slide,
        });
        setdisablebtn(false);
        setTimeout(() => {
          navigate("/courses");
        }, 2000);
      }
    }
  };
  const [items, setItems] = useState([]);
  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const reorderedItems = Array.from(items);
    const [removed] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, removed);
    setItems(reorderedItems);
  };
  return (
    <div className="px-14 min-h-screen">
      {selectoption === false ? (
        <div
          className="flex gap-2 items-center cursor-pointer"
          onClick={() => {
            navigate("/courses");
          }}
        >
          <WestIcon />
          <h1 className="text-2xl py-4 font-black">Create New Module</h1>
        </div>
      ) : (
        <div
          className="flex gap-2 items-center cursor-pointer"
          onClick={removebtn}
        >
          <WestIcon />
          <h1 className="text-2xl py-4 font-black">Select Lessons</h1>
        </div>
      )}

      <div className="w-full bg-white rounde shadow-2xl p-7 my-5 ">
        <div className="grid grid-cols-1 md:grid-cols-1 gap-8 mb-5">
          <div>
            {selectoption === false ? (
              <>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Title
                  </h1>
                  <input
                    placeholder="Title"
                    type="text"
                    name="title"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.title}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Description
                  </h1>
                  <textarea
                    placeholder="Description"
                    type="text"
                    name="description"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.description}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Short Description
                  </h1>
                  <textarea
                    placeholder="Short Description"
                    type="text"
                    name="shortdescription"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.shortdescription}
                  />
                </div>

                <div className="py-3">
                  <h1 className="text-lg">Thumbnail</h1>
                  <input
                    placeholder="Phone Number"
                    type="file"
                    name="thumbnail"
                    id="thumbnail"
                    className="border p-2 w-full py-2 file"
                    onChange={geturl}
                    // defaultValue={formdata.thumbnail}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg">Task</h1>
                  <input
                    placeholder="Phone Number"
                    type="file"
                    name="task"
                    className="border p-2 w-full py-2 file"
                    onChange={gettask}
                    // defaultValue={formdata.task}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Trainer
                  </h1>
                  <select
                    name="author"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.author}
                  >
                    <option>Select Trainer</option>
                    {staffdata.length !== 0
                      ? staffdata.map((data, index) => (
                          <option
                            key={index}
                            value={`${data.firstname} ${data.lastname}`}
                            selected={
                              formdata.author ==
                              `${data.firstname} ${data.lastname}`
                            }
                          >
                            {data.firstname} {data.lastname}
                          </option>
                        ))
                      : null}
                  </select>
                </div>
              </>
            ) : (
              <div className="py-3">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-5">
                  <div className="leftsidediv border-r-2 border-gray-300">
                    <h1 className="text-lg">Select Lesson</h1>
                    {lessondata.length !== 0
                      ? lessondata.map((data, index) =>
                          selectedlesson.some(
                            (obj) => obj == data.id
                          ) ? null : (
                            <div className="py-2 pl-5" key={index}>
                              <input
                                type="checkbox"
                                id={`lesson-${index}`}
                                checked={selectedlesson.includes(data.id)}
                                onChange={() => handlelessonSelection(data.id)}
                              />
                              <label className="" htmlFor={`lesson-${index}`}>
                                {" "}
                                {data.title}
                              </label>
                            </div>
                          )
                        )
                      : null}
                  </div>
                  <div>
                    <h1 className="text-lg">Selected Lesson</h1>
                    {/* {lessondata.length !== 0
                      ? lessondata.map((data, index) =>
                          selectedlesson.some((obj) => obj == data.id) ? (
                            <div className="py-2 pl-5" key={index}>
                              <input
                                type="checkbox"
                                id={`lesson-${index}`}
                                checked={selectedlesson.includes(data.id)}
                                onChange={() => handlelessonSelection(data.id)}
                              />
                              <label className="" htmlFor={`lesson-${index}`}>
                                {" "}
                                {data.title}
                              </label>
                            </div>
                          ) : null
                        )
                      : null} */}

                    <DragDropContext onDragEnd={handleDragEnd}>
                      <Droppable droppableId="droppable">
                        {(provided) => (
                          <ul
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            className={
                              items.length !== 0
                                ? `sortable-list mt-5 bg-slate-300 w-max p-2 rounded`
                                : `sortable-list`
                            }
                          >
                            {items.map((item, index) => (
                              <Draggable
                                key={item.id}
                                draggableId={item.id}
                                index={index}
                              >
                                {(provided) => (
                                  <li
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className="list-item p-2 w-max"
                                  >
                                    <div
                                      className="flex items-center bg-black rounded p-2 w-[25rem] h-[3rem]"
                                      onClick={() => {
                                        handlelessonSelection(Number(item.id));
                                      }}
                                    >
                                      <div className="my-5 w-[25rem] bg-black text-center grid items-center font-bold text-lg rounded flex gap-4">
                                        <div className="flex gap-4">
                                          <button className="p-1 mr-2">
                                            <IoMdCheckboxOutline className="h-4 text-white" />
                                          </button>
                                          <div className="mr-2 text-sm text-white">
                                            {item.name}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </ul>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="py-8">
          {selectoption === false ? (
            disablebtn === false ? (
              <button
                className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5"
                onClick={savebtn}
              >
                Next
              </button>
            ) : (
              <button className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5">
                Please wait...
              </button>
            )
          ) : disablebtn === false ? (
            <button
              className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5"
              onClick={submitbtn}
            >
              Submit
            </button>
          ) : (
            <button className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5">
              Please wait...
            </button>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Module;
