import { Courseproject } from "../axios";

export const CreateCourseproject = async (data) => {
  var CreateCourseproject = await Courseproject.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return CreateCourseproject;
};
