import { Languages_practice } from "../axios";

export const viewallLanguages_practice = async (data) => {
  var viewallLanguages_practice = await Languages_practice.post(
    `/viewalllanguage_practice`,
    data
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewallLanguages_practice;
};

export const createLanguages_practice = async (data) => {
  var createLanguages_practice = await Languages_practice.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return createLanguages_practice;
};

export const updateLanguages_practice = async (data) => {
  var updateLanguages_practice = await Languages_practice.post(`/update`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return updateLanguages_practice;
};

export const destroyLanguages_practice = async (data) => {
  var destroyLanguages_practice = await Languages_practice.post(
    `/destroy`,
    data
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return destroyLanguages_practice;
};

export const ViewbyLanguage = async (data) => {
  var ViewbyLanguage = await Languages_practice.post(`/viewbylanguage`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return ViewbyLanguage;
};
