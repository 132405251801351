import { Certificate } from "../axios";

export const CreateCertificate = async (data) => {
  var CreateCertificate = await Certificate.post(`/v1/generate`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return CreateCertificate;
};
