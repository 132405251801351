import { Contactuser } from "../axios";

export const viewallContactuser = async (data) => {
  var viewallContactuser = await Contactuser.post(`/viewallcontactuser`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewallContactuser;
};

export const destroyContactuser = async (data) => {
  var destroyContactuser = await Contactuser.post(`/destroy`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return destroyContactuser;
};
