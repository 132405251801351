/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import {
  ProjectbyStudent,
  createProject,
  destroyProject,
  updateProject,
} from "../Api/project";
import { IoInformationCircle } from "react-icons/io5";
import { MdDeleteOutline } from "react-icons/md";
import { AiOutlineGlobal } from "react-icons/ai";
import { FaRegUser } from "react-icons/fa";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import WestIcon from "@mui/icons-material/West";
import { viewallstaff } from "../Api/staff";
import { FaEdit } from "react-icons/fa";
import { FaGitAlt } from "react-icons/fa";
import JoditEditor from "jodit-react";
import HTMLReactParser from "html-react-parser";

const Project = () => {
  const editorRef = useRef(null);

  const [projectdata, setprojectdata] = useState([]);
  const [newmodel, setnewmodel] = useState(false);
  const [singledata, setsingledata] = useState([]);
  const [createstatus, setcreatestatus] = useState(false);
  const [formdata, setformdata] = useState({
    name: "",
    weblink: "",
    gitlink: "",
    coordinator: "",
    description: "",
  });
  const [clicked, setclicked] = useState(false);
  const [allstaffdata, setallstaffdata] = useState([]);
  const [updateid, setupdateid] = useState(null);

  var adminid =
    sessionStorage.getItem("adminid") || localStorage.getItem("adminid");
  useEffect(() => {
    getProjectdata();
  }, [adminid]);
  const getProjectdata = async () => {
    if (adminid !== null) {
      var projectdata = await ProjectbyStudent({ id: adminid });
      if (projectdata.length !== 0) {
        setprojectdata(projectdata);
      }
    }
    var allstaff = await viewallstaff();
    setallstaffdata(allstaff);
  };
  const openmodel = (data) => {
    setnewmodel(true);
    setsingledata([data]);
  };

  const config = {
    // Toolbar options
    toolbar: true, // Show the toolbar
    toolbarAdaptive: false, // Adaptive toolbar based on window width
    toolbarSticky: true, // Stick the toolbar to the top of the editor
    toolbarButtonSize: "middle", // Size of toolbar buttons: 'small', 'middle', 'big'
    toolbarButtonIcons: true, // Show icons on toolbar buttons
    toolbarButtonText: false, // Show text on toolbar buttons

    // Editor appearance
    theme: "default", // Editor theme: 'default', 'dark'
    language: "en", // Editor language

    // Editor behavior
    spellcheck: true, // Enable spellchecking
    showCharsCounter: true, // Show character counter
    showWordsCounter: true, // Show word counter
    hidePlaceholderOnFocus: false, // Hide the placeholder when the editor gains focus
    showXPathInStatusbar: true, // Show the XPath of the selected element in the status bar

    // Editor content
    allowScript: true, // Allow <script> tags in the editor content
    sanitize: false, // Sanitize the editor content to remove potentially unsafe elements and attributes

    // Plugins and features
    enableDragAndDropFileToEditor: true, // Enable dragging and dropping files into the editor to insert them
    enableResizeEditor: true, // Enable resizing of the editor
    enableSearchReplace: true, // Enable search and replace functionality
    enableCodeMirror: true, // Enable CodeMirror syntax highlighting for HTML source mode
  };
  const deletebtn = async (e) => {
    var result = await confirm("Are you sure you want to delete this project?");
    if (result === true) {
      var deletebtn = await destroyProject({ id: e });
      if (deletebtn === "Deleted Successfully") {
        toast.success("Project Deleted Successfully...", {
          autoClose: 2000,
          transition: Slide,
        });
        getProjectdata();
      }
    }
  };
  const handleChange = async (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setformdata((values) => ({ ...values, [name]: value }));
  };
  const savebtn = async () => {
    if (formdata.name.length === 0) {
      toast.error("Please Enter Project Name...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.weblink.length === 0) {
      toast.error("Please Enter Weblink...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.gitlink.length === 0) {
      toast.error("Please Enter Gitlink...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.coordinator.length === 0) {
      toast.error("Please Select Co -ordinator...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      setclicked(true);
      formdata["student"] = adminid;
      var createproject = await createProject(formdata);
      if (createproject.message === "SUCCESS") {
        toast.success("Project Details added Successfully...", {
          autoClose: 2000,
          transition: Slide,
        });
        setTimeout(() => {
          getProjectdata();
          setcreatestatus(false);
        }, 2000);
        setclicked(false);
      } else {
        setclicked(false);
        toast.success(createproject.message, {
          autoClose: 2000,
          transition: Slide,
        });
      }
    }
  };
  const Updatebtn = async () => {
    if (formdata.name.length === 0) {
      toast.error("Please Enter Project Name...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.weblink.length === 0) {
      toast.error("Please Enter Weblink...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.gitlink.length === 0) {
      toast.error("Please Enter Gitlink...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.coordinator.length === 0) {
      toast.error("Please Select Co -ordinator...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      formdata["id"] = updateid;
      setclicked(true);
      var updatedata = await updateProject(formdata);
      if (updatedata === "Updated Successfully") {
        toast.success("Project Details Updated Successfully...", {
          autoClose: 2000,
          transition: Slide,
        });
        setTimeout(() => {
          getProjectdata();
          setcreatestatus(false);
        }, 2000);
        setclicked(false);
      }
    }
  };
  const editbtn = async (data) => {
    setformdata({
      name: data.name,
      weblink: data.weblink,
      gitlink: data.gitlink,
      coordinator: data.coordinator,
      description: data.description,
    });
    setcreatestatus(true);
    setupdateid(data.id);
  };
  return (
    <>
      {createstatus === false ? (
        newmodel === false ? (
          <div className="px-14 pb-5">
            <div className="flex justify-between items-center">
              <h1 className="text-2xl py-4 font-black">My Project</h1>
              <button
                className="bg-black text-white rounded w-[10rem] font-md py-4"
                onClick={() => {
                  setcreatestatus(true);
                  setformdata({
                    name: "",
                    weblink: "",
                    gitlink: "",
                    coordinator: "",
                    description: "",
                  });
                  setupdateid(null);
                }}
              >
                Add Project
              </button>
            </div>
            <hr className="py-5 mt-5 border-black" />
            <div className="grid md:grid-cols-3 grid-cols-1 gap-6">
              {projectdata.length !== 0
                ? projectdata.map((data, index) => (
                    <div
                      className="flex flex-col rounded-sm justify-evenly shadow-2xl bg-white rounded-xl"
                      key={index}
                    >
                      <div className="px-3">
                        <div className="flex justify-between items-center pt-3">
                          <h1 className="text-xl pt-2 font-bold">
                            {data.name}
                          </h1>
                          <div className="flex gap-2">
                            <IoInformationCircle
                              className="h-5 text-xl cursor-pointer"
                              onClick={() => {
                                openmodel(data);
                              }}
                            />
                            <MdDeleteOutline
                              className="h-5 text-xl cursor-pointer"
                              onClick={() => {
                                deletebtn(data.id);
                              }}
                            />
                            <FaEdit
                              className="h-5 text-xl cursor-pointer"
                              onClick={() => {
                                editbtn(data);
                              }}
                            />
                          </div>
                        </div>
                        <div className="pb-5">
                          <div
                            className="flex gap-2 items-center mt-2 cursor-pointer"
                            onClick={() => {
                              window.open(data.weblink);
                            }}
                          >
                            <AiOutlineGlobal className="h-5 text-xl text-black" />
                            <p className="text-md text-gray-500">
                              {data.weblink}
                            </p>
                          </div>
                          <div className="flex gap-2 items-center mt-2">
                            <FaRegUser className="h-5 text-xl text-black" />
                            <p className="text-md text-gray-500">
                              {data.coordinator}
                            </p>
                          </div>
                          <p className="mt-2 smalltext">
                            {HTMLReactParser(data.description)}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
                : null}
            </div>
          </div>
        ) : (
          <>
            {singledata.length !== 0 ? (
              <div className="px-14 pb-5">
                <div
                  className="flex gap-2 items-center cursor-pointer"
                  onClick={() => {
                    setnewmodel(false);
                  }}
                >
                  <MdOutlineKeyboardBackspace className="text-3xl cursor-pointer" />
                  <h1 className="text-xl py-4 font-black cursor-pointer">
                    Back
                  </h1>
                </div>
                <hr className="py-5 mt-5 border-black" />
                <div className="flex justify-center px-4 py-2">
                  <h2 className="font-bold text-3xl text-center">
                    {singledata[0].name}{" "}
                  </h2>
                </div>
                <div className="px-3">
                  <p className="mt-5 text-xl">
                    {HTMLReactParser(singledata[0].description)}
                  </p>
                  <div className="pb-5 my-5 rounded shadow-2xl p-2 bg-white-100 border">
                    <div
                      className="flex gap-2 items-center mt-5 cursor-pointer"
                      onClick={() => {
                        window.open(singledata[0].weblink);
                      }}
                    >
                      <AiOutlineGlobal className="h-5 text-3xl text-gray-500" />
                      <p className="text-2xl">{singledata[0].weblink}</p>
                    </div>
                    <div
                      className="flex gap-2 items-center mt-5 cursor-pointer"
                      onClick={() => {
                        window.open(singledata[0].gitlink);
                      }}
                    >
                      <FaGitAlt className="h-5 text-3xl text-gray-500" />
                      <p className="text-2xl">{singledata[0].gitlink}</p>
                    </div>

                    <div className="flex gap-2 items-center mt-5">
                      <FaRegUser className="h-5 text-3xl text-gray-500" />
                      <p className="text-2xl">{singledata[0].coordinator}</p>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </>
        )
      ) : (
        <div className="px-14 min-h-screen">
          <div
            className="flex gap-2 items-center cursor-pointer"
            onClick={() => {
              setcreatestatus(false);
              setformdata({
                name: "",
                weblink: "",
                gitlink: "",
                coordinator: "",
                description: "",
              });
              setupdateid(null);
            }}
          >
            <WestIcon />
            {updateid !== null ? (
              <h1 className="text-2xl py-4 font-black">Update Project</h1>
            ) : (
              <h1 className="text-2xl py-4 font-black">Add New Project</h1>
            )}
          </div>
          <div className="w-full bg-white rounde shadow-2xl p-7 my-5 ">
            <div className="grid grid-cols-1 md:grid-cols-1 gap-8 mb-5">
              <div>
                <div className="py-3">
                  <h1 className="text-lg">Project Name</h1>
                  <input
                    placeholder="Project Name"
                    type="text"
                    name="name"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    value={formdata.name}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg">Web Link</h1>
                  <input
                    placeholder="Web Link"
                    type="text"
                    name="weblink"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.weblink}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg">Description</h1>
                  <JoditEditor
                    ref={editorRef}
                    value={formdata.description}
                    config={config}
                    tabIndex={1}
                    onBlur={(newContent) =>
                      setformdata((values) => ({
                        ...values,
                        description: newContent,
                      }))
                    }
                    className="subjecttag"
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg">Git Link</h1>
                  <input
                    placeholder="Git Link"
                    type="text"
                    name="gitlink"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.gitlink}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg">Co-ordinator</h1>
                  <select
                    className="border-2 p-2 w-full py-2 rounded"
                    name="coordinator"
                    onChange={handleChange}
                    defaultValue={formdata.coordinator}
                  >
                    <option>Select Co-ordinator</option>
                    {allstaffdata.length !== 0
                      ? allstaffdata.map((data, index) => (
                          <option
                            value={`${data.firstname} ${data.lastname}`}
                            key={index}
                          >
                            {data.firstname} {data.lastname}
                          </option>
                        ))
                      : null}
                  </select>
                </div>
              </div>
            </div>
            <div className="py-8">
              {clicked === true ? (
                <button className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5">
                  Please Wait...
                </button>
              ) : updateid !== null ? (
                <button
                  className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5"
                  onClick={Updatebtn}
                >
                  Update
                </button>
              ) : (
                <button
                  className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5"
                  onClick={savebtn}
                >
                  Save
                </button>
              )}
            </div>
          </div>
        </div>
      )}

      <ToastContainer />
    </>
  );
};

export default Project;
