/* eslint-disable no-unused-vars */
// CodeEditor.js
import React, { useState, useEffect } from "react";
import Editor from "react-simple-code-editor";
import Prism from "prismjs";
import "prismjs/themes/prism.css";

// Import language support from Prism
import "prismjs/components/prism-markup"; // HTML
import "prismjs/components/prism-javascript"; // JavaScript
import "prismjs/components/prism-python"; // Python
import "prismjs/components/prism-java"; // Java

const CodeEditor = () => {
  const [code, setCode] = useState("// Write your JavaScript code here\n");
  const [output, setOutput] = useState("");
  const [language, setLanguage] = useState("javascript");

  const highlight = (code) => {
    switch (language) {
      case "javascript":
        return Prism.highlight(code, Prism.languages.javascript, "javascript");
      case "python":
        return Prism.highlight(code, Prism.languages.python, "python");
      case "java":
        return Prism.highlight(code, Prism.languages.java, "java");
      case "html":
        return Prism.highlight(code, Prism.languages.markup, "markup");
      default:
        return Prism.highlight(code, Prism.languages.javascript, "javascript");
    }
  };

  const handleChange = (newCode) => {
    setCode(newCode);
  };

  const runCode = () => {
    if (language === "javascript") {
      try {
        const log = [];
        const customConsole = {
          log: (...args) => log.push(args.join(" ")),
        };
        const wrappedCode = `
          (function() {
            const console = { log: (...args) => log.push(args.join(' ')) };
            ${code}
          })();
        `;
        // eslint-disable-next-line no-eval
        eval(wrappedCode);
        setOutput(log.length ? log.join("\n") : "No output");
      } catch (error) {
        setOutput(`Error: ${error.message}`);
      }
    } else {
      setOutput("Execution for this language is not supported in the browser.");
    }
  };

  useEffect(() => {
    if (language === "html") {
      const iframe = document.getElementById("live-preview");
      const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
      iframeDoc.open();
      iframeDoc.write(code);
      iframeDoc.close();
    }
  }, [code, language]);

  return (
    <div className="px-12">
      <select onChange={(e) => setLanguage(e.target.value)} value={language}>
        <option value="javascript">JavaScript</option>
        <option value="python">Python</option>
        <option value="java">Java</option>
        <option value="html">HTML</option>
      </select>
      <Editor
        value={code}
        onValueChange={handleChange}
        highlight={highlight}
        padding={10}
        style={{
          fontFamily: "monospace",
          fontSize: 14,
          width: "100%",
          height: "300px",
          backgroundColor: "#282c34",
          color: "#ffffff",
        }}
      />
      <button
        onClick={runCode}
        style={{ marginTop: "10px", padding: "10px 20px", fontSize: "16px" }}
      >
        Run Code
      </button>
      {language === "html" ? (
        <iframe
          id="live-preview"
          title="Live Preview"
          style={{ width: "100%", height: "300px", border: "1px solid #ccc" }}
        />
      ) : (
        <div
          style={{
            marginTop: "20px",
            backgroundColor: "#1e1e1e",
            color: "#dcdcdc",
            padding: "10px",
            borderRadius: "4px",
          }}
        >
          <h3>Output:</h3>
          <pre>{output}</pre>
        </div>
      )}
    </div>
  );
};

export default CodeEditor;
