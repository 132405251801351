import React from "react";

const Termscondition = () => {
  return (
    <div className="px-16 mt-12">
      <h2 className="text-3xl">Terms & Conditions</h2>
      <p className="mt-2">
        These Terms & Conditions ("Terms") govern your use of the Code Purple
        Academy management software ("Software") provided by Vimix Technologies
        LLP. By accessing or using the Software, you agree to be bound by these
        Terms. If you do not agree to these Terms, please do not use the
        Software.
      </p>
      <div className="mt-2">
        <h5 className="font-bold text-xl">1. Use of the Software:</h5>
        <ul style={{ listStyle: "disc" }} className="ml-12">
          <li className="font-normal mt-2">
            You must be at least 18 years old or have the legal capacity to
            enter into a binding contract in your jurisdiction to use the
            Software.
          </li>
          <li className="font-normal mt-2">
            You are responsible for maintaining the confidentiality of your
            account credentials and for all activities that occur under your
            account.
          </li>
        </ul>
      </div>
      <div className="mt-2">
        <h5 className="font-bold text-xl">2. Intellectual Property:</h5>
        <ul style={{ listStyle: "disc" }} className="ml-12">
          <li className="font-normal mt-2">
            The Software and its contents, including but not limited to text,
            graphics, logos, button icons, images, audio clips, digital
            downloads, data compilations, and software, are the property of
            Vimix Technologies LLP and are protected by copyright and other
            intellectual property laws.
          </li>
          <li className="font-normal mt-2">
            You may not modify, reproduce, distribute, create derivative works
            of, publicly display, publicly perform, republish, download, store,
            or transmit any of the material on our Software without our prior
            written consent.
          </li>
        </ul>
      </div>
      <div className="mt-2">
        <h5 className="font-bold text-xl">3. Payment Gateway:</h5>
        <ul style={{ listStyle: "disc" }} className="ml-12">
          <li className="font-normal mt-2">
            The Software may include features that enable you to make payments
            for services or products provided by Code Purple Academy.
          </li>
          <li className="font-normal mt-2">
            By using the payment gateway feature, you agree to abide by the
            terms and conditions set forth by the payment processor and to
            provide accurate and complete payment information.
          </li>
        </ul>
      </div>
      <div className="mt-2">
        <h5 className="font-bold text-xl">4. Privacy Policy:</h5>
        <ul style={{ listStyle: "disc" }} className="ml-12">
          <li className="font-normal mt-2">
            Your use of the Software is subject to our Privacy Policy, which
            governs the collection, use, and disclosure of your personal
            information. By using the Software, you consent to the collection,
            use, and disclosure of your personal information as described in our
            Privacy Policy.
          </li>
        </ul>
      </div>
      <div className="mt-2">
        <h5 className="font-bold text-xl">5. Disclaimer of Warranties:</h5>
        <ul style={{ listStyle: "disc" }} className="ml-12">
          <li className="font-normal mt-2">
            The Software is provided on an "as is" and "as available" basis,
            without any warranties of any kind, either express or implied. We do
            not warrant that the Software will be error-free or uninterrupted,
            or that defects will be corrected.
          </li>
        </ul>
      </div>
      <div className="mt-2">
        <h5 className="font-bold text-xl">6. Limitation of Liability:</h5>
        <ul style={{ listStyle: "disc" }} className="ml-12">
          <li className="font-normal mt-2">
            In no event shall Vimix Technologies LLP, its affiliates, or their
            respective officers, directors, employees, or agents be liable for
            any indirect, incidental, special, consequential, or punitive
            damages, arising out of or in connection with your use of the
            Software.
          </li>
        </ul>
      </div>
      <div className="mt-2">
        <h5 className="font-bold text-xl">7. Governing Law:</h5>
        <ul style={{ listStyle: "disc" }} className="ml-12">
          <li className="font-normal mt-2">
            These Terms shall be governed by and construed in accordance with
            the laws of [your jurisdiction], without regard to its conflict of
            law principles.
          </li>
        </ul>
      </div>
      <div className="mt-2">
        <h5 className="font-bold text-xl">8. Changes to Terms:</h5>
        <ul style={{ listStyle: "disc" }} className="ml-12">
          <li className="font-normal mt-2">
            We reserve the right to modify or update these Terms at any time,
            without prior notice. Any changes will be effective immediately upon
            posting the updated Terms on the Software. Your continued use of the
            Software after any such changes constitutes your acceptance of the
            new Terms.
          </li>
        </ul>
      </div>
      <div className="mt-2">
        <h5 className="font-bold text-xl">9. Contact Us:</h5>
        <ul style={{ listStyle: "disc" }} className="ml-12">
          <li className="font-normal mt-2">
            If you have any questions or concerns about these Terms, please
            contact us at{" "}
            <a href="mailto:hr@codepurple.in" className="text-blue-500">
              hr@codepurple.in
            </a>
            .
          </li>
        </ul>
      </div>
      <p className="mt-5">
        By using the Software, you agree to be bound by these Terms. If you do
        not agree to these Terms, please do not use the Software.
      </p>
      <p className="mb-12 mt-5">
        These Terms were last updated on 12 April, 2024.
      </p>
    </div>
  );
};

export default Termscondition;
