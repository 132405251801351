/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Profile from "../Assets/profile.png";
import { useState } from "react";
import { FaAngleDown } from "react-icons/fa";
import { viewByidAdmin } from "../Api/admin";
import { viewByidStudents } from "../Api/student";
import { viewByidstaff } from "../Api/staff";

const TopNav = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [name, setname] = useState("");
  const [profile, setprofile] = useState("");

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  var adminid =
    sessionStorage.getItem("adminid") || localStorage.getItem("adminid");
  var role = sessionStorage.getItem("role") || localStorage.getItem("role");
  useEffect(() => {
    getadmindata();
  }, [adminid, role]);
  const getadmindata = async () => {
    if (adminid !== null) {
      if (role === "student") {
        var singledata = await viewByidStudents({ id: adminid });
        if (singledata.length !== 0) {
          setprofile(singledata[0].profile);
          setname(singledata[0].name);
        }
      } else if (role === "admin") {
        var admindata = await viewByidAdmin({ id: adminid });
        if (admindata.length !== 0) {
          setprofile(admindata[0].profile);
          setname(admindata[0].name);
        }
      } else {
        var staffdata = await viewByidstaff({ id: adminid });
        if (staffdata.length !== 0) {
          setname(`${staffdata[0].firstname} ${staffdata[0].lastname}`);
        }
      }
    }
  };
  const logoutbtn = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
  };
  return (
    <div className="w-full">
      <div className="mr-3 flex flex-col items-end justify-end">
        <div className="flex items-center p-5">
          <h1 className="font-extrabold text-xl mr-5">Hey {name}</h1>
          <img
            className="rounded-full h-8 w-8 object-cover"
            src={
              profile == null
                ? Profile
                : profile.length === 0
                ? Profile
                : profile
            }
            alt="Profile"
          />
          <FaAngleDown onClick={toggleDropdown} className="text-2xl" />
        </div>
      </div>
      {isOpen && (
        <ul className="absolute h-28 w-36 flex flex-col items-center justify-center mt-4 right-10 list-none z-10 text-xl  text-white bg-slate-700 rounded-xl">
          {role !== "admin" ? (
            <li
              className="cursor-pointer hover:text-gray-400"
              onClick={() => {
                window.location.replace(`/profile`);
              }}
            >
              Profile
            </li>
          ) : null}

          <li
            className="cursor-pointer hover:text-gray-400"
            onClick={() => {
              window.location.replace(`/settings`);
            }}
          >
            Settings
          </li>
          <li
            onClick={logoutbtn}
            className="cursor-pointer hover:text-gray-400"
          >
            LogOut
          </li>
        </ul>
      )}
    </div>
  );
};

export default TopNav;
