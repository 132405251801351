import { Lead } from "../axios";

export const viewallLead = async (data) => {
  var viewallLead = await Lead.post(`/viewalllead`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewallLead;
};

export const destroyLead = async (data) => {
  var destroyLead = await Lead.post(`/destroy`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return destroyLead;
};

export const createLeads = async (data) => {
  var createLeads = await Lead.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return createLeads;
};

export const updateLeads = async (data) => {
  var updateLeads = await Lead.post(`/update`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return updateLeads;
};
