import { Practice } from "../axios";

export const viewallPractice = async (data) => {
  var viewallPractice = await Practice.post(`/viewallpractice`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewallPractice;
};

export const createPractice = async (data) => {
  var createPractice = await Practice.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return createPractice;
};

export const PracticebyStudent = async (data) => {
  var PracticebyStudent = await Practice.post(`/practicebystudent`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return PracticebyStudent;
};

export const updatePractice = async (data) => {
  var updatePractice = await Practice.post(`/update`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return updatePractice;
};

export const destroyPractice = async (data) => {
  var destroyPractice = await Practice.post(`/destroy`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return destroyPractice;
};

export const PracticeByStudentLanguage = async (data) => {
  var PracticeByStudentLanguage = await Practice.post(
    `/practicebystudentlanguage`,
    data
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return PracticeByStudentLanguage;
};
