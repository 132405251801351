import React, { useEffect, useState } from "react";
import { DataGrid, GridDeleteIcon } from "@mui/x-data-grid";
import "react-toastify/dist/ReactToastify.css";
import { IconButton } from "@mui/material";
import Modal from "react-modal";
import { FaInfoCircle } from "react-icons/fa";
import { IoMdCloseCircle } from "react-icons/io";
import { destroyContactuser, viewallContactuser } from "../Api/contactuser";

const Contactuser = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [newmodel, setnewmodel] = useState(false);
  const [message, setmessage] = useState(null);

  const columns = [
    { field: "id", headerName: "ID", width: 50 },
    {
      field: "name",
      headerName: "Name",
      width: 200,
      renderCell: (params) => (
        <>
          <h1>{params.row.name}</h1>
        </>
      ),
    },
    {
      field: "email",
      headerName: "Email Address",
      width: 250,
      renderCell: (params) => (
        <>
          <h1>{params.row.email}</h1>
        </>
      ),
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 200,
      renderCell: (params) => (
        <>
          <h1>{params.row.phone}</h1>
        </>
      ),
    },
    {
      field: "message",
      headerName: "Message",
      width: 350,
      renderCell: (params) => (
        <>
          <h1>{params.row.message}</h1>
        </>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      renderCell: (params) => (
        <div>
          <IconButton>
            <FaInfoCircle
              onClick={() => {
                viewbtn(params.row.message);
              }}
            />
          </IconButton>
          <IconButton>
            <GridDeleteIcon
              onClick={() => {
                deletebtn(params.row.data);
              }}
            />
          </IconButton>
        </div>
      ),
    },
  ];

  useEffect(() => {
    getallleaddata();
  }, []);
  const getallleaddata = async () => {
    var alllead = await viewallContactuser();
    if (alllead.length !== 0) {
      var alldata = [];
      for (var i = 0; i < alllead.length; i++) {
        alldata.push({
          id: i + 1,
          name: alllead[i].name,
          email: alllead[i].email,
          phone: alllead[i].phone,
          message: alllead[i].message,
          data: alllead[i].id,
        });
      }
      setSelectedRows(alldata);
    }
  };
  const deletebtn = async (e) => {
    var deletedata = await destroyContactuser({ id: e });
    if (deletedata === "Deleted Successfully") {
      getallleaddata();
    }
  };
  function closemodel() {
    setnewmodel(false);
    setmessage(null);
  }
  const viewbtn = (data) => {
    setmessage(data);
    setnewmodel(true);
  };
  return (
    <>
      <div className="w-full px-14 min-h-screen">
        <div className="flex justify-between py-5 items-center">
          <h1 className="text-2xl py-4 font-black">Contact User</h1>
        </div>
        <div style={{ width: "100%" }}>
          <DataGrid
            rows={selectedRows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            align={"right"}
            pageSizeOptions={[10, 20, 50]}
            // checkboxSelection
          />
        </div>
      </div>
      <Modal
        isOpen={newmodel}
        onRequestClose={closemodel}
        className="accountmodel"
      >
        <div className="flex justify-between items-center">
          <h1>Contact Information</h1>
          <IoMdCloseCircle onClick={closemodel} className="h-8 w-8" />
        </div>
        <hr className="my-2 border border-black" />
        <div className="mt-5">
          <p>{message}</p>
        </div>
      </Modal>
    </>
  );
};

export default Contactuser;
