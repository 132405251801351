/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { DataGrid, GridCellParams, GridDeleteIcon } from "@mui/x-data-grid";
import {
  createLeads,
  destroyLead,
  updateLeads,
  viewallLead,
} from "../Api/lead";
import moment from "moment";
import { IconButton } from "@mui/material";
import { Edit } from "@mui/icons-material";
import WestIcon from "@mui/icons-material/West";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { viewallstaff } from "../Api/staff";
import { viewallFacility } from "../Api/facility";
import Citylist from "../Pages/citylist.json";

const LeadDatabase = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [createstatus, setcreatestatus] = useState(false);
  const [updatestatus, setupdatestatus] = useState(false);
  const [updateid, setupdateid] = useState(null);
  const [disablebtn, setdisablebtn] = useState(false);
  const [formdata, setformdata] = useState({
    firstname: "",
    email: "",
    phone: "",
    lastname: "",
    occupation: "",
    assign: "",
    facility: "",
    city: "",
    state: "",
    address: "",
  });
  const [staffdata, setstaffdata] = useState([]);
  const [facilitydata, setfacilitydata] = useState([]);
  const [statelist, setstatelist] = useState([]);
  const [citylist, setcitylist] = useState([]);
  const [allleaddata, setallleaddata] = useState([]);

  const columns = [
    { field: "id", headerName: "ID", width: 50 },
    {
      field: "name",
      headerName: "Name",
      width: 200,
      renderCell: (params) => (
        <>
          <h1
            onClick={() => {
              editbtn(params.row.data);
            }}
          >
            {params.row.name}
          </h1>
        </>
      ),
    },
    {
      field: "email",
      headerName: "Email Address",
      width: 250,
      renderCell: (params) => (
        <>
          <h1
            onClick={() => {
              editbtn(params.row.data);
            }}
          >
            {params.row.email}
          </h1>
        </>
      ),
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 150,
      renderCell: (params) => (
        <>
          <h1
            onClick={() => {
              editbtn(params.row.data);
            }}
          >
            {params.row.phone}
          </h1>
        </>
      ),
    },
    {
      field: "occupation",
      headerName: "Occupation",
      width: 150,
      renderCell: (params) => (
        <>
          <h1
            onClick={() => {
              editbtn(params.row.data);
            }}
          >
            {params.row.occupation}
          </h1>
        </>
      ),
    },
    {
      field: "assign",
      headerName: "Assign",
      width: 170,
      renderCell: (params) => (
        <>
          <h1
            onClick={() => {
              editbtn(params.row.data);
            }}
          >
            {params.row.assign}
          </h1>
        </>
      ),
    },
    // {
    //   field: "dateCreated",
    //   headerName: "Date Created",
    //   width: 120,
    //   renderCell: (params) => (
    //     <>
    //       <h1
    //         onClick={() => {
    //           editbtn(params.row.data);
    //         }}
    //       >
    //         {params.row.dateCreated}
    //       </h1>
    //     </>
    //   ),
    // },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => (
        <>
          <IconButton>
            <Edit
              onClick={() => {
                editbtn(params.row.data);
              }}
            />
          </IconButton>
          <IconButton>
            <GridDeleteIcon
              onClick={() => {
                deletebtn(params.row.data);
              }}
            />
          </IconButton>
        </>
      ),
    },
  ];

  useEffect(() => {
    getallleaddata();
  }, []);
  const getallleaddata = async () => {
    var alllead = await viewallLead();
    if (alllead.length !== 0) {
      setallleaddata(alllead);
      var alldata = [];
      for (var i = 0; i < alllead.length; i++) {
        alldata.push({
          id: i + 1,
          name: `${alllead[i].firstname} ${alllead[i].lastname}`,
          email: alllead[i].email,
          phone: alllead[i].phone,
          dateCreated: moment(alllead[i].createdAt).format("YYYY-MM-DD"),
          data: alllead[i].id,
          occupation: alllead[i].occupation,
          assign: alllead[i].assign === null ? "-" : alllead[i].assign,
        });
      }
      setSelectedRows(alldata);
    }
    var staffdata = await viewallstaff();
    if (staffdata.length !== 0) {
      var filterdata = await staffdata.filter((data) => {
        return data.role === "Marketing Team";
      });
      setstaffdata(filterdata);
    }

    var allfacility = await viewallFacility();
    setfacilitydata(allfacility);
    setstatelist(Citylist.states);
  };
  const deletebtn = async (e) => {
    var deletedata = await destroyLead({ id: e });
    if (deletedata === "Deleted Successfully") {
      getallleaddata();
    }
  };
  const editbtn = async (e) => {
    setformdata({
      firstname: "",
      email: "",
      phone: "",
      lastname: "",
      occupation: "",
      assign: "",
      facility: "",
      city: "",
      state: "",
      address: "",
    });
    var singledata = await allleaddata.filter((data) => {
      return data.id == e;
    });
    if (singledata.length !== 0) {
      setformdata({
        firstname: singledata[0].firstname,
        email: singledata[0].email,
        phone: singledata[0].phone,
        lastname: singledata[0].lastname,
        occupation: singledata[0].occupation,
        assign: singledata[0].assign,
        facility: singledata[0].facility,
        city: singledata[0].city,
        state: singledata[0].state,
        address: singledata[0].address,
      });
      setupdateid(singledata[0].id);
      setcreatestatus(true);
      setupdatestatus(true);
      var checkdatanew = await Citylist.states.filter((data) => {
        return data.state == singledata[0].state;
      });
      setformdata((values) => ({ ...values, state: singledata[0].state }));
      if (checkdatanew.length !== 0) {
        setcitylist(checkdatanew[0].districts);
      }
    }
  };
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setformdata((values) => ({ ...values, [name]: value }));
  };
  const savebtn = async () => {
    if (formdata.firstname.length === 0) {
      toast.error("Please enter First Name...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.lastname.length === 0) {
      toast.error("Please enter Last Name...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.email.length === 0) {
      toast.error("Please enter Email...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.phone.length === 0) {
      toast.error("Please enter Phone Number...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      setdisablebtn(true);
      var cretedata = await createLeads(formdata);
      if (cretedata.message === "SUCCESS") {
        toast.success("Lead added Successfully...", {
          autoClose: 2000,
          transition: Slide,
        });
        setdisablebtn(false);
        setTimeout(() => {
          getallleaddata();
          setcreatestatus(false);
        }, 2000);
      }
    }
  };
  const updatbtn = async () => {
    if (formdata.firstname.length === 0) {
      toast.error("Please enter First Name...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.lastname.length === 0) {
      toast.error("Please enter Last Name...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.email.length === 0) {
      toast.error("Please enter Email...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.phone.length === 0) {
      toast.error("Please enter Phone Number...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      setdisablebtn(true);
      formdata["id"] = updateid;
      var updatedata = await updateLeads(formdata);
      if (updatedata === "Updated Successfully") {
        toast.success("Lead Updated Successfully...", {
          autoClose: 2000,
          transition: Slide,
        });
        setTimeout(() => {
          getallleaddata();
          setcreatestatus(false);
        }, 2000);
        setdisablebtn(false);
        setformdata({
          firstname: "",
          email: "",
          phone: "",
          lastname: "",
          occupation: "",
          assign: "",
          facility: "",
          city: "",
          state: "",
          address: "",
        });
        setupdateid(null);
        setcreatestatus(false);
        setupdatestatus(false);
      }
    }
  };
  const changestate = async (e) => {
    var checkdata = await Citylist.states.filter((data) => {
      return data.state == e.target.value;
    });
    setformdata((values) => ({ ...values, state: e.target.value }));
    if (checkdata.length !== 0) {
      setcitylist(checkdata[0].districts);
    }
  };
  return (
    <>
      {createstatus === false ? (
        <div className="w-full px-14 min-h-screen">
          <div className="flex justify-between py-5 items-center">
            <h1 className="text-2xl py-4 font-black">Lead Database</h1>
            <button
              className="bg-black text-white rounded w-[10rem] font-md py-4"
              onClick={() => {
                setcreatestatus(true);
                setformdata({
                  firstname: "",
                  email: "",
                  phone: "",
                  lastname: "",
                  occupation: "",
                  assign: "",
                  facility: "",
                  city: "",
                  state: "",
                  address: "",
                });
                setupdateid(null);
                setupdatestatus(false);
              }}
            >
              Create
            </button>
          </div>
          <div style={{ width: "100%" }}>
            <DataGrid
              rows={selectedRows}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 10 },
                },
              }}
              align={"center"}
              pageSizeOptions={[10, 20, 50]}
              // checkboxSelection
            />
          </div>
        </div>
      ) : (
        <div className="px-14 min-h-screen">
          <div
            className="flex gap-2 items-center cursor-pointer"
            onClick={() => {
              setcreatestatus(false);
              setformdata({
                firstname: "",
                email: "",
                phone: "",
                lastname: "",
                occupation: "",
                assign: "",
                facility: "",
                city: "",
                state: "",
                address: "",
              });
              setupdateid(null);
            }}
          >
            <WestIcon />
            <h1 className="text-2xl py-4 font-black">Add New Lead</h1>
          </div>
          <div className="w-full bg-white rounde shadow-2xl p-7 my-5 ">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-5">
              <div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    First Name
                  </h1>
                  <input
                    placeholder="First Name"
                    type="text"
                    name="firstname"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.firstname}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Last Name
                  </h1>
                  <input
                    placeholder="Last Name"
                    type="text"
                    name="lastname"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.lastname}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Email
                  </h1>
                  <input
                    placeholder="Email"
                    type="text"
                    name="email"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.email}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Phone Number
                  </h1>
                  <input
                    placeholder="Phone Number"
                    type="text"
                    name="phone"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.phone}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg">Occupation</h1>
                  <select
                    name="occupation"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.occupation}
                  >
                    <option>Select Occupation</option>
                    <option value="Student">Student</option>
                    <option value="Unemployed">Unemployed</option>
                    <option value="Working Professional">
                      Working Professional
                    </option>
                  </select>
                </div>
                <div className="py-3">
                  <h1 className="text-lg">Assign To</h1>
                  <select
                    className="border-2 p-2 w-full py-2 rounded"
                    name="assign"
                    onChange={handleChange}
                    defaultValue={formdata.assign}
                  >
                    <option value="">Select</option>
                    {staffdata.length !== 0
                      ? staffdata.map((data, index) => (
                          <option
                            key={index}
                            value={`${data.firstname} ${data.lastname}`}
                          >
                            {data.firstname} {data.lastname}
                          </option>
                        ))
                      : null}
                  </select>
                </div>
              </div>
              <div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Facility
                  </h1>
                  <select
                    name="facility"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.facility}
                  >
                    <option value="">Select Facility</option>
                    {facilitydata.length !== 0
                      ? facilitydata.map((data, index) => (
                          <option value={data.id} key={index}>
                            {data.name}
                          </option>
                        ))
                      : null}
                  </select>
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    State
                  </h1>
                  <select
                    name="state"
                    id="state"
                    className="border-2 p-2 w-full py-2 rounded"
                    required=""
                    defaultValue={formdata.state}
                    onChange={changestate}
                  >
                    <option value="">Select State</option>
                    {statelist.length !== 0
                      ? statelist.map((data, index) => (
                          <option value={data.state} key={index}>
                            {data.state}
                          </option>
                        ))
                      : null}
                  </select>
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    City
                  </h1>
                  <select
                    name="city"
                    id="city"
                    className="border-2 p-2 w-full py-2 rounded"
                    required=""
                    defaultValue={formdata.city}
                    onChange={handleChange}
                  >
                    <option value="">Select City</option>
                    {citylist.length !== 0
                      ? citylist.map((data, index) => (
                          <option value={data} key={index}>
                            {data}
                          </option>
                        ))
                      : null}
                  </select>
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Address
                  </h1>
                  <textarea
                    placeholder="Address"
                    type="text"
                    name="address"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.address}
                  />
                </div>
              </div>
            </div>
            <div className="py-8">
              {updatestatus === true ? (
                disablebtn === true ? (
                  <button className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5">
                    Please Wait..
                  </button>
                ) : (
                  <button
                    className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5"
                    onClick={updatbtn}
                  >
                    Update
                  </button>
                )
              ) : disablebtn === true ? (
                <button className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5">
                  Please Wait..
                </button>
              ) : (
                <button
                  className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5"
                  onClick={savebtn}
                >
                  Save
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </>
  );
};

export default LeadDatabase;
