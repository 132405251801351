/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import WestIcon from "@mui/icons-material/West";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  createFacility,
  destroyFacility,
  updateFacility,
  viewallFacility,
} from "../Api/facility";
import { BsTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import Citylist from "../Pages/citylist.json";
import { BiEdit } from "react-icons/bi";
import { BsFillTrash3Fill } from "react-icons/bs";
import { IconButton } from "@mui/material";

const Facility = () => {
  const [addnew, setaddnew] = useState(false);
  const [allfacilitydata, setallfacilitydata] = useState([]);
  const [formdata, setformdata] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
    rent: "",
    advance: "",
  });
  const [statelist, setstatelist] = useState([]);
  const [citylist, setcitylist] = useState([]);
  const [editoption, seteditoption] = useState(null);
  const [disablebtn, setdisablebtn] = useState(false);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setformdata((values) => ({ ...values, [name]: value }));
  };
  const savebtn = async () => {
    if (formdata.name.length === 0) {
      toast.error("Please enter Name...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.email.length === 0) {
      toast.error("Please enter Email...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.phone.length === 0) {
      toast.error("Please enter Phone Number...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.address.length === 0) {
      toast.error("Please enter Address...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.city.length === 0) {
      toast.error("Please enter City...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.state.length === 0) {
      toast.error("Please enter State...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.country.length === 0) {
      toast.error("Please enter Country...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.pincode.length === 0) {
      toast.error("Please enter Pincode...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.rent.length === 0) {
      toast.error("Please enter Rent...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.advance.length === 0) {
      toast.error("Please enter Advance...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      setdisablebtn(true);
      var createstudent = await createFacility(formdata);
      if (createstudent.message === "SUCCESS") {
        toast.success("Facility added Successfully...", {
          autoClose: 2000,
          transition: Slide,
        });
        setTimeout(() => {
          getalldata();
          setaddnew(false);
          setdisablebtn(false);
          setformdata({
            name: "",
            email: "",
            phone: "",
            address: "",
            city: "",
            state: "",
            country: "",
            pincode: "",
            rent: "",
            advance: "",
          });
        }, 2000);
      }
    }
  };
  useEffect(() => {
    getalldata();
  }, []);
  const getalldata = async () => {
    var allfacility = await viewallFacility();
    setallfacilitydata(allfacility);
    setstatelist(Citylist.states);
  };
  const changestate = async (e) => {
    var checkdata = await Citylist.states.filter((data) => {
      return data.state == e.target.value;
    });
    setformdata((values) => ({ ...values, state: e.target.value }));
    if (checkdata.length !== 0) {
      setcitylist(checkdata[0].districts);
    }
  };
  const editbtn = async (e) => {
    var checkdata = await allfacilitydata.filter((data) => {
      return data.id == e;
    });
    if (checkdata.length !== 0) {
      setformdata({
        name: checkdata[0].name,
        email: checkdata[0].email,
        phone: checkdata[0].phone,
        address: checkdata[0].address,
        city: checkdata[0].city,
        state: checkdata[0].state,
        country: checkdata[0].country,
        pincode: checkdata[0].pincode,
        rent: checkdata[0].rent,
        advance: checkdata[0].advance,
      });
      var checkdatanew = await Citylist.states.filter((data) => {
        return data.state == checkdata[0].state;
      });
      setformdata((values) => ({ ...values, state: checkdata[0].state }));
      if (checkdatanew.length !== 0) {
        setcitylist(checkdatanew[0].districts);
      }
      seteditoption(checkdata[0].id);
      setaddnew(true);
    }
  };
  const Updatebtn = async () => {
    if (formdata.name.length === 0) {
      toast.error("Please enter Name...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.email.length === 0) {
      toast.error("Please enter Email...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.phone.length === 0) {
      toast.error("Please enter Phone Number...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.address.length === 0) {
      toast.error("Please enter Address...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.city.length === 0) {
      toast.error("Please enter City...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.state.length === 0) {
      toast.error("Please enter State...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.country.length === 0) {
      toast.error("Please enter Country...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.pincode.length === 0) {
      toast.error("Please enter Pincode...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.rent.length === 0) {
      toast.error("Please enter Rent...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.advance.length === 0) {
      toast.error("Please enter Advance...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      setdisablebtn(true);
      formdata["id"] = editoption;
      var updatedata = await updateFacility(formdata);
      if (updatedata === "Updated Successfully") {
        toast.success("Facility Updated Successfully...", {
          autoClose: 2000,
          transition: Slide,
        });
        setTimeout(() => {
          getalldata();
          setaddnew(false);
          seteditoption(null);
          setdisablebtn(false);
          setformdata({
            name: "",
            email: "",
            phone: "",
            address: "",
            city: "",
            state: "",
            country: "",
            pincode: "",
            rent: "",
            advance: "",
          });
        }, 2000);
      }
    }
  };
  const deletebtn = async (e) => {
    var deltebtn = await destroyFacility({ id: e });
    if (deltebtn === "Deleted Successfully") {
      toast.success("Facility Deleted Successfully...", {
        autoClose: 2000,
        transition: Slide,
      });
      setTimeout(() => {
        getalldata();
      }, 2000);
    }
  };
  return (
    <>
      {addnew === false ? (
        <div className="px-14">
          <div className="flex justify-between py-5 items-center">
            <h1 className="text-2xl py-4 font-black">Facility Details</h1>
            <button
              className="bg-black text-white rounded w-[10rem] font-md py-4"
              onClick={() => {
                setaddnew(true);
                seteditoption(null);
                setformdata({
                  name: "",
                  email: "",
                  phone: "",
                  address: "",
                  city: "",
                  state: "",
                  country: "",
                  pincode: "",
                  rent: "",
                  advance: "",
                });
              }}
            >
              Add New
            </button>
          </div>
          <div className="flex gap-8">
            {allfacilitydata.length !== 0
              ? allfacilitydata.map((data, index) => (
                  <div
                    className="p-3 flex flex-col rounded-sm justify-evenly w-1/2 shadow-2xl bg-white rounded"
                    key={index}
                  >
                    <h1 className="text-3xl">{data.name}</h1>
                    <div className="flex gap-2 items-center pt-2">
                      <BsTelephoneFill />
                      <p>{data.phone}</p>
                    </div>
                    <div className="flex gap-2 items-center py-2">
                      <MdEmail />
                      <p>{data.email}</p>
                    </div>
                    <p className="flex gap-2 items-center">
                      {data.address}, {data.city}, {data.state}, {data.country},{" "}
                      {data.pincode}
                    </p>
                    <div className="flex gap-2 items-center mt-2">
                      <p className="font-bold text-lg">Rent - </p>{" "}
                      <p className="text-lg">₹ {data.rent} /-</p>
                    </div>
                    <div className="flex gap-2 items-center mt-2">
                      <p className="font-bold text-lg">Advance - </p>{" "}
                      <p className="text-lg">₹ {data.advance} /-</p>
                    </div>
                    <div className="flex gap-4 float-right justify-end">
                      <IconButton className="float-right">
                        <BiEdit
                          onClick={() => {
                            editbtn(data.id);
                          }}
                        />
                      </IconButton>
                      <IconButton>
                        <BsFillTrash3Fill
                          onClick={() => {
                            deletebtn(data.id);
                          }}
                        />
                      </IconButton>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      ) : (
        <div className="px-14 min-h-screen">
          <div
            className="flex gap-2 items-center cursor-pointer"
            onClick={() => {
              setaddnew(false);
            }}
          >
            <WestIcon />
            {editoption === null ? (
              <h1 className="text-2xl py-4 font-black">Add New Facility</h1>
            ) : (
              <h1 className="text-2xl py-4 font-black">
                Edit Facility Details
              </h1>
            )}
          </div>
          <div className="w-full bg-white rounde shadow-2xl p-7 my-5 ">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-5">
              <div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Name
                  </h1>
                  <input
                    placeholder="Name"
                    type="text"
                    name="name"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.name}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Email
                  </h1>
                  <input
                    placeholder="Email"
                    type="text"
                    name="email"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.email}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Phone Number
                  </h1>
                  <input
                    placeholder="Phone Number"
                    type="text"
                    name="phone"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.phone}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Address
                  </h1>
                  <textarea
                    placeholder="Address"
                    type="text"
                    name="address"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.address}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    State
                  </h1>
                  <select
                    name="state"
                    id="state"
                    className="border-2 p-2 w-full py-2 rounded"
                    required=""
                    defaultValue={formdata.state}
                    onChange={changestate}
                  >
                    <option value="">Select State</option>
                    {statelist.length !== 0
                      ? statelist.map((data, index) => (
                          <option value={data.state} key={index}>
                            {data.state}
                          </option>
                        ))
                      : null}
                  </select>
                </div>
              </div>
              <div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    City
                  </h1>
                  <select
                    name="city"
                    id="city"
                    className="border-2 p-2 w-full py-2 rounded"
                    required=""
                    defaultValue={formdata.city}
                    onChange={handleChange}
                  >
                    <option value="">Select City</option>
                    {citylist.length !== 0
                      ? citylist.map((data, index) => (
                          <option value={data} key={index}>
                            {data}
                          </option>
                        ))
                      : null}
                  </select>
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Country
                  </h1>
                  <input
                    placeholder="Country"
                    type="text"
                    name="country"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.country}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Pincode
                  </h1>
                  <input
                    placeholder="Pincode"
                    type="text"
                    name="pincode"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.pincode}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Rent
                  </h1>
                  <input
                    placeholder="Rent"
                    type="number"
                    name="rent"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.rent}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Advance
                  </h1>
                  <input
                    placeholder="Advance"
                    type="number"
                    name="advance"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.advance}
                  />
                </div>
              </div>
            </div>
            <div className="py-8">
              {editoption === null ? (
                disablebtn === true ? (
                  <button className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5">
                    Please Wait...
                  </button>
                ) : (
                  <button
                    className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5"
                    onClick={savebtn}
                  >
                    Save
                  </button>
                )
              ) : disablebtn === true ? (
                <button className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5">
                  Please Wait...
                </button>
              ) : (
                <button
                  className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5"
                  onClick={Updatebtn}
                >
                  Update
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </>
  );
};

export default Facility;
