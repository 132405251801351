import { Questiondata } from "../axios";

export const createQuestiondata = async (data) => {
  var createQuestiondata = await Questiondata.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return createQuestiondata;
};

export const viewallQuestion = async (data) => {
  var viewallQuestion = await Questiondata.post(`/viewallQuestion`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewallQuestion;
};

export const updateQuestion = async (data) => {
  var updateQuestion = await Questiondata.post(`/update`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return updateQuestion;
};
