import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="px-16 mt-12">
      <h2 className="text-3xl">Privacy Policy</h2>
      <p className="mt-2">
        At Code Purple Academy, operated by Vimix Technologies LLP, we are
        committed to protecting your privacy. This Privacy Policy outlines how
        we collect, use, disclose, and protect your personal information when
        you use our management software and enable the payment gateway.
      </p>
      <div className="mt-2">
        <h5 className="font-bold text-xl">1. Information We Collect:</h5>
        <p className="font-normal mt-2">
          Personal Information: When you register for an account on our
          management software or make a payment through the payment gateway, we
          may collect personal information such as your name, email address,
          phone number, billing address, and payment card details. Usage
          Information: We may also collect information about how you interact
          with our software, including your course progress, engagement metrics,
          and any other actions you take while using the platform.
        </p>
      </div>
      <div className="mt-2">
        <h5 className="font-bold text-xl">2. How We Use Your Information:</h5>
        <p className="font-normal mt-2">
          Providing Services: We use the information we collect to provide and
          maintain our management software, including enabling the payment
          gateway functionality. Personalization: We may use your information to
          personalize your experience and provide tailored content,
          recommendations, and communication based on your interests and
          preferences. Communication: We may use your email address or phone
          number to communicate with you regarding your account, updates to our
          services, promotions, or other relevant information.
        </p>
      </div>
      <div className="mt-2">
        <h5 className="font-bold text-xl">3. Information Sharing:</h5>
        <p className="font-normal mt-2">
          Third-Party Service Providers: We may share your personal information
          with third-party service providers who assist us in operating our
          software, processing payments, or providing other services on our
          behalf. These service providers are contractually obligated to use
          your information only for the purposes specified by us and in
          accordance with this Privacy Policy. Legal Compliance: We may disclose
          your information if required to do so by law or in response to valid
          legal requests, such as court orders or subpoenas.
        </p>
      </div>
      <div className="mt-2">
        <h5 className="font-bold text-xl">4. Data Security:</h5>
        <p className="font-normal mt-2">
          We take reasonable measures to protect your personal information from
          unauthorized access, disclosure, alteration, or destruction. However,
          please note that no method of transmission over the internet or
          electronic storage is completely secure, and we cannot guarantee
          absolute security.
        </p>
      </div>
      <div className="mt-2">
        <h5 className="font-bold text-xl">5. Your Choices:</h5>
        <p className="font-normal mt-2">
          Access and Correction: You may access, update, or correct your
          personal information by logging into your account settings or by
          contacting us directly. Opt-Out: You may opt out of receiving
          promotional emails or other marketing communications from us by
          following the unsubscribe instructions provided in the emails or by
          contacting us directly.
        </p>
      </div>
      <div className="mt-2">
        <h5 className="font-bold text-xl">
          6. Changes to This Privacy Policy:
        </h5>
        <p className="font-normal mt-2">
          We reserve the right to update or modify this Privacy Policy at any
          time. Any changes will be effective immediately upon posting the
          updated Privacy Policy on our website. We encourage you to review this
          Privacy Policy periodically for any changes
        </p>
      </div>
      <div className="mt-2">
        <h5 className="font-bold text-xl">7. Contact Us:</h5>
        <p className="font-normal mt-2">
          If you have any questions or concerns about this Privacy Policy or our
          privacy practices, please contact us at hr@codepurple.in. By using our
          management software and enabling the payment gateway, you consent to
          the collection, use, and disclosure of your personal information as
          described in this Privacy Policy.
        </p>
      </div>
      <p className="mb-12 mt-5">This Privacy Policy was last updated on 12 April, 2024.</p>
    </div>
  );
};

export default PrivacyPolicy;
