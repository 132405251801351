/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";

export const Hire = () => {
  return (
    <div>
      <iframe
        src="https://optimhire.com/d/Search-job-iframe?ref_code=dinesh-kumar-421102&skill=&positions=&dark_color=3f73ee&light_color=3b74e8"
        width="100%"
        height="800"
      ></iframe>
    </div>
  );
};
