import React, { useState } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import "../App.css";
import { LoginAdmin } from "../Api/admin";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { loginStudents } from "../Api/student";
import { loginStaffs } from "../Api/staff";

const Login = () => {
  const [rememberMe, setrememberMe] = useState(false);
  const [type, settype] = useState("password");
  const [clicked, setclicked] = useState(false);
  const [logindata, setlogindata] = useState({
    email: "",
    password: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setlogindata((values) => ({ ...values, [name]: value }));
  };
  const handleSubmit = async () => {
    if (logindata.email.length === 0) {
      toast.error("Please enter Email Id...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (logindata.password.length === 0) {
      toast.error("Please enter Password...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      setclicked(true);
      var checkuser = await LoginAdmin(logindata);
      if (checkuser.message === "Please Register...") {
        var loginuser = await loginStudents(logindata);
        if (loginuser.message === "SUCCESS") {
          if (rememberMe === false) {
            sessionStorage.setItem(
              "token",
              loginuser.data.Studentsdata[0].token
            );
            sessionStorage.setItem(
              "adminid",
              loginuser.data.Studentsdata[0].id
            );
            sessionStorage.setItem("role", "student");
          } else {
            localStorage.setItem("token", loginuser.data.Studentsdata[0].token);
            localStorage.setItem("adminid", loginuser.data.Studentsdata[0].id);
            localStorage.setItem("role", "student");
          }
          toast.success("Welcome Back...", {
            autoClose: 2000,
            transition: Slide,
          });
          setTimeout(() => {
            window.location.replace("/");
          }, 2000);
        } else if (loginuser.message === "Please Register...") {
          var checkstaff = await loginStaffs(logindata);
          if (checkstaff.message === "SUCCESS") {
            if (rememberMe === false) {
              sessionStorage.setItem(
                "token",
                checkstaff.data.Staffdata[0].token
              );
              sessionStorage.setItem(
                "adminid",
                checkstaff.data.Staffdata[0].id
              );
              sessionStorage.setItem("role", "staff");
            } else {
              localStorage.setItem("token", checkstaff.data.Staffdata[0].token);
              localStorage.setItem("adminid", checkstaff.data.Staffdata[0].id);
              localStorage.setItem("role", "staff");
            }
            toast.success("Welcome Back...", {
              autoClose: 2000,
              transition: Slide,
            });
            setTimeout(() => {
              window.location.replace("/");
            }, 2000);
          } else {
            setclicked(false);
            toast.error(checkstaff.message, {
              autoClose: 2000,
              transition: Slide,
            });
          }
        } else {
          setclicked(false);
          toast.error(loginuser.message, {
            autoClose: 2000,
            transition: Slide,
          });
        }
      } else if (checkuser.message === "SUCCESS") {
        if (rememberMe === false) {
          sessionStorage.setItem("token", checkuser.data.Admindata[0].token);
          sessionStorage.setItem("adminid", checkuser.data.Admindata[0].id);
          sessionStorage.setItem("role", "admin");
        } else {
          localStorage.setItem("token", checkuser.data.Admindata[0].token);
          localStorage.setItem("adminid", checkuser.data.Admindata[0].id);
          localStorage.setItem("role", "admin");
        }
        toast.success("Welcome Back...", {
          autoClose: 2000,
          transition: Slide,
        });
        setTimeout(() => {
          window.location.replace("/");
        }, 2000);
      } else {
        setclicked(false);
        toast.error(checkuser.message, {
          autoClose: 2000,
          transition: Slide,
        });
      }
    }
  };
  return (
    <div className="h-screen w-screen flex flex-col items-center justify-center bg-gray-100">
      <div className="md:w-4/12 w-[90%] border-2 bg-white rounded-xl px-8 py-4 flex flex-col justify-center">
        <div>
          <h1 className="text-2xl my-8 font-semibold text-center">
            Login to the Dashboard
          </h1>
          <label className="font-medium">Email ID</label>
          <input
            type="text"
            placeholder="Email ID"
            name="email"
            onChange={handleChange}
            className="w-full h-12 mt-2 mb-4 px-4 border border-gray-200 rounded"
          />
          <label className="font-medium">Password</label>
          {type === "password" ? (
            <>
              <input
                type="password"
                placeholder="Password"
                name="password"
                onChange={handleChange}
                className="w-full h-12 mt-2 mb-4 px-4 bg-gray-100 border border-gray-200 rounded"
              />
              <AiFillEyeInvisible
                className="h-6 eyeicon cursor-pointer"
                onClick={() => {
                  settype("text");
                }}
              />
            </>
          ) : (
            <>
              <input
                type="text"
                placeholder="Password"
                name="password"
                onChange={handleChange}
                className="w-full h-12 mt-2 mb-4 px-4 bg-gray-100 border border-gray-200 rounded"
              />
              <AiFillEye
                className="h-6 eyeicon cursor-pointer"
                onClick={() => {
                  settype("password");
                }}
              />
            </>
          )}
          <div className="mx-2">
            <input
              type="checkbox"
              id="rememberMe"
              name="rememberMe"
              value="rememberMe"
              className="scale-150"
              onChange={() => {
                setrememberMe(!rememberMe);
              }}
            />
            &nbsp;&nbsp;&nbsp;
            <label className="text-gray-400 text-lg">Remember Me</label>
            {/* <p
            className="text-gray-400 text-lg float-right cursor-pointer"
            onClick={forget}
          >
            Forgot Password ?
          </p> */}
          </div>
        </div>
        {clicked === false ? (
          <button
            className="w-full h-12 my-4 bg-black text-white rounded"
            onClick={handleSubmit}
          >
            Login{" "}
          </button>
        ) : (
          <button
            className="w-full h-12 my-4 bg-black text-white rounded flex items-center justify-center"
            disabled
          >
            Please Wait...
          </button>
        )}
        <a href="/#/privacypolicy" className="underline text-center">
          Privacy Policy
        </a>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Login;
