import { Expense } from "../axios";

export const viewallExpense = async (data) => {
  var viewallExpense = await Expense.post(`/viewallExpense`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewallExpense;
};

export const createExpense = async (data) => {
  var createExpense = await Expense.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return createExpense;
};

export const updateExpense = async (data) => {
  var updateExpense = await Expense.post(`/update`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return updateExpense;
};

export const destroyExpense = async (data) => {
  var destroyExpense = await Expense.post(`/destroy`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return destroyExpense;
};
