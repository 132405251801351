import { Entrolluser } from "../axios";

export const viewallEntrolluser = async (data) => {
  var viewallEntrolluser = await Entrolluser.post(`/viewallenrolluser`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewallEntrolluser;
};

export const destroyEntrolluser = async (data) => {
  var destroyEntrolluser = await Entrolluser.post(`/destroy`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return destroyEntrolluser;
};

export const createEntrolluser = async (data) => {
  var createEntrolluser = await Entrolluser.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return createEntrolluser;
};

export const updateEntrolluser = async (data) => {
  var updateEntrolluser = await Entrolluser.post(`/update`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return updateEntrolluser;
};

export const viewByidEntrolluser = async (data) => {
  var viewByidEntrolluser = await Entrolluser.post(`/viewByid`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewByidEntrolluser;
};
