/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import WestIcon from "@mui/icons-material/West";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import { Edit } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import { viewallCourses } from "../../Api/courses";
import {
  createcoupon,
  destroycoupon,
  updatecoupon,
  viewallcoupon,
} from "../../Api/coupon";

const Coupon = () => {
  const navigate = useNavigate();

  const [selectedRows, setSelectedRows] = useState([]);
  const [createstatus, setcreatestatus] = useState(false);
  const [formdata, setformdata] = useState({
    name: "",
    amount: "",
    course: "",
  });
  const [alldata, setalldata] = useState([]);
  const [editoption, seteditoption] = useState(false);
  const [editid, seteditid] = useState(null);
  const [clicked, setclicked] = useState(false);
  const [coursedata, setcoursedata] = useState([]);

  useEffect(() => {
    getStaffdata();
  }, []);
  const getStaffdata = async () => {
    var alldata = await viewallcoupon();
    var allcourse = await viewallCourses();
    setcoursedata(allcourse);
    if (alldata.length !== 0) {
      setalldata(alldata);
      var alldatanew = [];
      for (var i = 0; i < alldata.length; i++) {
        alldatanew.push({
          id: i + 1,
          name: alldata[i].name,
          course: alldata[i].Course.title,
          amount: alldata[i].amount,
          data: alldata[i].id,
          courseid: alldata[i].Course.id,
        });
      }
      setSelectedRows(alldatanew);
    }
  };
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setformdata((values) => ({ ...values, [name]: value }));
  };
  const savebtn = async () => {
    if (formdata.name.length === 0) {
      toast.error("Please enter Coupon Code...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.course.length === 0) {
      toast.error("Please Select Course...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.amount.length === 0) {
      toast.error("Please Enter Discount Price...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      setclicked(true);
      var createdata = await createcoupon(formdata);
      if (createdata.message === "SUCCESS") {
        toast.success("Coupon Details added Successfully...", {
          autoClose: 2000,
          transition: Slide,
        });
        setTimeout(() => {
          setclicked(false);
          getStaffdata();
          setcreatestatus(false);
        }, 2000);
      }
    }
  };
  const updatebtn = async () => {
    if (formdata.name.length === 0) {
      toast.error("Please enter Coupon Code...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.course.length === 0) {
      toast.error("Please Select Course...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.amount.length === 0) {
      toast.error("Please Enter Discount Price...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      setclicked(true);
      formdata["id"] = editid;
      var updatedata = await updatecoupon(formdata);
      if (updatedata === "Updated Successfully") {
        toast.success("Coupon Details Updated Successfully...", {
          autoClose: 2000,
          transition: Slide,
        });
        setclicked(false);
        setTimeout(() => {
          getStaffdata();
          setcreatestatus(false);
          seteditoption(false);
          seteditid(null);
          setformdata({
            name: "",
            amount: "",
            course: "",
          });
        }, 2000);
      }
    }
  };
  const columns = [
    { field: "id", headerName: "id", width: 150 },
    { field: "name", headerName: "Coupon Code", width: 300 },
    { field: "course", headerName: "Course", width: 250 },
    { field: "amount", headerName: "Discount Price", width: 250 },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      renderCell: (params) => (
        <>
          <IconButton>
            <Edit
              onClick={() => {
                editbtn(params.row.data);
              }}
            />
          </IconButton>
          <IconButton>
            <DeleteIcon
              onClick={() => {
                deletebtn(params.row.data);
              }}
            />
          </IconButton>
        </>
      ),
    },
  ];

  const editbtn = async (e) => {
    var checkdata = await alldata.filter((data) => {
      return data.id == e;
    });
    if (checkdata.length !== 0) {
      setformdata({
        name: checkdata[0].name === null ? "" : checkdata[0].name,
        amount: checkdata[0].amount === null ? "" : checkdata[0].amount,
        course: checkdata[0].course === null ? "" : checkdata[0].course,
      });
      seteditoption(true);
      setcreatestatus(true);
      seteditid(checkdata[0].id);
    }
  };
  const deletebtn = async (e) => {
    var result = confirm("Are you sure to delete this Coupon?");
    if (result === true) {
      var deletedata = await destroycoupon({ id: e });
      if (deletedata === "Deleted Successfully") {
        toast.success("Coupon Deleted Successfully...", {
          autoClose: 2000,
          transition: Slide,
        });
        getStaffdata();
      }
    }
  };

  return (
    <div>
      {createstatus === false ? (
        <div className="px-14 min-h-screen">
          <div className="flex justify-between py-5 items-center">
            <div
              className="flex gap-2 items-center cursor-pointer"
              onClick={() => {
                navigate(`/finance`);
              }}
            >
              <WestIcon />
              <h1 className="text-2xl py-4 font-black">Coupon</h1>
            </div>

            <button
              className="bg-black text-white rounded w-[10rem] font-md py-4"
              onClick={() => {
                setcreatestatus(true);
                setformdata({
                  name: "",
                  amount: "",
                  course: "",
                });
                seteditoption(false);
                seteditid(null);
              }}
            >
              Add New Coupon
            </button>
          </div>
          <DataGrid rows={selectedRows} columns={columns} />
        </div>
      ) : (
        <div className="px-14 min-h-screen">
          <div
            className="flex gap-2 items-center cursor-pointer"
            onClick={() => {
              setcreatestatus(false);
              setformdata({
                name: "",
                amount: "",
                course: "",
              });
            }}
          >
            <WestIcon />
            <h1 className="text-2xl py-4 font-black">Add New Coupon</h1>
          </div>
          <div className="w-full bg-white rounde shadow-2xl p-7 my-5 ">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-5">
              <div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Coupon Code
                  </h1>
                  <input
                    placeholder="Coupon Code"
                    type="text"
                    name="name"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.name}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Select Course
                  </h1>
                  <select
                    name="course"
                    id="course"
                    className="border-2 p-2 w-full py-2 rounded"
                    required=""
                    defaultValue={formdata.course}
                    onChange={handleChange}
                  >
                    <option value="">Select Course</option>
                    {coursedata.length !== 0
                      ? coursedata.map((data, index) => (
                          <option value={data.id} key={index}>
                            {data.title}
                          </option>
                        ))
                      : null}
                  </select>
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Discount Price
                  </h1>
                  <input
                    placeholder="Discount Price"
                    type="number"
                    name="amount"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.amount}
                  />
                </div>
              </div>
            </div>
            <div className="py-8">
              {editoption === true ? (
                clicked === true ? (
                  <button className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5">
                    Please Wait...
                  </button>
                ) : (
                  <button
                    className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5"
                    onClick={updatebtn}
                  >
                    Update
                  </button>
                )
              ) : clicked === true ? (
                <button className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5">
                  Please Wait...
                </button>
              ) : (
                <button
                  className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5"
                  onClick={savebtn}
                >
                  Save
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default Coupon;
