import { Ticket_reply } from "../axios";

export const viewallTicket_reply = async (data) => {
  var viewallTicket_reply = await Ticket_reply.post(
    `/viewallticket_reply`,
    data
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewallTicket_reply;
};

export const createTicket_reply = async (data) => {
  var createTicket_reply = await Ticket_reply.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return createTicket_reply;
};

export const Ticket_replybyStudent = async (data) => {
  var Ticket_replybyStudent = await Ticket_reply.post(
    `/ticket_replybystudent`,
    data
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return Ticket_replybyStudent;
};

export const updateTicket_reply = async (data) => {
  var updateTicket_reply = await Ticket_reply.post(`/update`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return updateTicket_reply;
};

export const destroyTicket_reply = async (data) => {
  var destroyTicket_reply = await Ticket_reply.post(`/destroy`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return destroyTicket_reply;
};

export const ticket_replybyticket = async (data) => {
  var ticket_replybyticket = await Ticket_reply.post(
    `/ticket_replybyticket`,
    data
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return ticket_replybyticket;
};
