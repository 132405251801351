import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { viewallExpense } from "../Api/expense";
import moment from "moment/moment";
import { viewallPayment } from "../Api/payment";
import { viewallReturn } from "../Api/return";

const Finance = () => {
  const navigate = useNavigate();
  const [expensedata, setExpensedata] = useState([]);
  const [returndata, setReturndata] = useState([]);
  const [expenceamount, setExpenceamount] = useState(0);
  const [printAmount, setPrintamount] = useState(0);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const [expenses, returns, payments] = await Promise.all([
        viewallExpense(),
        viewallReturn(),
        viewallPayment(),
      ]);

      const month = moment().format("MM");
      const year = moment().format("YYYY");

      const filterByDate = (data) => {
        return data.filter(
          (item) =>
            item.date.split("-")[1] === month &&
            item.date.split("-")[0] === year
        );
      };

      // Filter and calculate expenses
      const filteredExpenses = filterByDate(expenses);
      const expenseAmount = filteredExpenses.reduce(
        (sum, item) => sum + Number(item.cost),
        0
      );
      setExpenceamount(expenseAmount);
      setExpensedata(filteredExpenses.slice(0, 9));

      // Filter and calculate returns
      const filteredReturns = filterByDate(returns);
      const returnAmount = filteredReturns.reduce(
        (sum, item) => sum + Number(item.cost),
        0
      );
      setReturndata(filteredReturns.slice(0, 9));

      const filteredPayments = filterByDate(payments);
      const paymentAmount = filteredPayments.reduce(
        (sum, item) => sum + Number(item.cost),
        0
      );

      setPrintamount(returnAmount + paymentAmount);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div>
      <div className="px-12">
        <h1 className="text-2xl py-4 font-black">Accounting</h1>
        <div className="flex gap-8">
          <div className="h-36 p-3 flex flex-col rounded-sm justify-evenly w-80 shadow-2xl">
            <h1 className="text-xl font-black">Expenses Of The Month</h1>
            <p className="font-extrabold text-transparent text-3xl bg-clip-text bg-gradient-to-r from-red-700 to-red-400">
              {expenceamount.toLocaleString("en-IN")}
            </p>
            <button
              onClick={() => navigate(`/expense`)}
              className="w-full h-12 mt-3 bg-slate-900 rounded-md text-white hover:bg-white hover:duration-100 hover:text-black hover:border-2 hover:border-black"
            >
              Know More
            </button>
          </div>
          <div className="h-36 p-3 flex flex-col rounded-sm justify-evenly w-80 shadow-2xl">
            <h1 className="text-xl font-black">Return Of The Month</h1>
            <p className="font-extrabold text-transparent text-3xl bg-clip-text bg-gradient-to-r from-lime-500 to-lime-300">
              {printAmount.toLocaleString("en-IN")}
            </p>
            <button
              onClick={() => navigate(`/return`)}
              className="w-full h-12 mt-3 bg-slate-900 rounded-md text-white hover:bg-white hover:duration-100 hover:text-black hover:border-2 hover:border-black"
            >
              Know More
            </button>
          </div>
        </div>
        <div className="flex items-center gap-20 justify-center w-[90%] h-auto">
          <div className="h-[auto] w-[50%] mt-5 rounded-sm shadow-2xl p-5">
            <div className="flex justify-between items-center">
              <h1 className="text-xl font-extrabold">Expense of the Month</h1>
              <p
                className="font-extrabold underline underline-offset-4 text-gray-500 cursor-pointer"
                onClick={() => navigate(`/expense`)}
              >
                ViewAll
              </p>
            </div>
            <div className="py-5">
              {expensedata.length > 0
                ? expensedata.map((data, index) => (
                    <div
                      key={index}
                      className="flex justify-between gap-4 py-2"
                    >
                      <h1>{data.reason}</h1>
                      <h1>{data.cost} /-</h1>
                    </div>
                  ))
                : null}
            </div>
          </div>
          <div className="h-[auto] w-[50%] mt-5 rounded-sm shadow-2xl p-5">
            <div className="flex justify-between items-center">
              <h1 className="text-xl font-extrabold">Return Of The Month</h1>
              <p
                className="font-extrabold underline underline-offset-4 text-gray-500 cursor-pointer"
                onClick={() => navigate(`/return`)}
              >
                ViewAll
              </p>
            </div>
            <div className="py-5">
              {returndata.length > 0
                ? returndata.map((data, index) => (
                    <div
                      key={index}
                      className="flex justify-between gap-4 py-2"
                    >
                      <h1>{data.reason}</h1>
                      <h1>{data.cost} /-</h1>
                    </div>
                  ))
                : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Finance;
