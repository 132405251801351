import { Amount_split } from "../axios";

export const viewallAmount_split = async (data) => {
  var viewallAmount_split = await Amount_split.post(
    `/viewallamount_split`,
    data
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewallAmount_split;
};

export const createAmount_split = async (data) => {
  var createAmount_split = await Amount_split.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return createAmount_split;
};

export const Amount_splitbyStudent = async (data) => {
  var Amount_splitbyStudent = await Amount_split.post(
    `/amount_splitbystudent`,
    data
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return Amount_splitbyStudent;
};

export const updateAmount_split = async (data) => {
  var updateAmount_split = await Amount_split.post(`/update`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return updateAmount_split;
};
