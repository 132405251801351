import { Cover } from "../axios";

export const viewallCover = async (data) => {
  var viewallCover = await Cover.post(`/viewallcover`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewallCover;
};

export const createCover = async (data) => {
  var createCover = await Cover.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return createCover;
};

export const updateCover = async (data) => {
  var updateCover = await Cover.post(`/update`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return updateCover;
};
export const destroyCover = async (data) => {
  var destroyCover = await Cover.post(`/destroy`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return destroyCover;
};
