import { Staff } from "../axios";

export const viewallstaff = async (data) => {
  var viewallstaff = await Staff.post(`/viewallstaff`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewallstaff;
};

export const createstaff = async (data) => {
  var createstaff = await Staff.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return createstaff;
};

export const viewByidstaff = async (data) => {
  var viewByidstaff = await Staff.post(`/viewByid`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewByidstaff;
};

export const updatestaff = async (data) => {
  var updatestaff = await Staff.post(`/update`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return updatestaff;
};

export const loginStaffs = async (data) => {
  var loginStaffs = await Staff.post(`/login`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return loginStaffs;
};

export const checkpasswordStaffs = async (data) => {
  var checkpasswordStaffs = await Staff.post(`/checkpassword`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return checkpasswordStaffs;
};

export const updatepasswordStaffs = async (data) => {
  var updatepasswordStaffs = await Staff.post(`/updatepassword`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return updatepasswordStaffs;
};
