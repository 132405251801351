import { Modules } from "../axios";

export const viewallModules = async (data) => {
  var viewallModules = await Modules.post(`/viewallModules`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewallModules;
};

export const createModules = async (data) => {
  var createModules = await Modules.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return createModules;
};

export const updateModules = async (data) => {
  var updateModules = await Modules.post(`/update`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return updateModules;
};
export const viewByidModule = async (data) => {
  var viewByidModule = await Modules.post(`/viewByid`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewByidModule;
};
