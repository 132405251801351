import { Interestlead } from "../axios";

export const viewallInterestlead = async (data) => {
  var viewallInterestlead = await Interestlead.post(
    `/viewallinterestlead`,
    data
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewallInterestlead;
};

export const destroyInterestlead = async (data) => {
  var destroyInterestlead = await Interestlead.post(`/destroy`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return destroyInterestlead;
};

export const createInterestlead = async (data) => {
  var createInterestlead = await Interestlead.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return createInterestlead;
};

export const updateInterestlead = async (data) => {
  var updateInterestlead = await Interestlead.post(`/update`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return updateInterestlead;
};

export const viewByidInterestlead = async (data) => {
  var viewByidInterestlead = await Interestlead.post(`/viewByid`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewByidInterestlead;
};
