import { Phonepe } from "../axios";

export const Checkphonepe = async (data) => {
  var Checkphonepe = await Phonepe.post(`/phonepe`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return Checkphonepe;
};

export const statusphonepe = async (data) => {
  var statusphonepe = await Phonepe.post(`/status`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return statusphonepe;
};
