import { Ticket } from "../axios";

export const viewallTicket = async (data) => {
  var viewallTicket = await Ticket.post(`/viewallticket`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewallTicket;
};

export const createTicket = async (data) => {
  var createTicket = await Ticket.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return createTicket;
};

export const TicketbyStudent = async (data) => {
  var TicketbyStudent = await Ticket.post(`/ticketbystudent`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return TicketbyStudent;
};

export const updateTicket = async (data) => {
  var updateTicket = await Ticket.post(`/update`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return updateTicket;
};

export const destroyTicket = async (data) => {
  var destroyTicket = await Ticket.post(`/destroy`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return destroyTicket;
};
