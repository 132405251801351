/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import WestIcon from "@mui/icons-material/West";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  ViewAllStudents,
  updateStudents,
  viewByidStudents,
} from "../Api/student";
import { createPayment, viewallPayment } from "../Api/payment";
import { IconButton } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { Amount_splitbyStudent, updateAmount_split } from "../Api/amount_split";

const PaymentHistory = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [allstudents, setallstudents] = useState([]);
  const [singledata, setsingledata] = useState([]);
  const [alldatalist, setalldatalist] = useState([]);
  const [createstatus, setcreatestatus] = useState(false);
  const [formdata, setformdata] = useState({
    notes: "",
    date: "",
    cost: "",
    student: "",
    pendingfees: "0",
    mode: "",
  });
  const [amountsplit, setamountsplit] = useState([]);
  const [selectitemid, setselectitemid] = useState(null);
  const [disablebtn, setdisablebtn] = useState(false);

  useEffect(() => {
    getStaffdata();
  }, []);
  const getStaffdata = async () => {
    var alldata = await ViewAllStudents();
    if (alldata.length !== 0) {
      var filterdata = [];
      for (var i = 0; i < alldata.length; i++) {
        var balance = Number(alldata[i].fees) - Number(alldata[i].paidfees);
        if (balance !== 0) {
          filterdata.push(alldata[i], "alldata[i]");
        }
      }
      setallstudents(alldata);
    }
    var paymenthistory = await viewallPayment();
    if (paymenthistory.length !== 0) {
      setalldatalist(paymenthistory);
      var paymenthistorydata = [];
      for (var j = 0; j < paymenthistory.length; j++) {
        paymenthistorydata.push({
          id: j + 1,
          name: paymenthistory[j].Student.name,
          cost: paymenthistory[j].cost,
          date: paymenthistory[j].date,
          data: paymenthistory[j].id,
          mode: paymenthistory[j].mode,
        });
      }
      setSelectedRows(paymenthistorydata);
    }
  };
  const handleChange = async (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name === "student") {
      var studentdata = await viewByidStudents({ id: value });
      if (studentdata.length !== 0) {
        setsingledata(studentdata);
        var balance =
          Number(studentdata[0].fees) - Number(studentdata[0].paidfees);
        if (balance !== 0) {
          setformdata((values) => ({ ...values, pendingfees: balance }));
        } else {
          toast.info("No Pending Fees...", {
            autoClose: 2000,
            transition: Slide,
          });
          setformdata((values) => ({ ...values, pendingfees: 0 }));
        }
      }
      var amountsplit = await Amount_splitbyStudent({ id: value });
      setamountsplit(amountsplit);
      setformdata((values) => ({ ...values, [name]: value }));
      setselectitemid(null);
      setformdata((values) => ({ ...values, cost: "" }));
    } else {
      setformdata((values) => ({ ...values, [name]: value }));
    }
  };
  const savebtn = async () => {
    if (formdata.pendingfees !== 0) {
      if (formdata.student.length === 0) {
        toast.error("Please Select Student...", {
          autoClose: 2000,
          transition: Slide,
        });
      } else if (formdata.date.length === 0) {
        toast.error("Please Select Date...", {
          autoClose: 2000,
          transition: Slide,
        });
      } else if (formdata.cost.length === 0) {
        toast.error("Please enter Cost...", {
          autoClose: 2000,
          transition: Slide,
        });
      } else {
        setdisablebtn(true);
        var newobj = {
          id: selectitemid,
          status: "true",
        };
        updateAmount_split(newobj);
        var newdata = {
          paidfees: Number(singledata[0].paidfees) + Number(formdata.cost),
          pendingfees:
            Number(singledata[0].fees) -
            (Number(singledata[0].paidfees) + Number(formdata.cost)),
          id: singledata[0].id,
        };

        formdata["pendingfees"] =
          Number(singledata[0].fees) -
          (Number(singledata[0].paidfees) + Number(formdata.cost));
        var createdata = await createPayment(formdata);
        if (createdata.message === "SUCCESS") {
          updateStudents(newdata);
          toast.success("Payment Details added Successfully...", {
            autoClose: 2000,
            transition: Slide,
          });
          setdisablebtn(false);
          setTimeout(() => {
            getStaffdata();
            setcreatestatus(false);
          }, 2000);
        }
      }
    } else {
      toast.info("No Pending Fees for this student...", {
        autoClose: 2000,
        transition: Slide,
      });
      setdisablebtn(false);
    }
  };
  const columns = [
    { field: "id", headerName: "id", width: 150 },
    { field: "name", headerName: "name", width: 300 },
    { field: "date", headerName: "Date", width: 250 },
    { field: "cost", headerName: "Paid Amount", width: 250 },
    { field: "mode", headerName: "Mode", width: 250 },
    // {
    //   field: "actions",
    //   headerName: "Actions",
    //   width: 200,
    //   renderCell: (params) => (
    //     <>
    //       <IconButton>
    //         <Edit
    //           onClick={() => {
    //             editbtn(params.row.data);
    //           }}
    //         />
    //       </IconButton>
    //     </>
    //   ),
    // },
  ];
  const editbtn = async (e) => {
    var checkdata = await alldatalist.filter((data) => {
      return data.id == e;
    });
    if (checkdata.length !== 0) {
      setformdata({
        notes: checkdata[0].notes,
        date: checkdata[0].date,
        cost: checkdata[0].cost,
        student: checkdata[0].student,
        pendingfees: checkdata[0].pendingfees,
      });
      setcreatestatus(true);
    }
  };
  const selectitem = async (e) => {
    var checkdata = await amountsplit.filter((data) => {
      return data.id == e;
    });
    setformdata((values) => ({ ...values, cost: checkdata[0].amount }));
    setselectitemid(e);
  };
  return (
    <div>
      {createstatus === false ? (
        <div className="px-14 min-h-screen">
          <div className="flex justify-between py-5 items-center">
            <div className="flex gap-2 items-center cursor-pointer">
              <h1 className="text-2xl py-4 font-black">Payments</h1>
            </div>

            <button
              className="bg-black text-white rounded w-[10rem] font-md py-4"
              onClick={() => {
                setcreatestatus(true);
                setformdata({
                  notes: "",
                  date: "",
                  cost: "",
                  student: "",
                  pendingfees: "0",
                });
              }}
            >
              Add New Payment
            </button>
          </div>
          <DataGrid rows={selectedRows} columns={columns} />
        </div>
      ) : (
        <div className="px-14 min-h-screen">
          <div
            className="flex gap-2 items-center cursor-pointer"
            onClick={() => {
              setcreatestatus(false);
              setformdata({
                notes: "",
                date: "",
                cost: "",
                student: "",
                pendingfees: "0",
              });
            }}
          >
            <WestIcon />
            <h1 className="text-2xl py-4 font-black">Add New Payment</h1>
          </div>
          <div className="w-full bg-white rounde shadow-2xl p-7 my-5 ">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-5">
              <div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Student
                  </h1>
                  <select
                    className="border-2 p-2 w-full py-2 rounded"
                    name="student"
                    onChange={handleChange}
                    defaultValue={formdata.student}
                  >
                    <option value="">Select Student</option>
                    {allstudents.length !== 0
                      ? allstudents.map((data, index) => (
                          <option key={index} value={data.id}>
                            {data.name}
                          </option>
                        ))
                      : null}
                  </select>
                </div>
                <div className="py-3">
                  <h1 className="text-lg">Pending Fees</h1>
                  <input
                    placeholder="Pending Fees"
                    type="text"
                    name="pendingfees"
                    disabled
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    value={formdata.pendingfees}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Date
                  </h1>
                  <input
                    placeholder="Date"
                    type="date"
                    name="date"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.date}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Cost
                  </h1>
                  <input
                    placeholder="Cost"
                    type="number"
                    name="cost"
                    disabled={amountsplit.length !== 0}
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    value={formdata.cost}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg">Notes</h1>
                  <textarea
                    placeholder="Notes"
                    type="text"
                    name="notes"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.notes}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg">Mode</h1>
                  <select
                    name="mode"
                    id=""
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.mode}
                  >
                    <option value="">Select Mode</option>
                    <option value="Online">Online</option>
                    <option value="Offline">Offline</option>
                  </select>
                </div>
              </div>
              <div>
                {amountsplit.length !== 0 ? (
                  <>
                    <h1>Amount Split Details</h1>
                    <div>
                      <table className="w-full text-center mt-5">
                        <tr>
                          <th>Select </th>
                          <th>Date</th>
                          <th>Amount</th>
                        </tr>
                        {amountsplit.length !== 0
                          ? amountsplit.map((data, index) => (
                              <tr className="mt-5" disabled key={index}>
                                <td>
                                  <input
                                    type="radio"
                                    id={data.id}
                                    name="id"
                                    value={data.id}
                                    onChange={() => {
                                      selectitem(data.id);
                                    }}
                                    checked={data.id == selectitemid}
                                    disabled={data.status === "true"}
                                  />
                                </td>

                                <td>
                                  {data.status === "true" ? (
                                    <del>{data.duedate}</del>
                                  ) : (
                                    data.duedate
                                  )}
                                </td>
                                <td>
                                  ₹{" "}
                                  {data.status === "true" ? (
                                    <del>{data.amount}</del>
                                  ) : (
                                    data.amount
                                  )}
                                  /-
                                </td>
                              </tr>
                            ))
                          : null}
                      </table>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
            <div className="py-8">
              {disablebtn === true ? (
                <button className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5">
                  Please wait...
                </button>
              ) : (
                <button
                  className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5"
                  onClick={savebtn}
                >
                  Save
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default PaymentHistory;
