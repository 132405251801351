import React, { useState } from "react";
import * as XLSX from "xlsx";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CreateCertificate } from "../Api/certificate";

const CertificateGenerator = () => {
  const [xldata, setxldata] = useState([]);
  const [clicked, setclicked] = useState(false);

  const handleFile = (file) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      if (jsonData.length !== 0) {
        setxldata(jsonData);
      }
    };

    reader.readAsBinaryString(file);
  };
  const submitbtn = async () => {
    if (xldata.length !== 0) {
      setclicked(true);
      toast.info("Please wait while the certificate is being generated....", {
        autoClose: 2000,
        transition: Slide,
      });
      for (var i = 1; i < xldata.length; i++) {
        if (xldata[i].length !== 0) {
          var newobj = {
            name: xldata[i][0],
            event: xldata[i][1],
            email: xldata[i][2],
            date: xldata[i][3],
          };
          await CreateCertificate(newobj);
        }
      }
      toast.success("A certificate was sent to all the students.....", {
        autoClose: 2000,
        transition: Slide,
      });
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      toast.error("Please Upload Xl File....", {
        autoClose: 2000,
        transition: Slide,
      });
    }
  };
  return (
    <>
      <div className="flex justify-between py-5 items-center px-12">
        <h1 className="text-2xl font-black">Certificate Generator</h1>
      </div>
      <div className="h-screen flex flex-col items-center">
        <div className="md:w-1/2 w-[90%] border-2 bg-white rounded-xl px-8 py-4 flex flex-col justify-center">
          <div>
            <h1 className="text-2xl my-8 font-semibold text-center">
              Upload Student List
            </h1>
            <label className="font-medium">File</label>
            <input
              type="file"
              placeholder="Email ID"
              name="email"
              className="w-full h-12 mt-2 mb-4 px-4 pt-2 border border-gray-200 rounded"
              onChange={(e) => handleFile(e.target.files[0])}
            />
            {clicked === true ? (
              <button className="w-full h-12 my-4 bg-black text-white rounded">
                Please Wait...
              </button>
            ) : (
              <button
                className="w-full h-12 my-4 bg-black text-white rounded"
                onClick={submitbtn}
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default CertificateGenerator;
