import React from "react";
import Coverimg from "../Assets/ui-path.webp";
import { HiOutlineClock } from "react-icons/hi";
import { AiOutlineGlobal } from "react-icons/ai";
import { FaUsers } from "react-icons/fa";

const Mycourse = () => {
  return (
    <div className="px-14 pb-5">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl py-4 font-black">My Course</h1>
      </div>
      <div className="grid md:grid-cols-3 grid-cols-1 gap-6">
        <div className="flex flex-col rounded-sm justify-evenly shadow-2xl bg-white rounded-xl">
          <img src={Coverimg} alt="Logo" className="rounded-t-xl h-56" />
          <div className="px-3 pb-5">
            <h1 className="text-lg py-2 font-bold">Course Name</h1>
            <div className="flex gap-4">
              <div className="flex gap-1 items-center">
                <HiOutlineClock className="h-5 text-xl text-gray-500" />
                <p className="text-md text-gray-500">6 Hrs</p>
              </div>
              <div className="flex gap-1 items-center">
                <AiOutlineGlobal className="h-5 text-xl text-gray-500" />
                <p className="text-md text-gray-500">English</p>
              </div>
              <div className="flex gap-1 items-center">
                <FaUsers className="h-5 text-xl text-gray-500" />
                <p className="text-md text-gray-500">6099350 Enrolled</p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col rounded-sm justify-evenly shadow-2xl bg-white rounded-xl">
          <img src={Coverimg} alt="Logo" className="rounded-t-xl h-56" />
          <div className="px-3 pb-5">
            <h1 className="text-lg py-2 font-bold">Course Name</h1>
            <div className="flex gap-4">
              <div className="flex gap-1 items-center">
                <HiOutlineClock className="h-5 text-xl text-gray-500" />
                <p className="text-md text-gray-500">6 Hrs</p>
              </div>
              <div className="flex gap-1 items-center">
                <AiOutlineGlobal className="h-5 text-xl text-gray-500" />
                <p className="text-md text-gray-500">English</p>
              </div>
              <div className="flex gap-1 items-center">
                <FaUsers className="h-5 text-xl text-gray-500" />
                <p className="text-md text-gray-500">6099350 Enrolled</p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col rounded-sm justify-evenly shadow-2xl bg-white rounded-xl">
          <img src={Coverimg} alt="Logo" className="rounded-t-xl h-56" />
          <div className="px-3 pb-5">
            <h1 className="text-lg py-2 font-bold">Course Name</h1>
            <div className="flex gap-4">
              <div className="flex gap-1 items-center">
                <HiOutlineClock className="h-5 text-xl text-gray-500" />
                <p className="text-md text-gray-500">6 Hrs</p>
              </div>
              <div className="flex gap-1 items-center">
                <AiOutlineGlobal className="h-5 text-xl text-gray-500" />
                <p className="text-md text-gray-500">English</p>
              </div>
              <div className="flex gap-1 items-center">
                <FaUsers className="h-5 text-xl text-gray-500" />
                <p className="text-md text-gray-500">6099350 Enrolled</p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col rounded-sm justify-evenly shadow-2xl bg-white rounded-xl">
          <img src={Coverimg} alt="Logo" className="rounded-t-xl h-56" />
          <div className="px-3 pb-5">
            <h1 className="text-lg py-2 font-bold">Course Name</h1>
            <div className="flex gap-4">
              <div className="flex gap-1 items-center">
                <HiOutlineClock className="h-5 text-xl text-gray-500" />
                <p className="text-md text-gray-500">6 Hrs</p>
              </div>
              <div className="flex gap-1 items-center">
                <AiOutlineGlobal className="h-5 text-xl text-gray-500" />
                <p className="text-md text-gray-500">English</p>
              </div>
              <div className="flex gap-1 items-center">
                <FaUsers className="h-5 text-xl text-gray-500" />
                <p className="text-md text-gray-500">6099350 Enrolled</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mycourse;
