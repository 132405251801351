import React, { useEffect, useState } from "react";
import "./Quiz.css";
import { viewBycategory } from "../../Api/attempt";
import {
  destroyquestion,
  updatequestion,
  viewBycategoryquestion,
} from "../../Api/question";
import {
  FaEdit,
  FaTrash,
  FaEye,
  FaWindowClose,
  FaArrowLeft,
} from "react-icons/fa";
import Modal from "react-modal";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { firebase } from "../../database/firebase";

const StudentPages1 = () => {
  const [allnewdata, setallnewdata] = useState([]);
  const [questiondata, setquestiondata] = useState([]);
  const [newmodel, setnewmodel] = useState(false);
  const [newmodeledit, setnewmodeledit] = useState(false);
  const [singledata, setsingledata] = useState([]);
  const [formdata, setformdata] = useState({
    title: "",
    option1: "",
    option2: "",
    option3: "",
    option4: "",
    answer: "",
    image: "",
  });
  const [updateid, setupdateid] = useState(null);
  const [clicked, setclicked] = useState(false);

  useEffect(() => {
    getalldata();
  }, []);
  const getalldata = async () => {
    var alldata = await viewBycategory({
      category: sessionStorage.getItem("categoryid"),
    });
    setallnewdata(alldata);
    var questiondata = await viewBycategoryquestion({
      id: sessionStorage.getItem("categoryid"),
    });
    setquestiondata(questiondata);
  };
  const viewmodel = async (data) => {
    setsingledata([data]);
    setnewmodel(true);
  };
  function closemodel() {
    setnewmodel(false);
    setsingledata([]);
  }
  function closemodelnew() {
    setnewmodeledit(false);
    setupdateid(null);
  }
  const editmodel = async (data) => {
    setnewmodeledit(true);
    setformdata({
      title: data.title,
      option1: data.option1,
      option2: data.option2,
      option3: data.option3,
      option4: data.option4,
      answer: data.answer,
      image: data.image,
    });
    setupdateid(data.id);
  };
  const geturl = async (e) => {
    toast.info("Please Wait Image is uploading...", {
      autoClose: 2000,
      transition: Slide,
    });
    const fileSize = e.target.files[0].size;
    const fileMb = fileSize / 1024 ** 2;
    if (fileMb >= 2) {
      toast.error("Please select a file less than 2MB.", {
        autoClose: 5000,
        transition: Slide,
      });
    } else {
      let file = e.target.files;
      let file13 = new Promise((resolve, reject) => {
        var storageRef = firebase.storage().ref("course/" + file[0].name);
        storageRef.put(file[0]).then(function (snapshot) {
          storageRef.getDownloadURL().then(function (url) {
            setTimeout(() => resolve(url), 1000);
          });
        });
      });
      var imgurl1 = await file13;
      if (imgurl1 !== null) {
        toast.success("Image uploaded...", {
          autoClose: 5000,
          transition: Slide,
        });
        setformdata((values) => ({ ...values, image: imgurl1 }));
      }
    }
  };
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setformdata((values) => ({ ...values, [name]: value }));
  };
  const updatbtn = async () => {
    setclicked(true);
    formdata["id"] = updateid;
    var updatedata = await updatequestion(formdata);
    if (updatedata === "Updated Successfully") {
      toast.success("Updated Successfully...", {
        autoClose: 5000,
        transition: Slide,
      });
      setnewmodeledit(false);
      setupdateid(null);
      getalldata();
      setclicked(false);
    }
  };
  const deltebtn = async (id) => {
    var deletebtn = await destroyquestion({ id: id });
    if (deletebtn === "Deleted Successfully") {
      toast.warning("Question Deleted Successfully...", {
        autoClose: 5000,
        transition: Slide,
      });
      getalldata();
    }
  };
  return (
    <>
      <div className="Quiz">
        <div className="Quiz1">
          <div className="quiz-program">
            <div
              className="flex gap-2 items-center cursor-pointer"
              onClick={() => {
                window.location.replace("/#/quiz");
              }}
            >
              <FaArrowLeft className="text-xl" />
              <h1>Quiz Programs</h1>
            </div>
            <div className="boxnewview">
              <div className="description-title w-full">
                <h1 className="text-2xl">Full Stack Event - Javascript</h1>
              </div>
              <div className="flex justify-between w-full mt-2 items-center">
                <p className="text-xl">Questions : {questiondata.length}</p>
                <p className="text-xl">Participants : {allnewdata.length}</p>
              </div>
            </div>
            <div className="flex gap-8 w-full p-4">
              <div className="w-[45%] bg-white shadow p-3">
                <h6 className="font-semibold text-xl py-5">Leaderboard</h6>
                <table className="w-full mt-2 p-2 text-center">
                  <tr>
                    <th>S.No</th>
                    <th>Student List</th>
                    <th>Score</th>
                    <th>Time Taken</th>
                    <th>Rating</th>
                  </tr>
                  {allnewdata.length !== 0
                    ? allnewdata.map((data, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{data.User.name}</td>
                          <td>
                            {data.score} / {questiondata.length}
                          </td>
                          <td>{data.timetaken}</td>
                          <td>
                            {data.feedback === null ? 0 : data.feedback} Star
                          </td>
                        </tr>
                      ))
                    : null}
                </table>
              </div>
              <div className="w-[35%] bg-white shadow p-3">
                <h6 className="font-semibold text-xl py-5">Questions</h6>
                {questiondata.length !== 0
                  ? questiondata.map((data, index) => (
                      <div className="py-4">
                        <div key={index}>
                          {index + 1} . {data.title}
                        </div>
                        <div className="flex gap-2 mt-2">
                          <FaEdit
                            className="text-xl text-black cursor-pointer"
                            onClick={() => {
                              editmodel(data);
                            }}
                          />
                          <FaTrash
                            className="text-xl text-black cursor-pointer"
                            onClick={() => {
                              deltebtn(data.id);
                            }}
                          />
                          <FaEye
                            className="text-xl text-black cursor-pointer"
                            onClick={() => {
                              viewmodel(data);
                            }}
                          />
                        </div>
                      </div>
                    ))
                  : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={newmodel}
        onRequestClose={closemodel}
        className="accountmodel"
      >
        <div className="flex justify-between">
          <h3 className="text-2xl">Question Info</h3>
          <FaWindowClose
            className="text-2xl cursor-pointer"
            onClick={closemodel}
          />
        </div>
        <hr className="my-2 border-black" />
        {singledata.length !== 0
          ? singledata.map((data, index) => (
              <div key={index} className="py-2">
                <h1 className="text-lg">{data.title}</h1>
                {data.image !== null ? (
                  <img
                    src={data.image}
                    alt=""
                    className="h-[20rem] object-cover"
                  />
                ) : null}
                <h2 className="py-2">(a) {data.option1}</h2>
                <h2 className="py-2">(b) {data.option2}</h2>
                <h2 className="py-2">(c) {data.option3}</h2>
                <h2 className="py-2">(d) {data.option4}</h2>
                <h2 className="py-2">Answer : {data.answer}</h2>
              </div>
            ))
          : null}
      </Modal>
      <Modal
        isOpen={newmodeledit}
        onRequestClose={closemodelnew}
        className="accountmodel"
      >
        <div className="flex justify-between">
          <h3 className="text-2xl">Edit Question</h3>
          <FaWindowClose
            className="text-2xl cursor-pointer"
            onClick={closemodelnew}
          />
        </div>
        <hr className="my-2 border-black" />
        <div className="mt-5">
          <div className="py-2">
            <label>Title</label>
            <input
              className="border w-full p-2"
              name="title"
              defaultValue={formdata.title}
              type="text"
              onChange={handleChange}
            />
          </div>
          <div className="py-2">
            <label>Image</label>
            <input
              className="border w-full p-2"
              name="image"
              type="file"
              onChange={geturl}
            />
          </div>
          <div className="py-2">
            <label>Option 1</label>
            <input
              className="border w-full p-2"
              name="option1"
              defaultValue={formdata.option1}
              type="text"
              onChange={handleChange}
            />
          </div>
          <div className="py-2">
            <label>Option 2</label>
            <input
              className="border w-full p-2"
              name="option2"
              defaultValue={formdata.option2}
              type="text"
              onChange={handleChange}
            />
          </div>
          <div className="py-2">
            <label>Option 3</label>
            <input
              className="border w-full p-2"
              name="option3"
              defaultValue={formdata.option3}
              type="text"
              onChange={handleChange}
            />
          </div>
          <div className="py-2">
            <label>Option 4</label>
            <input
              className="border w-full p-2"
              name="option4"
              defaultValue={formdata.option4}
              type="text"
              onChange={handleChange}
            />
          </div>
          <div className="py-2">
            <label>Answer</label>
            <input
              className="border w-full p-2"
              name="answer"
              defaultValue={formdata.answer}
              type="text"
              onChange={handleChange}
            />
          </div>
          {clicked === true ? (
            <button className="bg-black text-white rounded py-4 mt-5 w-full">
              Please Wait...
            </button>
          ) : (
            <button
              className="bg-black text-white rounded py-4 mt-5 w-full"
              onClick={updatbtn}
            >
              Update
            </button>
          )}
        </div>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default StudentPages1;
