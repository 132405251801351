import { Question } from "../axios";

export const createQuestion = async (data) => {
  var createQuestion = await Question.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return createQuestion;
};

export const viewBycategoryquestion = async (data) => {
  var viewBycategory = await Question.post(`/viewBycategory`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewBycategory;
};

export const updatequestion = async (data) => {
  var update = await Question.post(`/update`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return update;
};

export const destroyquestion = async (data) => {
  var destroy = await Question.post(`/destroy`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return destroy;
};
