import { Attempt } from "../axios";

export const viewBycategory = async (data) => {
  var viewBycategory = await Attempt.post(`/viewBycategory`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewBycategory;
};
