import { Student } from "../axios";

export const ViewAllStudents = async (data) => {
  var ViewAllStudents = await Student.post(`/viewallstudents`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return ViewAllStudents;
};

export const createStudents = async (data) => {
  var createStudents = await Student.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return createStudents;
};

export const viewByidStudents = async (data) => {
  var viewByidStudents = await Student.post(`/viewByid`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewByidStudents;
};

export const updateStudents = async (data) => {
  var updateStudents = await Student.post(`/update`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return updateStudents;
};

export const destroyStudents = async (data) => {
  var destroyStudents = await Student.post(`/destroy`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return destroyStudents;
};

export const loginStudents = async (data) => {
  var loginStudents = await Student.post(`/login`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return loginStudents;
};

export const checkpasswordStudents = async (data) => {
  var checkpasswordStudents = await Student.post(`/checkpassword`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return checkpasswordStudents;
};

export const updatepasswordStudents = async (data) => {
  var updatepasswordStudents = await Student.post(`/updatepassword`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return updatepasswordStudents;
};
