import React, { useState } from "react";
import WestIcon from "@mui/icons-material/West";
import { firebase } from "../../database/firebase";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createLesson } from "../../Api/lesson";
import { useNavigate } from "react-router-dom";

const Lesson = () => {
  const navigate = useNavigate();
  const [formdata, setformdata] = useState({
    title: "",
    description: "",
    shortdescription: "",
    duration: "",
    thumbnail: "",
    task: "",
  });
  const [disablebtn, setdisablebtn] = useState(false);
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setformdata((values) => ({ ...values, [name]: value }));
  };
  const geturl = async (e) => {
    toast.info("Please Wait Image is uploading...", {
      autoClose: 2000,
      transition: Slide,
    });
    const fileSize = e.target.files[0].size;
    const fileMb = fileSize / 1024 ** 2;
    if (fileMb >= 2) {
      toast.error("Please select a file less than 2MB.", {
        autoClose: 5000,
        transition: Slide,
      });
    } else {
      let file = e.target.files;
      let file13 = new Promise((resolve, reject) => {
        var storageRef = firebase.storage().ref("lesson/" + file[0].name);
        storageRef.put(file[0]).then(function (snapshot) {
          storageRef.getDownloadURL().then(function (url) {
            setTimeout(() => resolve(url), 1000);
          });
        });
      });
      var imgurl1 = await file13;
      if (imgurl1 !== null) {
        toast.success("Image uploaded...", {
          autoClose: 5000,
          transition: Slide,
        });
        setformdata((values) => ({ ...values, thumbnail: imgurl1 }));
      }
    }
  };
  const gettask = async (e) => {
    toast.info("Please Wait Image is uploading...", {
      autoClose: 2000,
      transition: Slide,
    });
    const fileSize = e.target.files[0].size;
    const fileMb = fileSize / 1024 ** 2;
    if (fileMb >= 2) {
      toast.error("Please select a file less than 2MB.", {
        autoClose: 5000,
        transition: Slide,
      });
    } else {
      let file = e.target.files;
      let file13 = new Promise((resolve, reject) => {
        var storageRef = firebase.storage().ref("lesson/" + file[0].name);
        storageRef.put(file[0]).then(function (snapshot) {
          storageRef.getDownloadURL().then(function (url) {
            setTimeout(() => resolve(url), 1000);
          });
        });
      });
      var imgurl1 = await file13;
      if (imgurl1 !== null) {
        toast.success("File uploaded...", {
          autoClose: 5000,
          transition: Slide,
        });
        setformdata((values) => ({ ...values, task: imgurl1 }));
      }
    }
  };
  const savebtn = async () => {
    if (formdata.title.length === 0) {
      toast.error("Please enter Title...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.description.length === 0) {
      toast.error("Please enter Description...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.shortdescription.length === 0) {
      toast.error("Please enter Short Description...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.duration.length === 0) {
      toast.error("Please enter Duration...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      setdisablebtn(true);
      var createdata = await createLesson(formdata);
      if (createdata.message === "SUCCESS") {
        toast.success("Lesson Created Successfully...", {
          autoClose: 2000,
          transition: Slide,
        });
        setdisablebtn(false);
        setTimeout(() => {
          navigate("/courses");
        }, 2000);
      }
    }
  };
  return (
    <div className="px-14 min-h-screen">
      <div
        className="flex gap-2 items-center cursor-pointer"
        onClick={() => {
          navigate("/courses");
        }}
      >
        <WestIcon />
        <h1 className="text-2xl py-4 font-black">Create New Lesson</h1>
      </div>
      <div className="w-full bg-white rounde shadow-2xl p-7 my-5 ">
        <div className="grid grid-cols-1 md:grid-cols-1 gap-8 mb-5">
          <div>
            <div className="py-3">
              <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                Title
              </h1>
              <input
                placeholder="Title"
                type="text"
                name="title"
                className="border-2 p-2 w-full py-2 rounded"
                onChange={handleChange}
                defaultValue={formdata.title}
              />
            </div>
            <div className="py-3">
              <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                Description
              </h1>
              <textarea
                placeholder="Description"
                type="text"
                name="description"
                className="border-2 p-2 w-full py-2 rounded"
                onChange={handleChange}
                defaultValue={formdata.description}
              />
            </div>
            <div className="py-3">
              <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                Short Description
              </h1>
              <textarea
                placeholder="Short Description"
                type="text"
                name="shortdescription"
                className="border-2 p-2 w-full py-2 rounded"
                onChange={handleChange}
                defaultValue={formdata.shortdescription}
              />
            </div>
            <div className="py-3">
              <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                Duration (in hours)
              </h1>
              <input
                placeholder="Duration"
                type="number"
                name="duration"
                className="border-2 p-2 w-full py-2 rounded"
                onChange={handleChange}
                defaultValue={formdata.duration}
              />
            </div>
            <div className="py-3">
              <h1 className="text-lg ">Thumbnail</h1>
              <input
                placeholder="Phone Number"
                type="file"
                name="thumbnail"
                className="border-2 p-2 w-full py-2 rounded"
                onChange={geturl}
                defaultValue={formdata.thumbnail}
              />
            </div>
            <div className="py-3">
              <h1 className="text-lg ">Task</h1>
              <input
                placeholder="Task"
                type="file"
                name="thumbnail"
                className="border-2 p-2 w-full py-2 rounded"
                onChange={gettask}
                defaultValue={formdata.thumbnail}
              />
            </div>
          </div>
        </div>
        <div className="py-8">
          {disablebtn === false ? (
            <button
              className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5"
              onClick={savebtn}
            >
              Save
            </button>
          ) : (
            <button className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5">
              Please wait...
            </button>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Lesson;
