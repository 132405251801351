import { Coupon } from "../axios";

export const viewallcoupon = async (data) => {
  var viewallcoupon = await Coupon.post(`/viewallcoupon`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewallcoupon;
};

export const createcoupon = async (data) => {
  var createcoupon = await Coupon.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return createcoupon;
};

export const updatecoupon = async (data) => {
  var updatecoupon = await Coupon.post(`/update`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return updatecoupon;
};

export const destroycoupon = async (data) => {
  var destroycoupon = await Coupon.post(`/destroy`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return destroycoupon;
};
