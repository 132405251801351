/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { createstaff, updatestaff, viewallstaff } from "../Api/staff";
import WestIcon from "@mui/icons-material/West";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { viewallFacility } from "../Api/facility";
import Citylist from "../Pages/citylist.json";
import { IoIosInformationCircle } from "react-icons/io";

const Staffinfo = () => {
  const navigate = useNavigate();

  const [selectedRows, setSelectedRows] = useState([]);
  const [alldata, setalldata] = useState([]);
  const [createstatus, setcreatestatus] = useState(false);
  const [disablebtn, setdisablebtn] = useState(false);
  const [formdata, setformdata] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    role: "",
    joiningdate: "",
    salary: "",
    dob: "",
    qualification: "",
    facility: "",
    city: "",
    state: "",
    address: "",
  });
  const [updatestatus, setupdatestatus] = useState(false);
  const [updateid, setupdateid] = useState(null);
  const [facilitydata, setfacilitydata] = useState([]);
  const [statelist, setstatelist] = useState([]);
  const [citylist, setcitylist] = useState([]);

  useEffect(() => {
    getStaffdata();
  }, []);
  const getStaffdata = async () => {
    var alldata = await viewallstaff();
    if (alldata.length !== 0) {
      var alldatanew = [];
      for (var i = 0; i < alldata.length; i++) {
        alldatanew.push({
          id: i + 1,
          name: `${alldata[i].firstname} ${alldata[i].lastname}`,
          role: alldata[i].role,
          phone: alldata[i].phone,
          email: alldata[i].email,
          joiningdate: alldata[i].joiningdate,
          salary: alldata[i].salary,
          data: alldata[i].id,
        });
      }
      setalldata(alldata);
      setSelectedRows(alldatanew);
    }
    var allfacility = await viewallFacility();
    setfacilitydata(allfacility);
    setstatelist(Citylist.states);
  };
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setformdata((values) => ({ ...values, [name]: value }));
  };
  const savebtn = async () => {
    if (formdata.firstname.length === 0) {
      toast.error("Please enter First Name...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.lastname.length === 0) {
      toast.error("Please enter Last Name...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.email.length === 0) {
      toast.error("Please enter Email...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.phone.length === 0) {
      toast.error("Please enter Phone Number...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.joiningdate.length === 0) {
      toast.error("Please Select Joining Date...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.role.length === 0) {
      toast.error("Please enter Role...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.facility.length === 0) {
      toast.error("Please Select Facility...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.salary.length === 0) {
      toast.error("Please enter Salary...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.dob.length === 0) {
      toast.error("Please select DOB...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.qualification.length === 0) {
      toast.error("Please enter Qualification...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.city.length === 0) {
      toast.error("Please enter City...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.address.length === 0) {
      toast.error("Please enter Address...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      setdisablebtn(true);
      formdata["password"] = "Test@123";
      var createdata = await createstaff(formdata);
      if (createdata.message === "SUCCESS") {
        toast.success("Staff Details added Successfully...", {
          autoClose: 2000,
          transition: Slide,
        });
        setdisablebtn(false);
        setTimeout(() => {
          getStaffdata();
          setcreatestatus(false);
        }, 2000);
      }
    }
  };
  const columns = [
    { field: "id", headerName: "id", width: 80 },
    {
      field: "name",
      headerName: "Name",
      width: 230,
      renderCell: (params) => (
        <>
          <h1
            onClick={() => {
              editbtn(params.row.data);
            }}
          >
            {params.row.name}
          </h1>
        </>
      ),
    },

    {
      field: "role",
      headerName: "Role",
      width: 150,
      renderCell: (params) => (
        <>
          <h1
            onClick={() => {
              editbtn(params.row.data);
            }}
          >
            {params.row.role}
          </h1>
        </>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      width: 220,
      renderCell: (params) => (
        <>
          <h1
            onClick={() => {
              editbtn(params.row.data);
            }}
          >
            {params.row.email}
          </h1>
        </>
      ),
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 150,
      renderCell: (params) => (
        <>
          <h1
            onClick={() => {
              editbtn(params.row.data);
            }}
          >
            {params.row.phone}
          </h1>
        </>
      ),
    },
    {
      field: "joiningdate",
      headerName: "Joining Date",
      width: 120,
      renderCell: (params) => (
        <>
          <h1
            onClick={() => {
              editbtn(params.row.data);
            }}
          >
            {params.row.joiningdate}
          </h1>
        </>
      ),
    },
    {
      field: "salary",
      headerName: "Salary",
      width: 120,
      renderCell: (params) => (
        <>
          <h1
            onClick={() => {
              editbtn(params.row.data);
            }}
          >
            {params.row.salary}
          </h1>
        </>
      ),
    },
    {
      field: "actions",
      headerName: "Action",
      width: 250,
      renderCell: (params) => (
        <div className="flex gap-2">
          <IconButton className="m-5">
            <IoIosInformationCircle
              onClick={() => {
                getdata(params.row.data);
              }}
            />
          </IconButton>
          <IconButton className="m-5">
            <Edit
              onClick={() => {
                editbtn(params.row.data);
              }}
            />
          </IconButton>
        </div>
      ),
    },
  ];
  const getdata = async (e) => {
    navigate(`/staffDetails/${e}`);
  };
  const editbtn = async (e) => {
    var checkdata = await alldata.filter((data) => {
      return data.id == e;
    });
    setformdata({
      firstname: checkdata[0].firstname,
      lastname: checkdata[0].lastname,
      email: checkdata[0].email,
      phone: checkdata[0].phone,
      role: checkdata[0].role,
      joiningdate: checkdata[0].joiningdate,
      salary: checkdata[0].salary,
      dob: checkdata[0].dob,
      qualification: checkdata[0].qualification,
      city: checkdata[0].city,
      state: checkdata[0].state,
      address: checkdata[0].address,
      facility: checkdata[0].facility,
    });
    var checkdatanew = await Citylist.states.filter((data) => {
      return data.state == checkdata[0].state;
    });
    setformdata((values) => ({ ...values, state: checkdata[0].state }));
    if (checkdatanew.length !== 0) {
      setcitylist(checkdatanew[0].districts);
    }
    setupdateid(e);
    setupdatestatus(true);
    setcreatestatus(true);
  };
  const updatbtn = async () => {
    formdata["id"] = updateid;
    setdisablebtn(true);
    var updatedata = await updatestaff(formdata);
    if (updatedata === "Updated Successfully") {
      toast.success("Staff Details Updated Successfully...", {
        autoClose: 2000,
        transition: Slide,
      });
      setdisablebtn(false);
      setTimeout(() => {
        getStaffdata();
        setcreatestatus(false);
      }, 2000);
    }
  };
  const changestate = async (e) => {
    var checkdata = await Citylist.states.filter((data) => {
      return data.state == e.target.value;
    });
    setformdata((values) => ({ ...values, state: e.target.value }));
    if (checkdata.length !== 0) {
      setcitylist(checkdata[0].districts);
    }
  };
  return (
    <div>
      {createstatus === false ? (
        <div className="px-14 min-h-screen">
          <div className="flex justify-between py-5 items-center">
            <h1 className="text-2xl py-4 font-black">Staff Information</h1>
            <button
              className="bg-black text-white rounded w-[10rem] font-md py-4"
              onClick={() => {
                setcreatestatus(true);
                setformdata({
                  firstname: "",
                  lastname: "",
                  email: "",
                  phone: "",
                  role: "",
                  joiningdate: "",
                  salary: "",
                  dob: "",
                  qualification: "",
                  facility: "",
                  city: "",
                  state: "",
                  address: "",
                });
                setupdateid(null);
                setupdatestatus(false);
              }}
            >
              Create
            </button>
          </div>
          <DataGrid rows={selectedRows} columns={columns} />
        </div>
      ) : (
        <div className="px-14 min-h-screen">
          <div
            className="flex gap-2 items-center cursor-pointer"
            onClick={() => {
              setcreatestatus(false);
              setformdata({
                firstname: "",
                lastname: "",
                email: "",
                phone: "",
                role: "",
                joiningdate: "",
                salary: "",
                dob: "",
                qualification: "",
                facility: "",
                city: "",
                state: "",
                address: "",
              });
              setupdateid(null);
            }}
          >
            <WestIcon />
            {updatestatus === true ? (
              <h1 className="text-2xl py-4 font-black">Edit Staff Details</h1>
            ) : (
              <h1 className="text-2xl py-4 font-black">Add New Staff</h1>
            )}
          </div>
          <div className="w-full bg-white rounde shadow-2xl p-7 my-5 ">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-5">
              <div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    First Name
                  </h1>
                  <input
                    placeholder="First Name"
                    type="text"
                    name="firstname"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.firstname}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Last Name
                  </h1>
                  <input
                    placeholder="Last Name"
                    type="text"
                    name="lastname"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.lastname}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Email
                  </h1>
                  <input
                    placeholder="Email"
                    type="text"
                    name="email"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.email}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Phone Number
                  </h1>
                  <input
                    placeholder="Phone Number"
                    type="text"
                    name="phone"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.phone}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Joining Date
                  </h1>
                  <input
                    placeholder="Joining Date"
                    type="date"
                    name="joiningdate"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.joiningdate}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Role
                  </h1>
                  <select
                    name="role"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.role}
                  >
                    <option value="">Select Role</option>
                    <option value="Marketing Team">Marketing Team</option>
                    <option value="Developer Team">Developer Team</option>
                    <option value="Admin">Admin</option>
                    <option value="Intern">Intern</option>
                  </select>
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Facility
                  </h1>
                  <select
                    name="facility"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.facility}
                  >
                    <option value="">Select Facility</option>
                    {facilitydata.length !== 0
                      ? facilitydata.map((data, index) => (
                          <option value={data.id} key={index}>
                            {data.name}
                          </option>
                        ))
                      : null}
                  </select>
                </div>
              </div>
              <div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Salary
                  </h1>
                  <input
                    placeholder="Salary"
                    type="number"
                    name="salary"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.salary}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    DOB
                  </h1>
                  <input
                    placeholder="DOB"
                    type="date"
                    name="dob"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.dob}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Qualification
                  </h1>
                  <input
                    placeholder="Qualification"
                    type="text"
                    name="qualification"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.qualification}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    State
                  </h1>
                  <select
                    name="state"
                    id="state"
                    className="border-2 p-2 w-full py-2 rounded"
                    required=""
                    defaultValue={formdata.state}
                    onChange={changestate}
                  >
                    <option value="">Select State</option>
                    {statelist.length !== 0
                      ? statelist.map((data, index) => (
                          <option value={data.state} key={index}>
                            {data.state}
                          </option>
                        ))
                      : null}
                  </select>
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    City
                  </h1>
                  <select
                    name="city"
                    id="city"
                    className="border-2 p-2 w-full py-2 rounded"
                    required=""
                    defaultValue={formdata.city}
                    onChange={handleChange}
                  >
                    <option value="">Select City</option>
                    {citylist.length !== 0
                      ? citylist.map((data, index) => (
                          <option value={data} key={index}>
                            {data}
                          </option>
                        ))
                      : null}
                  </select>
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Address
                  </h1>
                  <textarea
                    placeholder="Address"
                    type="text"
                    name="address"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.address}
                  />
                </div>
              </div>
            </div>
            <div className="py-8">
              {updatestatus === true ? (
                disablebtn === true ? (
                  <button className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5">
                    Please Wait...
                  </button>
                ) : (
                  <button
                    className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5"
                    onClick={updatbtn}
                  >
                    Update
                  </button>
                )
              ) : disablebtn === true ? (
                <button className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5">
                  Please Wait...
                </button>
              ) : (
                <button
                  className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5"
                  onClick={savebtn}
                >
                  Save
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default Staffinfo;
