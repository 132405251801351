/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import WestIcon from "@mui/icons-material/West";
import { firebase } from "../../database/firebase";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import { viewallModules } from "../../Api/modules";
import { updateCourses, viewByidCourses } from "../../Api/courses";
import { viewallstaff } from "../../Api/staff";

const Editcourse = () => {
  const navigate = useNavigate();
  let { id } = useParams();

  const [formdata, setformdata] = useState({
    title: "",
    description: "",
    shortdescription: "",
    duration: "",
    icon: "",
    author: "",
    price: "",
    offerprice: "",
    project: "",
    syllabus: "",
    introvideo: "",
    batch: "",
    amount_split: "",
  });
  const [disablebtn, setdisablebtn] = useState(false);
  const [lessondata, setlessondata] = useState([]);
  const [selectedlesson, setselectedlesson] = useState([]);
  const [updatedid, setupdatedid] = useState(null);
  const [selectoption, setselectoption] = useState(false);
  const [staffdata, setstaffdata] = useState([]);
  const [techdata, settechdata] = useState([]);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setformdata((values) => ({ ...values, [name]: value }));
  };
  const geturl = async (e) => {
    toast.info("Please Wait Image is uploading...", {
      autoClose: 2000,
      transition: Slide,
    });
    const fileSize = e.target.files[0].size;
    const fileMb = fileSize / 1024 ** 2;
    if (fileMb >= 2) {
      toast.error("Please select a file less than 2MB.", {
        autoClose: 5000,
        transition: Slide,
      });
    } else {
      let file = e.target.files;
      let file13 = new Promise((resolve, reject) => {
        var storageRef = firebase.storage().ref("course/" + file[0].name);
        storageRef.put(file[0]).then(function (snapshot) {
          storageRef.getDownloadURL().then(function (url) {
            setTimeout(() => resolve(url), 1000);
          });
        });
      });
      var imgurl1 = await file13;
      if (imgurl1 !== null) {
        toast.success("Image uploaded...", {
          autoClose: 5000,
          transition: Slide,
        });
        setformdata((values) => ({ ...values, icon: imgurl1 }));
      }
    }
  };
  const savebtn = async () => {
    if (formdata.title.length === 0) {
      toast.error("Please enter Title...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.description.length === 0) {
      toast.error("Please enter Description...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.shortdescription.length === 0) {
      toast.error("Please enter Short Description...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.duration.length === 0) {
      toast.error("Please enter Duration...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.price.length === 0) {
      toast.error("Please enter Price...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.offerprice.length === 0) {
      toast.error("Please enter Offer Price...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.amount_split.length === 0) {
      toast.error("Please Select Amount Split...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.icon.length === 0) {
      toast.error("Please Upload Image...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.author.length === 0) {
      toast.error("Please Enter Trainer...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      setselectoption(true);
    }
  };
  useEffect(() => {
    getalllesson();
  }, [id]);
  const getalllesson = async () => {
    var alldata = await viewallModules();
    setlessondata(alldata);
    var coursedata = await viewByidCourses({ id: id });
    if (coursedata.length !== 0) {
      setformdata({
        title: coursedata[0].title,
        description: coursedata[0].description,
        shortdescription: coursedata[0].shortdescription,
        duration: coursedata[0].duration,
        icon: coursedata[0].icon,
        author: coursedata[0].author,
        price: coursedata[0].price,
        offerprice: coursedata[0].offerprice,
        project: coursedata[0].project,
        syllabus: coursedata[0].syllabus,
        batch: coursedata[0].batch,
        introvideo: coursedata[0].introvideo,
        amount_split: coursedata[0].amount_split,
      });
      setselectedlesson(JSON.parse(coursedata[0].selectedmodule));
      setupdatedid(coursedata[0].id);
      settechdata(JSON.parse(coursedata[0].techdata));
    }
    var viewall_staff = await viewallstaff();
    if (viewall_staff.length !== 0) {
      var filterdata = await viewall_staff.filter((data) => {
        return data.role === "Developer Team";
      });
      setstaffdata(filterdata);
    }
  };
  const handlelessonSelection = (lesson) => {
    const updatedSelectedlessons = [...selectedlesson];
    if (updatedSelectedlessons.includes(lesson)) {
      updatedSelectedlessons.splice(updatedSelectedlessons.indexOf(lesson), 1);
    } else {
      updatedSelectedlessons.push(lesson);
    }
    setselectedlesson(updatedSelectedlessons);
  };
  const getproject = async (e) => {
    toast.info("Please Wait Image is uploading...", {
      autoClose: 2000,
      transition: Slide,
    });
    const fileSize = e.target.files[0].size;
    const fileMb = fileSize / 1024 ** 2;
    if (fileMb >= 2) {
      toast.error("Please select a file less than 2MB.", {
        autoClose: 5000,
        transition: Slide,
      });
    } else {
      let file = e.target.files;
      let file13 = new Promise((resolve, reject) => {
        var storageRef = firebase.storage().ref("course/" + file[0].name);
        storageRef.put(file[0]).then(function (snapshot) {
          storageRef.getDownloadURL().then(function (url) {
            setTimeout(() => resolve(url), 1000);
          });
        });
      });
      var imgurl1 = await file13;
      if (imgurl1 !== null) {
        toast.success("Image uploaded...", {
          autoClose: 5000,
          transition: Slide,
        });
        setformdata((values) => ({ ...values, project: imgurl1 }));
      }
    }
  };
  const removebtn = () => {
    setselectoption(!selectoption);
  };
  const submitbtn = async () => {
    if (selectedlesson.length === 0) {
      toast.error("Please Select Modules...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      formdata["selectedmodule"] = JSON.stringify(selectedlesson);
      formdata["techdata"] = JSON.stringify(techdata);
      formdata["id"] = updatedid;
      setdisablebtn(true);
      var createdata = await updateCourses(formdata);
      if (createdata === "Updated Successfully") {
        toast.success("Course Updated Successfully...", {
          autoClose: 2000,
          transition: Slide,
        });
        setdisablebtn(false);
        setTimeout(() => {
          navigate("/courses");
        }, 2000);
      }
    }
  };

  const gettech = async (e) => {
    var imagearray = [];
    const files = Array.from(e.target.files);
    toast.info("Please Wait Image is uploading...", {
      autoClose: 2000,
      transition: Slide,
    });
    setdisablebtn(true);
    for (var i = 0; i < files.length; i++) {
      // eslint-disable-next-line no-loop-func
      let file13 = new Promise((resolve, reject) => {
        var storageRef = firebase.storage().ref("logo/" + files[i].name);
        storageRef.put(files[i]).then(function (snapshot) {
          storageRef.getDownloadURL().then(function (url) {
            setTimeout(() => resolve(url), 1000);
          });
        });
      });
      var imgurl1 = await file13;
      imagearray.push(imgurl1);
    }

    if (files.length === imagearray.length) {
      settechdata(imagearray);
      toast.success("Attachment Uploaded..", {
        autoClose: 2000,
        transition: Slide,
      });

      setdisablebtn(false);
    }
  };

  const getintro = async (e) => {
    toast.info("Please Wait Intro Video is uploading...", {
      autoClose: 2000,
      transition: Slide,
    });
    const fileSize = e.target.files[0].size;
    const fileMb = fileSize / 1024 ** 10;
    if (fileMb >= 2) {
      toast.error("Please select a file less than 2MB.", {
        autoClose: 5000,
        transition: Slide,
      });
    } else {
      let file = e.target.files;
      let file13 = new Promise((resolve, reject) => {
        var storageRef = firebase.storage().ref("course/" + file[0].name);
        storageRef.put(file[0]).then(function (snapshot) {
          storageRef.getDownloadURL().then(function (url) {
            setTimeout(() => resolve(url), 1000);
          });
        });
      });
      var imgurl1 = await file13;
      if (imgurl1 !== null) {
        toast.success("Intro Video uploaded Successfully...", {
          autoClose: 5000,
          transition: Slide,
        });
        setformdata((values) => ({ ...values, introvideo: imgurl1 }));
      }
    }
  };
  return (
    <div className="px-14 min-h-screen">
      {selectoption === false ? (
        <div
          className="flex gap-2 items-center cursor-pointer"
          onClick={() => {
            navigate("/courses");
          }}
        >
          <WestIcon />
          <h1 className="text-2xl py-4 font-black">Edit Course</h1>
        </div>
      ) : (
        <div
          className="flex gap-2 items-center cursor-pointer"
          onClick={removebtn}
        >
          <WestIcon />
          <h1 className="text-2xl py-4 font-black">Select Modules</h1>
        </div>
      )}
      <div className="w-full bg-white rounde shadow-2xl p-7 my-5 ">
        <div className="grid grid-cols-1 md:grid-cols-1 gap-8 mb-5">
          <div>
            {selectoption === false ? (
              <>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Title
                  </h1>
                  <input
                    placeholder="Title"
                    type="text"
                    name="title"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.title}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Description
                  </h1>
                  <textarea
                    placeholder="Description"
                    type="text"
                    name="description"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.description}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Short Description
                  </h1>
                  <textarea
                    placeholder="Short Description"
                    type="text"
                    name="shortdescription"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.shortdescription}
                  />
                </div>

                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Price
                  </h1>
                  <input
                    placeholder="Price"
                    type="number"
                    name="price"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.price}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Offer Price
                  </h1>
                  <input
                    placeholder="Offer Price"
                    type="number"
                    name="offerprice"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.offerprice}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Course Amount Split
                  </h1>

                  <select
                    placeholder="Course Amount Split"
                    type="number"
                    name="amount_split"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.amount_split}
                  >
                    <option value={null}>Select Amount Split</option>
                    <option value="1" selected={formdata.amount_split == 1}>
                      1
                    </option>
                    <option value="2" selected={formdata.amount_split == 2}>
                      2
                    </option>
                    <option value="3" selected={formdata.amount_split == 3}>
                      3
                    </option>
                  </select>
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Image
                  </h1>
                  <input
                    placeholder="Phone Number"
                    type="file"
                    name="icon"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={geturl}
                    // defaultValue={formdata.icon}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Syllabus
                  </h1>
                  <input
                    placeholder="syllabus"
                    type="file"
                    name="syllabus"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={getproject}
                    // defaultValue={formdata.project}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Tech We Provide
                  </h1>
                  <input
                    placeholder="syllabus"
                    type="file"
                    name="syllabus"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={gettech}
                    multiple
                    // defaultValue={formdata.project}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg">Intro Video</h1>
                  <input
                    placeholder="Intro Video"
                    type="file"
                    name="syllabus"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={getintro}
                    accept="video/*"
                    // defaultValue={formdata.project}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Next Batch
                  </h1>
                  <input
                    placeholder="Next Batch"
                    type="date"
                    name="batch"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.batch}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Trainer
                  </h1>
                  <select
                    name="author"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.author}
                    seleted={formdata.author}
                  >
                    <option>Select Trainer</option>
                    {staffdata.length !== 0
                      ? staffdata.map((data, index) => (
                          <option
                            key={index}
                            value={`${data.firstname} ${data.lastname}`}
                            selected={
                              formdata.author ==
                              `${data.firstname} ${data.lastname}`
                            }
                          >
                            {data.firstname} {data.lastname}
                          </option>
                        ))
                      : null}
                  </select>
                </div>
              </>
            ) : (
              <div className="py-3">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-5">
                  <div className="leftsidediv border-r-2 border-gray-300">
                    <h1 className="text-lg">Select Modules</h1>
                    {lessondata.length !== 0
                      ? lessondata.map((data, index) =>
                          selectedlesson.some(
                            (obj) => obj == data.id
                          ) ? null : (
                            <div className="py-2 pl-5" key={index}>
                              <input
                                type="checkbox"
                                id={`lesson-${index}`}
                                checked={selectedlesson.includes(data.id)}
                                onChange={() => handlelessonSelection(data.id)}
                              />
                              <label className="" htmlFor={`lesson-${index}`}>
                                {" "}
                                {data.title}
                              </label>
                            </div>
                          )
                        )
                      : null}
                  </div>
                  <div>
                    <h1 className="text-lg">Selected Modules</h1>
                    {lessondata.length !== 0
                      ? lessondata.map((data, index) =>
                          selectedlesson.some((obj) => obj == data.id) ? (
                            <div className="py-2 pl-5" key={index}>
                              <input
                                type="checkbox"
                                id={`lesson-${index}`}
                                checked={selectedlesson.includes(data.id)}
                                onChange={() => handlelessonSelection(data.id)}
                              />
                              <label className="" htmlFor={`lesson-${index}`}>
                                {" "}
                                {data.title}
                              </label>
                            </div>
                          ) : null
                        )
                      : null}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="py-8">
          {selectoption === false ? (
            disablebtn === false ? (
              <button
                className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5"
                onClick={savebtn}
              >
                Next
              </button>
            ) : (
              <button className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5">
                Please wait...
              </button>
            )
          ) : disablebtn === false ? (
            <button
              className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5"
              onClick={submitbtn}
            >
              Submit
            </button>
          ) : (
            <button className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5">
              Please wait...
            </button>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Editcourse;
