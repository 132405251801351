import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyAVXIyKga3PyrYF250_YfMwAgrEVMxkDwY",
  authDomain: "codepurple-21f69.firebaseapp.com",
  projectId: "codepurple-21f69",
  storageBucket: "codepurple-21f69.appspot.com",
  messagingSenderId: "628068449796",
  appId: "1:628068449796:web:69a367f9859a7d03cbbf51",
};
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app();
}
export { firebase };
