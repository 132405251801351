import { Languages } from "../axios";

export const viewallLanguages = async (data) => {
  var viewallLanguages = await Languages.post(`/viewalllanguages`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewallLanguages;
};

export const createLanguages = async (data) => {
  var createLanguages = await Languages.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return createLanguages;
};

export const updateLanguages = async (data) => {
  var updateLanguages = await Languages.post(`/update`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return updateLanguages;
};

export const destroyLanguages = async (data) => {
  var destroyLanguages = await Languages.post(`/destroy`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return destroyLanguages;
};
