/* eslint-disable react-hooks/exhaustive-deps */
import HTMLReactParser from "html-react-parser";
import React, { useEffect, useState } from "react";
import "react-rangeslider/lib/index.css";
import WestIcon from "@mui/icons-material/West";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoMdTrash } from "react-icons/io";
import { MdEditSquare } from "react-icons/md";
import {
  createQuestiondata,
  updateQuestion,
  viewallQuestion,
} from "../Api/questiondata";

const Questiondata = () => {
  var adminid =
    sessionStorage.getItem("adminid") || localStorage.getItem("adminid");
  var role = sessionStorage.getItem("role") || localStorage.getItem("role");

  const [questionlist, setquestionlist] = useState([]);
  const [addnew, setaddnew] = useState(false);
  const [practicsdata, setpracticsdata] = useState({
    question: "",
    description: "",
  });
  const [updateid, setupdateid] = useState(null);
  const [updatestatus, setupdatestatus] = useState(false);
  const [disablebtn, setdisablebtn] = useState(false);

  const handleChangenew = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setpracticsdata((values) => ({ ...values, [name]: value }));
  };

  const savebtnnew = async () => {
    if (practicsdata.question.length === 0) {
      toast.error("Please enter Question...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (practicsdata.description.length === 0) {
      toast.error("Please enter Description...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      setdisablebtn(true);
      var newobj = {
        title: practicsdata.question,
        description: practicsdata.description,
      };
      var craeatedata = await createQuestiondata(newobj);
      if (craeatedata.message === "SUCCESS") {
        toast.success("New Question added Successfully...", {
          autoClose: 2000,
          transition: Slide,
        });
        setdisablebtn(false);
        setTimeout(() => {
          getQuestiondata();
          setaddnew(false);
          setdisablebtn(false);
        }, 2000);
      } else {
        setdisablebtn(false);
        toast.error(craeatedata.message, {
          autoClose: 2000,
          transition: Slide,
        });
      }
    }
  };
  useEffect(() => {
    getQuestiondata();
  }, [adminid]);

  const getQuestiondata = async () => {
    var alllandata = await viewallQuestion();
    setquestionlist(alllandata);
  };

  const editbtnnew = async (data) => {
    setaddnew(true);
    setpracticsdata({
      question: data.title,
      description: data.description,
    });
    setupdateid(data.id);
    setupdatestatus(true);
  };

  const updatbtnnew = async () => {
    if (practicsdata.question.length === 0) {
      toast.error("Please enter Question...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (practicsdata.description.length === 0) {
      toast.error("Please enter Description...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      setdisablebtn(true);
      practicsdata["title"] = practicsdata.question;
      practicsdata["id"] = updateid;
      var updatedata = await updateQuestion(practicsdata);
      if (updatedata === "Updated Successfully") {
        getQuestiondata();
        setTimeout(() => {
          setaddnew(false);
          setdisablebtn(false);
        }, 2000);
      }
    }
  };
  return (
    <>
      {addnew === false ? (
        <div className="px-14 pb-5">
          <div className="flex justify-between items-center">
            <div className="flex gap-2 items-center cursor-pointer">
              <h1 className="text-2xl py-4 font-black pb-5">Question</h1>
            </div>
            {role === "admin" ? (
              <button
                className="bg-black text-white rounded w-[10rem] font-md py-4"
                onClick={() => {
                  setaddnew(true);
                  setpracticsdata({
                    question: "",
                    description: "",
                  });
                  setupdateid(null);
                  setupdatestatus(false);
                }}
              >
                New Question
              </button>
            ) : null}
          </div>
          <hr className="my-2 border border-gray-500" />
          <div className="grid md:grid-cols-1 grid-cols-1 gap-6">
            {questionlist.length !== 0
              ? questionlist.map((data, index) => (
                  <div
                    className="flex flex-col rounded-sm justify-evenly shadow-2xl bg-white rounded-xl"
                    key={index}
                  >
                    <div className="px-3">
                      <div className="flex justify-between items-center pt-3">
                        <div className="flex gap-2 items-center">
                          <h1 className="text-xl font-bold cursor-pointer">
                            {data.title}
                          </h1>
                        </div>
                        {role === "admin" ? (
                          <div className="flex gap-1">
                            <MdEditSquare
                              className="text-xl text-black cursor-pointer"
                              onClick={() => {
                                editbtnnew(data);
                              }}
                            />
                            <IoMdTrash
                              className="text-xl text-red-600 cursor-pointer"
                              onClick={() => {}}
                            />
                          </div>
                        ) : null}
                      </div>
                      <div className="pb-5">
                        <p className="mt-2 smalltextsubject">
                          {HTMLReactParser(data.description)}
                        </p>
                      </div>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      ) : (
        <div className="px-14 min-h-screen">
          <div
            className="flex gap-2 items-center cursor-pointer"
            onClick={() => {
              setaddnew(false);
              setpracticsdata({
                question: "",
                description: "",
              });
              setupdateid(null);
            }}
          >
            <WestIcon />
            <h1 className="text-2xl py-4 font-black">Add New Question</h1>
          </div>
          <div className="w-full bg-white rounde shadow-2xl p-7 my-5 ">
            <div className="grid grid-cols-1 md:grid-cols-1 gap-8 mb-5">
              <div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Question
                  </h1>
                  <input
                    placeholder="Question"
                    type="text"
                    name="question"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChangenew}
                    defaultValue={practicsdata.question}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Description
                  </h1>
                  <textarea
                    placeholder="Description"
                    type="text"
                    name="description"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChangenew}
                    defaultValue={practicsdata.description}
                  />
                </div>
              </div>
            </div>
            <div className="py-8">
              {updatestatus === true ? (
                disablebtn === true ? (
                  <button className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5">
                    Please Wait..
                  </button>
                ) : (
                  <button
                    className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5"
                    onClick={updatbtnnew}
                  >
                    Update
                  </button>
                )
              ) : disablebtn === true ? (
                <button className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5">
                  Please Wait..
                </button>
              ) : (
                <button
                  className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5"
                  onClick={savebtnnew}
                >
                  Save
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </>
  );
};

export default Questiondata;
