/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import staffProfile from "../Assets/staffProfile.png";
import Group2 from "../Assets/Group 2.png";
import phone from "../Assets/phone.png";
import mail from "../Assets/mail.png";
import WestIcon from "@mui/icons-material/West";
import { useNavigate, useParams } from "react-router-dom";
import { viewByidstaff } from "../Api/staff";

const StaffDetails = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [staffdata, setstaffdata] = useState([]);

  useEffect(() => {
    getSingleuser();
  }, [id]);

  const getSingleuser = async () => {
    var studentdata = await viewByidstaff({ id: id });
    setstaffdata(studentdata);
  };
  return (
    <>
      {staffdata.length !== 0 ? (
        <div>
          <div className="flex flex-col ml-8">
            <div className="flex p-5 justify-between items-end">
              <div
                className="flex gap-2 items-center cursor-pointer"
                onClick={() => {
                  navigate(`/staff`);
                }}
              >
                <WestIcon />
                <h1 className="text-2xl font-black">
                  Staff Details About {staffdata[0].firstname}{" "}
                  {staffdata[0].lastname}
                </h1>
              </div>
              <button className="h-12 w-48 rounded-lg  text-white bg-black">
                Book Appointment
              </button>
            </div>
          </div>
          <div className="flex justify-evenly p-3 w3/4 items-center">
            <div className="h-48 w-96 bg-gray-50 rounded-lg shadow-2xl bg-blend-color shadow-slate-400">
              <div className="flex">
                <img
                  className="w-20 h-20 object-contain mt-2 ml-3 rounded-sm"
                  src={staffProfile}
                  alt="staffProfile"
                />
                <div className="flex flex-col p-3">
                  <h1 className="text-2xl font-black">
                    {staffdata[0].firstname} {staffdata[0].lastname}
                  </h1>
                  <h3>{staffdata[0].role}</h3>
                </div>
                <img
                  className="h-14 w-16 mt-4 object-contain"
                  src={Group2}
                  alt="group2"
                />
              </div>
              <div className="flex justify-between p-2  text-bold">
                <div className="">
                  <p className="text-g flex gap-2 items-center">
                    <img
                      className="h-4 w-4 ml-2 object-contain"
                      src={mail}
                      alt="mail"
                    />{" "}
                    {staffdata[0].email}
                  </p>

                  <p className="flex gap-2 items-center mt-3">
                    <img
                      className="h-4 w-4 ml-2 object-contain"
                      src={phone}
                      alt="phone"
                    />
                    {staffdata[0].phone}
                  </p>
                </div>
                <div>
                  <p>Joined on </p>
                  <p className=" text-xl "> {staffdata[0].joiningdate}</p>
                </div>
              </div>
            </div>
            <div className="flex flex-col  h-40  w-48 p-3  bg-gray-50 rounded-lg shadow-2xl bg-blend-color shadow-slate-400">
              <h1 className=" text-bold text-xl "> Placed Students</h1>
              <p className="p-4 text-6xl text-green-600">0</p>
            </div>
            <div className="flex flex-col p-2 h-40 w-48  bg-gray-50 rounded-lg shadow-2xl bg-blend-color shadow-slate-400">
              <h1 className="text-bold text-xl">Students Handled</h1>
              <p className="text-6xl p-4 underline decoration-solid decoration-3 text-blue-600">
                0
              </p>
            </div>
            <div className="font-bold text-2xl  leading-10  ">
              <h1>Current Salary</h1>
              <h1>Hostel Expense</h1>
              <h1>Ongoing batches</h1>
            </div>
            <div className="font-bold text-2xl p-4 leading-10">
              <h1>INR {staffdata[0].salary} /- </h1>
              <h1>0 /-</h1>
              <h1>0</h1>
            </div>
          </div>
          {/* <h1 className="text-3xl font-bold mt-10 ml-10">Availability</h1>
          <div className="flex justify-between  leading-10  p-10 ">
            <div className="leading-10">
              <h1 className="text-2xl font-bold mb-3 ">Batch no</h1>
              <h1 className="text-xl font-semibold mb-2">23</h1>
              <h1 className="text-xl font-semibold mb-2">27</h1>
              <h1 className="text-xl font-semibold mb-2">Demo</h1>
            </div>
            <div className="leading-10">
              <h1 className="text-2xl font-bold mb-3">Timing</h1>
              <h1 className="text-xl font-semibold mb-2">14:40</h1>
              <h1 className="text-xl font-semibold mb-2">10:20</h1>
              <h1 className="text-xl font-semibold mb-2">12:00</h1>
            </div>
            <div className=" leading-10">
              <h1 className="text-2xl font-bold mb-3">Students Nos</h1>
              <h1 className="text-xl font-semibold mb-2 underline decoration-solid decoration-3">
                5
              </h1>
              <h1 className="text-xl font-semibold mb-2 underline decoration-solid decoration-3">
                7
              </h1>
              <h1 className="text-xl font-semibold mb-2 underline decoration-solid decoration-3">
                1
              </h1>
            </div>
            <div className=" leading-10">
              <h1 className="text-2xl font-bold mb-3">Topic</h1>
              <h1 className="text-xl font-semibold mb-2">HTML</h1>
              <h1 className="text-xl font-semibold mb-2">Javascript</h1>
              <h1 className="text-xl font-semibold mb-2">Full Stack</h1>
            </div>
            <div className=" leading-10">
              <h1 className="text-2xl font-bold mb-3">Lesson No</h1>
              <h1 className="text-xl font-semibold mb-2 ">L.no 10</h1>
              <h1 className="text-xl font-semibold mb-2">L.no 123</h1>
              <h1 className="text-xl font-semibold mb-2">Demo class </h1>
            </div>
          </div>
          <h1 className="font-bold text-3xl mt-8 ml-12">Projects</h1>
          <div className="flex justify-between p-10  ">
            <div className="   leading-10">
              <h1 className="text-2xl  font-bold mb-3">S.no</h1>
              <h1 className="text-xl font-semibold mb-2 ">1.</h1>
            </div>
            <div className="  leading-10">
              <h1 className="text-2xl font-bold mb-3">Name</h1>
              <h1 className="text-xl font-semibold mb-2 ">Backoffice</h1>
            </div>
            <div className=" leading-10">
              <h1 className="text-2xl font-bold mb-3">weblink</h1>
              <h1 className="text-xl font-semibold mb-2 underline decoration-solid decoration-3">
                www.netlify.app
              </h1>
            </div>
            <div className="  leading-10">
              <h1 className="text-2xl font-bold mb-3">Reference</h1>
              <h1 className="text-xl font-semibold mb-2 ">Nil</h1>
            </div>
            <div className="  leading-10">
              <h1 className="text-2xl font-bold mb-3">Co-ordinator</h1>
              <h1 className="text-xl font-semibold mb-2 underline decoration-solid decoration-3 ">
                Mathesh S
              </h1>
            </div>
            <div className=" leading-10">
              <h1 className="text-2xl font-bold mb-3 flex">Status </h1>
              <h1 className="text-xl font-semibold mb-2 flex">
                Ongoing{" "}
                <img
                  src={vector}
                  alt="vector"
                  className="h-5 ml-2 mt-2 object-contain w-5"
                />{" "}
              </h1>
            </div>
            <div className="  leading-10">
              <h1 className="text-2xl font-bold mb-3">Completed Date</h1>
              <h1 className="text-xl font-semibold mb-2 ">12-07-2024</h1>
            </div>
          </div> */}
        </div>
      ) : null}
    </>
  );
};

export default StaffDetails;
