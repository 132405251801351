/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  checkpasswordStudents,
  updatepasswordStudents,
  viewByidStudents,
} from "../Api/student";
import {
  checkpasswordAdmin,
  updatepasswordAdmin,
  viewByidAdmin,
} from "../Api/admin";
import {
  checkpasswordStaffs,
  updatepasswordStaffs,
  viewByidstaff,
} from "../Api/staff";

const Settings = () => {
  var adminid =
    sessionStorage.getItem("adminid") || localStorage.getItem("adminid");
  var role = sessionStorage.getItem("role") || localStorage.getItem("role");

  const [formdata, setformdata] = useState({
    email: "",
    oldpassword: "",
    newpassword: "",
  });
  const [clicked, setclicked] = useState(false);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setformdata((values) => ({ ...values, [name]: value }));
  };
  useEffect(() => {
    getalldata();
  }, [adminid]);
  const getalldata = async () => {
    if (adminid !== null) {
      if (role === "student") {
        var singledata = await viewByidStudents({ id: adminid });
        if (singledata.length !== 0) {
          setformdata({
            email: singledata[0].email,
            oldpassword: "",
            newpassword: "",
          });
        }
      } else if (role === "admin") {
        var admindata = await viewByidAdmin({ id: adminid });
        if (admindata.length !== 0) {
          setformdata({
            email: admindata[0].email,
            oldpassword: "",
            newpassword: "",
          });
        }
      } else {
        var staffdata = await viewByidstaff({ id: adminid });
        if (staffdata.length !== 0) {
          setformdata({
            email: staffdata[0].email,
            oldpassword: "",
            newpassword: "",
          });
        }
      }
    }
  };
  const submitbtn = async () => {
    if (formdata.oldpassword.length === 0) {
      toast.error("Please enter Old Password...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.newpassword.length === 0) {
      toast.error("Please enter New Password...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      if (role === "student") {
        setclicked(true);
        var checkpassword = await checkpasswordStudents({
          email: formdata.email,
          password: formdata.oldpassword,
        });
        if (checkpassword === true) {
          var updatedata = await updatepasswordStudents({
            id: adminid,
            password: formdata.newpassword,
          });
          if (updatedata.message === "Updated Successfully") {
            toast.success("Password Updated Successfully...", {
              autoClose: 2000,
              transition: Slide,
            });
            setclicked(false);
          }
        } else {
          toast.error("Old Password Not match...", {
            autoClose: 2000,
            transition: Slide,
          });
          setclicked(false);
        }
      } else if (role === "admin") {
        setclicked(true);
        var chekadmin = await checkpasswordAdmin({
          email: formdata.email,
          password: formdata.oldpassword,
        });
        if (chekadmin === true) {
          var updatenew = await updatepasswordAdmin({
            id: adminid,
            password: formdata.newpassword,
          });
          if (updatenew.message === "Updated Successfully") {
            toast.success("Password Updated Successfully...", {
              autoClose: 2000,
              transition: Slide,
            });
            setclicked(false);
          }
        } else {
          toast.error("Old Password Not match...", {
            autoClose: 2000,
            transition: Slide,
          });
          setclicked(false);
        }
      } else {
        setclicked(true);
        var chekstaff = await checkpasswordStaffs({
          email: formdata.email,
          password: formdata.oldpassword,
        });
        if (chekstaff === true) {
          var updatenewstaff = await updatepasswordStaffs({
            id: adminid,
            password: formdata.newpassword,
          });
          if (updatenewstaff.message === "Updated Successfully") {
            toast.success("Password Updated Successfully...", {
              autoClose: 2000,
              transition: Slide,
            });
            setclicked(false);
          }
        } else {
          toast.error("Old Password Not match...", {
            autoClose: 2000,
            transition: Slide,
          });
          setclicked(false);
        }
      }
    }
  };
  return (
    <div className="px-14 min-h-screen">
      <div className="flex gap-2 items-center cursor-pointer">
        <h1 className="text-2xl py-4 font-black">Change Password</h1>
      </div>
      <div className="w-full bg-white rounde shadow-2xl p-7 my-5 ">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-5">
          <div>
            <div className="py-3">
              <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                Email
              </h1>
              <input
                placeholder="Email"
                type="email"
                name="email"
                className="border-2 p-2 w-full py-2 rounded"
                onChange={handleChange}
                defaultValue={formdata.email}
                disabled
              />
            </div>
            <div className="py-3">
              <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                Old Password
              </h1>
              <input
                placeholder="Old Password"
                type="password"
                name="oldpassword"
                className="border-2 p-2 w-full py-2 rounded"
                onChange={handleChange}
                defaultValue={formdata.oldpassword}
              />
            </div>
            <div className="py-3">
              <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                New Password
              </h1>
              <input
                placeholder="New Password"
                type="password"
                name="newpassword"
                className="border-2 p-2 w-full py-2 rounded"
                onChange={handleChange}
                defaultValue={formdata.newpassword}
              />
            </div>
          </div>
        </div>
        <div className="py-8">
          {clicked === true ? (
            <button className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5">
              Please wait...
            </button>
          ) : (
            <button
              className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5"
              onClick={submitbtn}
            >
              Submit
            </button>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Settings;
