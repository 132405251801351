/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import timeGridPlugin from "@fullcalendar/timegrid";
// import listPlugin from "@fullcalendar/list";

const Appointments = () => {
  const [appointments, setAppointments] = useState([]);

  useEffect(() => {
    fetchAppointments();
  }, []);

  const fetchAppointments = () => {
    // Fetch appointments from the server and update the 'appointments' state
    // Replace this with your own logic to fetch appointments
    // For example:
    // fetch('/api/appointments')
    //   .then(response => response.json())
    //   .then(data => setAppointments(data));
    // You can format the fetched appointments to match the FullCalendar event structure
    // For example:
    // const formattedAppointments = data.map(appointment => ({
    //   title: appointment.name,
    //   start: appointment.start,
    //   end: appointment.end,
    // }));
    // setAppointments(formattedAppointments);
    setAppointments();
  };

  const createappointment = () => {
    window.location.replace("/#/createappointment");
  };
  const handleMouseEnter = (e) => {
    alert(e.event._def.title);
  };
  return (
    <div className="px-14">
      <h1 className="text-2xl font-bold mb-6">Appointments</h1>
      <div className="flex w-full">
        <div className="w-1/4 flex flex-col">
          <button
            className="w-56 rounded-md py-3 bg-black text-white"
            onClick={createappointment}
          >
            Create New
          </button>
          <LocalizationProvider className="m-0" dateAdapter={AdapterDayjs}>
            <DateCalendar />
          </LocalizationProvider>
          <div>
            <h1 className="text-blue-800 text-xl">Upcoming Events</h1>
            <div className="py-2 border-b mx-4">
              <p>Today, 5.00 Onwards</p>
              <h1>Jampack Team Meet</h1>
            </div>
            <div className="py-2 border-b mx-4">
              <p>Tomorrow, 2.35pm</p>
              <h1>Indigo Flight to Indonesia</h1>
            </div>
            <div className="py-2 border-b mx-4">
              <p>24 Jul, 9.30pm</p>
              <h1>Awwwards Conference</h1>
            </div>
          </div>
          <div className="py-3">
            <h1 className="text-blue-800 text-xl">Categories</h1>
            <div>
              <div className="py-2 mx-4 flex">
                <input type="checkbox" checked />
                <h1 className="mx-2">Meetings</h1>
              </div>
              <div className="py-2 mx-4 flex">
                <input type="checkbox" checked />
                <h1 className="mx-2">Meetings</h1>
              </div>
              <div className="py-2 mx-4 flex">
                <input type="checkbox" checked />
                <h1 className="mx-2">Birthdays</h1>
              </div>
              <div className="py-2 mx-4 flex">
                <input type="checkbox" />
                <h1 className="mx-2">Conferences</h1>
              </div>
            </div>
          </div>
        </div>

        <div className="shadow w-full h-full bg-white rounded-lg p-6">
          <FullCalendar
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              // interactionPlugin,
              // listPlugin,
            ]}
            initialView="dayGridMonth"
            headerToolbar={{
              center: "dayGridMonth,timeGridWeek,timeGridDay new",
            }}
            eventClick={handleMouseEnter}
            // events={events}
            eventColor="red"
            slotDuration="00:30:00"
            nowIndicator
            editable={true}
            eventDrop={(info) => {
              //<--- see from here
              const { start, end } = info.oldEvent._instance.range;
              const { start: newStart, end: newEnd } =
                info.event._instance.range;
              if (new Date(start).getDate() === new Date(newStart).getDate()) {
                info.revert();
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Appointments;
