/* eslint-disable react-hooks/exhaustive-deps */
import HTMLReactParser from "html-react-parser";
import React, { useEffect, useState } from "react";
import {
  PracticeByStudentLanguage,
  createPractice,
  updatePractice,
} from "../Api/practice";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import WestIcon from "@mui/icons-material/West";
import {
  createLanguages,
  updateLanguages,
  viewallLanguages,
} from "../Api/languages";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { firebase } from "../database/firebase";
import { IoMdTrash } from "react-icons/io";
import { MdEditSquare } from "react-icons/md";
import {
  ViewbyLanguage,
  createLanguages_practice,
  updateLanguages_practice,
} from "../Api/languages_practice";

const CodingPractice = () => {
  var adminid =
    sessionStorage.getItem("adminid") || localStorage.getItem("adminid");
  var role = sessionStorage.getItem("role") || localStorage.getItem("role");

  const [selectedpractics, setselectedpractics] = useState([]);
  const [practics, setpractics] = useState([]);
  const [languageslist, setlanguageslist] = useState(true);
  const [languageslistdata, setlanguageslistdata] = useState([]);
  const [addnew, setaddnew] = useState(false);
  const [formdata, setformdata] = useState({
    title: "",
    image: "",
  });
  const [practicsdata, setpracticsdata] = useState({
    question: "",
    description: "",
  });

  const [updatestatus, setupdatestatus] = useState(false);
  const [updateid, setupdateid] = useState(null);
  const [disablebtn, setdisablebtn] = useState(false);
  const [lanuageid, setlanuageid] = useState(null);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setformdata((values) => ({ ...values, [name]: value }));
  };

  const handleChangenew = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setpracticsdata((values) => ({ ...values, [name]: value }));
  };
  useEffect(() => {
    getSelectedPractics();
  }, [adminid]);
  const getSelectedPractics = async () => {
    var alllandata = await viewallLanguages();
    setlanguageslistdata(alllandata);
  };

  const handlelessonSelection = async (lesson) => {
    if (role !== "admin") {
      const updatedSelectedlessons = [...selectedpractics];
      if (updatedSelectedlessons.includes(lesson)) {
        updatedSelectedlessons.splice(
          updatedSelectedlessons.indexOf(lesson),
          1
        );
      } else {
        updatedSelectedlessons.push(lesson);
      }
      setselectedpractics(updatedSelectedlessons);

      var checkdata = await await PracticeByStudentLanguage({
        id: adminid,
        language: lanuageid,
      });
      if (checkdata.length !== 0) {
        var newobjupdate = {
          list: updatedSelectedlessons.toString(),
          id: checkdata[0].id,
        };
        var updatedata = await updatePractice(newobjupdate);
        if (updatedata === "Updated Successfully") {
          getSelectedPractics();
        }
      } else {
        var newobj = {
          student: adminid,
          list: updatedSelectedlessons.toString(),
          language: lanuageid,
        };
        var createdata = await createPractice(newobj);
        if (createdata.message === "SUCCESS") {
          getSelectedPractics();
        }
      }
    }
  };
  const selectlanguage = async (data) => {
    setlanuageid(data);
    setlanguageslist(false);
    var practicedata = await ViewbyLanguage({ id: data });
    setpractics(practicedata);
    var selecteddata = await PracticeByStudentLanguage({
      id: adminid,
      language: data,
    });
    if (selecteddata.length !== 0) {
      var b = selecteddata[0].list.split(",").map(function (item) {
        return parseInt(item);
      });
      setselectedpractics(b);
    } else {
      setselectedpractics([]);
    }
  };
  const result = Math.round((selectedpractics.length / practics.length) * 100);
  const geturl = async (e) => {
    toast.info("Please Wait Image is uploading...", {
      autoClose: 2000,
      transition: Slide,
    });
    const fileSize = e.target.files[0].size;
    const fileMb = fileSize / 1024 ** 2;
    if (fileMb >= 2) {
      toast.error("Please select a file less than 2MB.", {
        autoClose: 5000,
        transition: Slide,
      });
    } else {
      let file = e.target.files;
      let file13 = new Promise((resolve, reject) => {
        var storageRef = firebase.storage().ref("course/" + file[0].name);
        storageRef.put(file[0]).then(function (snapshot) {
          storageRef.getDownloadURL().then(function (url) {
            setTimeout(() => resolve(url), 1000);
          });
        });
      });
      var imgurl1 = await file13;
      if (imgurl1 !== null) {
        toast.success("Image uploaded...", {
          autoClose: 5000,
          transition: Slide,
        });
        setformdata((values) => ({ ...values, image: imgurl1 }));
      }
    }
  };
  const savebtn = async () => {
    if (formdata.title.length === 0) {
      toast.error("Please enter Title...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.image.length === 0) {
      toast.error("Please Uplaod Image...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      setdisablebtn(true);
      var craeatedata = await createLanguages(formdata);
      if (craeatedata.message === "SUCCESS") {
        toast.success("Languages added Successfully...", {
          autoClose: 2000,
          transition: Slide,
        });
        setdisablebtn(false);
        setTimeout(() => {
          getSelectedPractics();
          setaddnew(false);
          setdisablebtn(false);
        }, 2000);
      } else {
        setdisablebtn(false);
        toast.error(craeatedata.message, {
          autoClose: 2000,
          transition: Slide,
        });
      }
    }
  };
  const updatbtn = async () => {
    if (formdata.title.length === 0) {
      toast.error("Please enter Title...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.image.length === 0) {
      toast.error("Please Uplaod Image...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      formdata["id"] = updateid;
      var updatedata = await updateLanguages(formdata);
      if (updatedata === "Updated Successfully") {
        toast.success("Languages Updated Successfully...", {
          autoClose: 2000,
          transition: Slide,
        });
        setdisablebtn(false);
        setTimeout(() => {
          getSelectedPractics();
          setaddnew(false);
          setdisablebtn(false);
        }, 2000);
      }
    }
  };
  const editbtn = async (data) => {
    setformdata({
      title: data.title,
      image: data.image,
    });
    setaddnew(true);
    setupdateid(data.id);
    setupdatestatus(true);
  };
  const savebtnnew = async () => {
    if (practicsdata.question.length === 0) {
      toast.error("Please enter Question...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (practicsdata.description.length === 0) {
      toast.error("Please enter Description...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      practicsdata["language"] = lanuageid;
      setdisablebtn(true);
      var craeatedata = await createLanguages_practice(practicsdata);
      if (craeatedata.message === "SUCCESS") {
        toast.success("Data added Successfully...", {
          autoClose: 2000,
          transition: Slide,
        });
        selectlanguage(lanuageid);
        setdisablebtn(false);
        setTimeout(() => {
          getSelectedPractics();
          setaddnew(false);
          setdisablebtn(false);
          setlanguageslist(false);
        }, 2000);
      } else {
        setdisablebtn(false);
        toast.error(craeatedata.message, {
          autoClose: 2000,
          transition: Slide,
        });
      }
    }
  };
  const updatbtnnew = async () => {
    if (practicsdata.question.length === 0) {
      toast.error("Please enter Question...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (practicsdata.description.length === 0) {
      toast.error("Please enter Description...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      setdisablebtn(true);
      practicsdata["id"] = updateid;
      var updatedata = await updateLanguages_practice(practicsdata);
      if (updatedata === "Updated Successfully") {
        getSelectedPractics();
        selectlanguage(lanuageid);
        setTimeout(() => {
          setaddnew(false);
          setdisablebtn(false);
          setlanguageslist(false);
        }, 2000);
      }
    }
  };
  const editbtnnew = async (data) => {
    setaddnew(true);
    setpracticsdata({
      question: data.question,
      description: data.description,
    });
    setupdateid(data.id);
    setupdatestatus(true);
  };
  return (
    <>
      {languageslist === true ? (
        addnew === false ? (
          <div className="px-14 pb-5">
            <div className="flex justify-between items-center">
              <h1 className="text-2xl py-4 font-black pb-5">Languages List</h1>
              {role === "admin" ? (
                <button
                  className="bg-black text-white rounded w-[10rem] font-md py-4"
                  onClick={() => {
                    setaddnew(true);
                  }}
                >
                  New Languages
                </button>
              ) : null}
            </div>
            <hr className="py-5 mt-5 border-black" />
            <div className="grid md:grid-cols-5 grid-cols-1 gap-6">
              {languageslistdata.length !== 0
                ? languageslistdata.map((data, index) => (
                    <div
                      className="flex flex-col rounded-sm justify-evenly shadow-2xl bg-white rounded-xl cursor-pointer"
                      key={index}
                    >
                      <img
                        src={data.image}
                        alt="Logo"
                        onClick={() => {
                          selectlanguage(data.id);
                        }}
                        className="rounded-t-xl w-full object-cover h-[11rem]"
                      />
                      <div className="px-3 pb-2 border-t border-black flex justify-between items-center gap-2">
                        <h1 className="text-xl py-2 font-bold text-center">
                          {data.title}
                        </h1>
                        {role === "admin" ? (
                          <div className="flex gap-1">
                            <MdEditSquare
                              className="text-xl text-black cursor-pointer"
                              onClick={() => {
                                editbtn(data);
                              }}
                            />
                            <IoMdTrash
                              className="text-xl text-red-600 cursor-pointer"
                              onClick={() => {}}
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ))
                : null}
            </div>
          </div>
        ) : (
          <div className="px-14 min-h-screen">
            <div
              className="flex gap-2 items-center cursor-pointer"
              onClick={() => {
                setaddnew(false);
                setformdata({
                  title: "",
                  image: "",
                });
                setupdateid(null);
              }}
            >
              <WestIcon />
              <h1 className="text-2xl py-4 font-black">Add New Languages</h1>
            </div>
            <div className="w-full bg-white rounde shadow-2xl p-7 my-5 ">
              <div className="grid grid-cols-1 md:grid-cols-1 gap-8 mb-5">
                <div>
                  <div className="py-3">
                    <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                      Title
                    </h1>
                    <input
                      placeholder="Title"
                      type="text"
                      name="title"
                      className="border-2 p-2 w-full py-2 rounded"
                      onChange={handleChange}
                      defaultValue={formdata.title}
                    />
                  </div>
                  <div className="py-3">
                    <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                      Image
                    </h1>
                    <input
                      placeholder="Last Name"
                      type="file"
                      name="file"
                      className="border-2 p-2 w-full py-2 rounded"
                      onChange={geturl}
                    />
                  </div>
                </div>
              </div>
              <div className="py-8">
                {updatestatus === true ? (
                  disablebtn === true ? (
                    <button className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5">
                      Please Wait..
                    </button>
                  ) : (
                    <button
                      className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5"
                      onClick={updatbtn}
                    >
                      Update
                    </button>
                  )
                ) : disablebtn === true ? (
                  <button className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5">
                    Please Wait..
                  </button>
                ) : (
                  <button
                    className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5"
                    onClick={savebtn}
                  >
                    Save
                  </button>
                )}
              </div>
            </div>
          </div>
        )
      ) : addnew === false ? (
        <div className="px-14 pb-5">
          <div className="flex justify-between items-center">
            <div
              className="flex gap-2 items-center cursor-pointer"
              onClick={() => {
                setlanguageslist(true);
                setformdata({
                  title: "",
                  image: "",
                });
                setpracticsdata({
                  question: "",
                  description: "",
                });
                setupdateid(null);
              }}
            >
              <WestIcon />
              <h1 className="text-2xl py-4 font-black pb-5">Practics</h1>
            </div>
            {role === "admin" ? (
              <button
                className="bg-black text-white rounded w-[10rem] font-md py-4"
                onClick={() => {
                  setaddnew(true);
                  setpracticsdata({
                    question: "",
                    description: "",
                  });
                  setupdateid(null);
                  setupdatestatus(false);
                }}
              >
                New Practics
              </button>
            ) : null}
          </div>
          <hr className="py-5 border-black" />
          <div className="flex justify-between items-center">
            <div></div>
            {role === "student" ? (
              <div className="slider w-1/4">
                <Slider
                  min={0}
                  max={practics.length}
                  value={selectedpractics.length}
                />

                <div className="value text-center text-md py-2">{result} %</div>
              </div>
            ) : null}
          </div>
          <div className="grid md:grid-cols-1 grid-cols-1 gap-6">
            {practics.length !== 0
              ? practics.map((data, index) => (
                  <div
                    className="flex flex-col rounded-sm justify-evenly shadow-2xl bg-white rounded-xl"
                    key={index}
                  >
                    <div className="px-3">
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <input
                            type="checkbox"
                            id={data.title}
                            className="pt-2 text-lg"
                            name={data.title}
                            value={data.id}
                            checked={selectedpractics.includes(data.id)}
                            onChange={() => handlelessonSelection(data.id)}
                          />
                          <h1
                            className="text-lg pt-1 font-bold cursor-pointer"
                            onClick={() => {
                              handlelessonSelection(data.id);
                            }}
                          >
                            {data.question}
                          </h1>
                        </div>
                        {role === "admin" ? (
                          <div className="flex gap-1">
                            <MdEditSquare
                              className="text-xl text-black cursor-pointer"
                              onClick={() => {
                                editbtnnew(data);
                              }}
                            />
                            <IoMdTrash
                              className="text-xl text-red-600 cursor-pointer"
                              onClick={() => {}}
                            />
                          </div>
                        ) : null}
                      </div>
                      <div className="pb-5">
                        <p className="mt-2 smalltextsubject">
                          {HTMLReactParser(data.description)}
                        </p>
                      </div>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      ) : (
        <div className="px-14 min-h-screen">
          <div
            className="flex gap-2 items-center cursor-pointer"
            onClick={() => {
              setaddnew(false);
              setpracticsdata({
                question: "",
                description: "",
              });
              setupdateid(null);
            }}
          >
            <WestIcon />
            <h1 className="text-2xl py-4 font-black">Add New Practics</h1>
          </div>
          <div className="w-full bg-white rounde shadow-2xl p-7 my-5 ">
            <div className="grid grid-cols-1 md:grid-cols-1 gap-8 mb-5">
              <div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Question
                  </h1>
                  <input
                    placeholder="Question"
                    type="text"
                    name="question"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChangenew}
                    defaultValue={practicsdata.question}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Description
                  </h1>
                  <textarea
                    placeholder="Description"
                    type="text"
                    name="description"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChangenew}
                    defaultValue={practicsdata.description}
                  />
                </div>
              </div>
            </div>
            <div className="py-8">
              {updatestatus === true ? (
                disablebtn === true ? (
                  <button className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5">
                    Please Wait..
                  </button>
                ) : (
                  <button
                    className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5"
                    onClick={updatbtnnew}
                  >
                    Update
                  </button>
                )
              ) : disablebtn === true ? (
                <button className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5">
                  Please Wait..
                </button>
              ) : (
                <button
                  className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5"
                  onClick={savebtnnew}
                >
                  Save
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </>
  );
};

export default CodingPractice;
