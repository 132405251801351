import React from "react";

const Refundpolicy = () => {
  return (
    <div className="px-16 mt-12">
      <h2 className="text-3xl">Cancellation & Refund Policy</h2>
      <p className="mt-2">
        This Cancellation & Refund Policy ("Policy") governs cancellations and
        refunds for services provided by Code Purple Academy, operated by Vimix
        Technologies LLP. By using our services, you agree to be bound by this
        Policy.
      </p>
      <div className="mt-2">
        <h5 className="font-bold text-xl">1. Cancellation of Services:</h5>
        <ul style={{ listStyle: "disc" }} className="ml-12">
          <li className="font-normal mt-2">
            Student-initiated Cancellations: If a student wishes to cancel their
            enrollment in a course, they must notify us in writing at least 7
            days before the course start date. A cancellation fee may apply, and
            any refunds will be subject to the terms outlined in this Policy.
          </li>
          <li className="font-normal mt-2">
            Code Purple Academy-initiated Cancellations: In the event that Code
            Purple Academy cancels a course for any reason, such as low
            enrollment or unforeseen circumstances, registered students will be
            notified promptly, and a full refund will be provided.
          </li>
        </ul>
      </div>
      <div className="mt-2">
        <h5 className="font-bold text-xl">2. Refund Policy:</h5>
        <ul style={{ listStyle: "disc" }} className="ml-12">
          <li className="font-normal mt-2">
            Refunds for Student-initiated Cancellations: Refunds for
            student-initiated cancellations will be provided according to the
            following schedule:
          </li>
          <ul style={{ listStyle: "disc" }} className="ml-12">
            <li className="font-normal mt-2">
              Cancellation made seven or more days before the course start date:
              90% refund of the fee what they’ve paid.
            </li>
            <li className="font-normal mt-2">
              Cancellation made less than seven days before the course start
              date: No refund will be provided.
            </li>
          </ul>
          <li className="font-normal mt-2">
            Refunds for Code Purple Academy-initiated Cancellations: If Code
            Purple Academy cancels a course, a full refund of the total course
            fee will be provided to registered students.
          </li>
        </ul>
      </div>
      <div className="mt-2">
        <h5 className="font-bold text-xl">3. Refund Process:</h5>
        <ul style={{ listStyle: "disc" }} className="ml-12">
          <li className="font-normal mt-2">
            Refunds for eligible cancellations will be processed within 60 days
            of the cancellation request being approved.
          </li>
          <li className="font-normal mt-2">
            Refunds will be issued using the same method of payment used for the
            original transaction.
          </li>
        </ul>
      </div>
      <div className="mt-2">
        <h5 className="font-bold text-xl">4. Non-refundable Fees:</h5>
        <ul style={{ listStyle: "disc" }} className="ml-12">
          <li className="font-normal mt-2">
            All fees paid to Code Purple Academy, including but not limited to
            registration fees, administrative fees, and course fees, are
            non-refundable after the course starting date.
          </li>
        </ul>
      </div>
      <div className="mt-2">
        <h5 className="font-bold text-xl">5. Time Limit for Refunds:</h5>
        <ul style={{ listStyle: "disc" }} className="ml-12">
          <li className="font-normal mt-2">
            Refunds will not be provided for cancellations requested after 7
            working days from the course start date.
          </li>
        </ul>
      </div>
      <div className="mt-2">
        <h5 className="font-bold text-xl">6. Contact Us:</h5>
        <ul style={{ listStyle: "disc" }} className="ml-12">
          <li className="font-normal mt-2">
            If you have any questions or concerns about our Cancellation &
            Refund Policy, please contact us at{" "}
            <a href="mailto:hr@codepurple.in" className="text-blue-500">
              hr@codepurple.in
            </a>
            .
          </li>
        </ul>
      </div>

      <p className="mt-5">
        By using our services, you agree to be bound by this Cancellation &
        Refund Policy. If you do not agree to this Policy, please do not use our
        services.
      </p>
      <p className="mb-12 mt-5">
        This Policy was last updated on 12 April, 2024.
      </p>
    </div>
  );
};

export default Refundpolicy;
