/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import Citylist from "../Pages/citylist.json";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { firebase } from "../database/firebase";
import { updateStudents, viewByidStudents } from "../Api/student";
import { updatestaff, viewByidstaff } from "../Api/staff";
import { ResumebyStudent, Resumeupdate, createResume } from "../Api/resume";

const Profile = () => {
  var adminid =
    sessionStorage.getItem("adminid") || localStorage.getItem("adminid");
  var role = sessionStorage.getItem("role") || localStorage.getItem("role");
  const [formdata, setformdata] = useState({
    name: "",
    email: "",
    phone: "",
    profile: "",
    city: "",
    state: "",
    dob: "",
    qualification: "",
  });
  const [formdatanew, setformdatanew] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    profile: "",
    city: "",
    state: "",
    dob: "",
    qualification: "",
  });
  const [statelist, setstatelist] = useState([]);
  const [citylist, setcitylist] = useState([]);
  const [clicked, setclicked] = useState(false);
  const [taps, settaps] = useState("Profile");
  const [resume, setresume] = useState("");
  const [updateid, setupdateid] = useState(null);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setformdata((values) => ({ ...values, [name]: value }));
  };
  const handleChangenew = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setformdatanew((values) => ({ ...values, [name]: value }));
  };
  const changestate = async (e) => {
    var checkdata = await Citylist.states.filter((data) => {
      return data.state == e.target.value;
    });
    setformdata((values) => ({ ...values, state: e.target.value }));
    if (checkdata.length !== 0) {
      setcitylist(checkdata[0].districts);
    }
  };
  useEffect(() => {
    getalldata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminid]);
  const getalldata = async () => {
    setstatelist(Citylist.states);
    if (adminid !== null) {
      if (role === "student") {
        var singledata = await viewByidStudents({ id: adminid });
        if (singledata.length !== 0) {
          setformdata({
            name: singledata[0].name === null ? "" : singledata[0].name,
            email: singledata[0].email === null ? "" : singledata[0].email,
            phone: singledata[0].phone === null ? "" : singledata[0].phone,
            profile:
              singledata[0].profile === null ? "" : singledata[0].profile,
            city: singledata[0].city === null ? "" : singledata[0].city,
            state: singledata[0].state === null ? "" : singledata[0].state,
            dob: singledata[0].dob === null ? "" : singledata[0].dob,
            qualification:
              singledata[0].qualification === null
                ? ""
                : singledata[0].qualification,
          });
          var checkdata = await Citylist.states.filter((data) => {
            return data.state == singledata[0].state;
          });
          if (checkdata.length !== 0) {
            setformdata((values) => ({
              ...values,
              state: singledata[0].state,
            }));
            if (checkdata.length !== 0) {
              setcitylist(checkdata[0].districts);
            }
          }
          // setTimeout(() => {
          //   document.getElementById("state").value = singledata[0].state;
          //   document.getElementById("city").value = singledata[0].city;
          // }, 2000);
        }
        var resumedata = await ResumebyStudent({ id: adminid });
        if (resumedata.length !== 0) {
          setresume(resumedata[0].resume);
          setupdateid(resumedata[0].id);
        }
      } else if (role === "staff") {
        var staffdata = await viewByidstaff({ id: adminid });
        if (staffdata.length !== 0) {
          setformdatanew({
            firstname: staffdata[0].name === null ? "" : staffdata[0].firstname,
            lastname: staffdata[0].name === null ? "" : staffdata[0].lastname,
            email: staffdata[0].email === null ? "" : staffdata[0].email,
            phone: staffdata[0].phone === null ? "" : staffdata[0].phone,
            city: staffdata[0].city === null ? "" : staffdata[0].city,
            state: staffdata[0].state === null ? "" : staffdata[0].state,
            dob: staffdata[0].dob === null ? "" : staffdata[0].dob,
            qualification:
              staffdata[0].qualification === null
                ? ""
                : staffdata[0].qualification,
          });
          var checkdatanew = await Citylist.states.filter((data) => {
            return data.state == staffdata[0].state;
          });
          setformdata((values) => ({ ...values, state: staffdata[0].state }));
          if (checkdatanew.length !== 0) {
            setcitylist(checkdatanew[0].districts);
          }
          document.getElementById("state").value = staffdata[0].state;
          document.getElementById("city").value = staffdata[0].city;
        }
      }
    }
  };
  const geturl = async (e) => {
    toast.info("Please Wait Image is uploading...", {
      autoClose: 2000,
      transition: Slide,
    });
    const fileSize = e.target.files[0].size;
    const fileMb = fileSize / 1024 ** 2;
    if (fileMb >= 2) {
      toast.error("Please select a file less than 2MB.", {
        autoClose: 5000,
        transition: Slide,
      });
    } else {
      let file = e.target.files;
      let file13 = new Promise((resolve, reject) => {
        var storageRef = firebase.storage().ref("course/" + file[0].name);
        storageRef.put(file[0]).then(function (snapshot) {
          storageRef.getDownloadURL().then(function (url) {
            setTimeout(() => resolve(url), 1000);
          });
        });
      });
      var imgurl1 = await file13;
      if (imgurl1 !== null) {
        toast.success("Pofile uploaded successfully...", {
          autoClose: 5000,
          transition: Slide,
        });
        setformdata((values) => ({ ...values, profile: imgurl1 }));
      }
    }
  };
  const submitbtn = async () => {
    if (formdata.name.length === 0) {
      toast.error("Please enter Name...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.email.length === 0) {
      toast.error("Please enter Email...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.phone.length === 0) {
      toast.error("Please enter Phone Number...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.dob.length === 0) {
      toast.error("Please select Date Of birth...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.state.length === 0) {
      toast.error("Please enter State...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.city.length === 0) {
      toast.error("Please enter City...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.qualification.length === 0) {
      toast.error("Please enter Qualification...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      setclicked(true);
      formdata["id"] = adminid;
      var updatedata = await updateStudents(formdata);
      if (updatedata === "Updated Successfully") {
        toast.success("Profile Updated Successfully...", {
          autoClose: 2000,
          transition: Slide,
        });
        setclicked(false);
        getalldata();
      }
    }
  };
  const submitbtnstaff = async () => {
    if (formdatanew.firstname.length === 0) {
      toast.error("Please enter First Name...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdatanew.lastname.length === 0) {
      toast.error("Please enter Last Name...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdatanew.email.length === 0) {
      toast.error("Please enter Email...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdatanew.phone.length === 0) {
      toast.error("Please enter Phone Number...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdatanew.dob.length === 0) {
      toast.error("Please select Date Of birth...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdatanew.state.length === 0) {
      toast.error("Please enter State...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdatanew.city.length === 0) {
      toast.error("Please enter City...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdatanew.qualification.length === 0) {
      toast.error("Please enter Qualification...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      setclicked(true);
      formdatanew["id"] = adminid;
      var updatedata = await updatestaff(formdatanew);
      if (updatedata === "Updated Successfully") {
        toast.success("Profile Updated Successfully...", {
          autoClose: 2000,
          transition: Slide,
        });
        setclicked(false);
        getalldata();
      }
    }
  };
  const geturlnew = async (e) => {
    toast.info("Please Wait Resume is uploading...", {
      autoClose: 2000,
      transition: Slide,
    });
    const fileSize = e.target.files[0].size;
    const fileMb = fileSize / 1024 ** 2;
    if (fileMb >= 4) {
      toast.error("Please select a file less than 4 MB.", {
        autoClose: 5000,
        transition: Slide,
      });
    } else {
      let file = e.target.files;
      let file13 = new Promise((resolve, reject) => {
        var storageRef = firebase.storage().ref("course/" + file[0].name);
        storageRef.put(file[0]).then(function (snapshot) {
          storageRef.getDownloadURL().then(function (url) {
            setTimeout(() => resolve(url), 1000);
          });
        });
      });
      var imgurl1 = await file13;
      if (imgurl1 !== null) {
        toast.success("Resume uploaded successfully...", {
          autoClose: 5000,
          transition: Slide,
        });
        setresume(imgurl1);
      }
    }
  };
  const sybmitresume = async () => {
    if (resume.length == 0) {
      toast.error("Please Upload Resume...", {
        autoClose: 5000,
        transition: Slide,
      });
    } else {
      setclicked(true);
      var createresume = await createResume({
        resume: resume,
        student: adminid,
      });
      if (createresume.message === "SUCCESS") {
        toast.success("Resume Uploaded Successfully...", {
          autoClose: 5000,
          transition: Slide,
        });
        setclicked(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    }
  };
  const Updateresume = async () => {
    if (resume.length == 0) {
      toast.error("Please Upload Resume...", {
        autoClose: 5000,
        transition: Slide,
      });
    } else {
      setclicked(true);
      var createresume = await Resumeupdate({
        resume: resume,
        id: updateid,
      });
      if (createresume === "Updated Successfully") {
        toast.success("Resume Uploaded Successfully...", {
          autoClose: 5000,
          transition: Slide,
        });
        setclicked(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    }
  };
  

  return (
    <div className="px-14 min-h-screen">
      <div className="flex gap-2 items-center cursor-pointer">
        <h1 className="text-2xl py-4 font-black">Profile Information</h1>
      </div>

      <div className="flex gap-4">
        <h1
          className={
            taps === "Profile"
              ? "bg-black text-white py-2 px-5 rounded cursor-pointer"
              : "py-2 px-5 cursor-pointer"
          }
          onClick={() => {
            settaps("Profile");
          }}
        >
          Profile
        </h1>
        {role === "student" ? (
          <h1
            className={
              taps === "Resume"
                ? "bg-black text-white py-2 px-5 rounded cursor-pointer"
                : "py-2 px-5 cursor-pointer"
            }
            onClick={() => {
              settaps("Resume");
            }}
          >
            Resume
          </h1>
        ) : null}
      </div>
      {role === "student" ? (
        taps === "Profile" ? (
          <div className="w-full bg-white rounde shadow-2xl p-7 my-5 ">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-5">
              <div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Name
                  </h1>
                  <input
                    placeholder="Name"
                    type="text"
                    name="name"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.name}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Email
                  </h1>
                  <input
                    placeholder="Email"
                    type="text"
                    name="email"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.email}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Phone Number
                  </h1>
                  <input
                    placeholder="Phone Number"
                    type="text"
                    name="phone"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.phone}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Profile
                  </h1>
                  <input
                    placeholder="Phone Number"
                    type="file"
                    name="phone"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={geturl}
                  />
                </div>
              </div>
              <div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Date Of Birth
                  </h1>
                  <input
                    placeholder="Email"
                    type="date"
                    name="dob"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.dob}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    State
                  </h1>
                  <select
                    name="state"
                    id="state"
                    className="border-2 p-2 w-full py-2 rounded"
                    required=""
                    defaultValue={formdata.state}
                    onChange={changestate}
                  >
                    <option value="">Select State</option>
                    {statelist.length !== 0
                      ? statelist.map((data, index) => (
                          <option value={data.state} key={index}>
                            {data.state}
                          </option>
                        ))
                      : null}
                  </select>
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    City
                  </h1>
                  <select
                    name="city"
                    id="city"
                    className="border-2 p-2 w-full py-2 rounded"
                    defaultValue={formdata.city}
                    onChange={handleChange}
                  >
                    <option value="">Select City</option>
                    {citylist.length !== 0
                      ? citylist.map((data, index) => (
                          <option value={data} key={index}>
                            {data}
                          </option>
                        ))
                      : null}
                  </select>
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Qualification
                  </h1>
                  <input
                    placeholder="Qualification"
                    type="text"
                    name="qualification"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.qualification}
                  />
                </div>
              </div>
            </div>
            <div className="py-8">
              {clicked === true ? (
                <button className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5">
                  Please wait...
                </button>
              ) : (
                <button
                  className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5"
                  onClick={submitbtn}
                >
                  Submit
                </button>
              )}
            </div>
          </div>
        ) : taps === "Resume" ? (
          <div className="w-full bg-white rounde shadow-2xl p-7 my-5">
            <div className="py-3">
              <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                Resume
              </h1>
              <input
                placeholder="Name"
                type="file"
                name="name"
                className="border-2 p-2 w-1/2 py-2 rounded"
                onChange={geturlnew}
                accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
              />
            </div>
            <div className="py-8">
              {clicked === true ? (
                <button className="bg-black text-white py-3 rounded w-[20rem]">
                  Please wait...
                </button>
              ) : updateid === null ? (
                <button
                  className="bg-black text-white py-3 rounded w-[20rem]"
                  onClick={sybmitresume}
                >
                  Submit
                </button>
              ) : (
                <button
                  className="bg-black text-white py-3 rounded w-[20rem]"
                  onClick={Updateresume}
                >
                  Update
                </button>
              )}
            </div>
          </div>
        ) : null
      ) : (
        <div className="w-full bg-white rounde shadow-2xl p-7 my-5 ">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-5">
            <div>
              <div className="py-3">
                <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                  First Name
                </h1>
                <input
                  placeholder="First Name"
                  type="text"
                  name="firstname"
                  className="border-2 p-2 w-full py-2 rounded"
                  onChange={handleChangenew}
                  defaultValue={formdatanew.firstname}
                />
              </div>
              <div className="py-3">
                <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                  Last Name
                </h1>
                <input
                  placeholder="Last Name"
                  type="text"
                  name="lastname"
                  className="border-2 p-2 w-full py-2 rounded"
                  onChange={handleChangenew}
                  defaultValue={formdatanew.lastname}
                />
              </div>
              <div className="py-3">
                <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                  Email
                </h1>
                <input
                  placeholder="Email"
                  type="text"
                  name="email"
                  className="border-2 p-2 w-full py-2 rounded"
                  onChange={handleChangenew}
                  defaultValue={formdatanew.email}
                />
              </div>
              <div className="py-3">
                <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                  Phone Number
                </h1>
                <input
                  placeholder="Phone Number"
                  type="text"
                  name="phone"
                  className="border-2 p-2 w-full py-2 rounded"
                  onChange={handleChangenew}
                  defaultValue={formdatanew.phone}
                />
              </div>
            </div>
            <div>
              <div className="py-3">
                <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                  Date Of Birth
                </h1>
                <input
                  placeholder="Email"
                  type="date"
                  name="dob"
                  className="border-2 p-2 w-full py-2 rounded"
                  onChange={handleChangenew}
                  defaultValue={formdatanew.dob}
                />
              </div>
              <div className="py-3">
                <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                  State
                </h1>
                <select
                  name="state"
                  id="state"
                  className="border-2 p-2 w-full py-2 rounded"
                  required=""
                  defaultValue={formdatanew.state}
                  onChange={changestate}
                >
                  <option value="">Select State</option>
                  {statelist.length !== 0
                    ? statelist.map((data, index) => (
                        <option value={data.state} key={index}>
                          {data.state}
                        </option>
                      ))
                    : null}
                </select>
              </div>
              <div className="py-3">
                <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                  City
                </h1>
                <select
                  name="city"
                  id="city"
                  className="border-2 p-2 w-full py-2 rounded"
                  defaultValue={formdatanew.city}
                  onChange={handleChangenew}
                >
                  <option value="">Select City</option>
                  {citylist.length !== 0
                    ? citylist.map((data, index) => (
                        <option value={data} key={index}>
                          {data}
                        </option>
                      ))
                    : null}
                </select>
              </div>
              <div className="py-3">
                <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                  Qualification
                </h1>
                <input
                  placeholder="Qualification"
                  type="text"
                  name="qualification"
                  className="border-2 p-2 w-full py-2 rounded"
                  onChange={handleChangenew}
                  defaultValue={formdatanew.qualification}
                />
              </div>
            </div>
          </div>
          <div className="py-8">
            {clicked === true ? (
              <button className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5">
                Please wait...
              </button>
            ) : (
              <button
                className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5"
                onClick={submitbtnstaff}
              >
                Submit
              </button>
            )}
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default Profile;
