import { Category } from "../axios";

export const categoryviewByslug = async (data) => {
  var categoryviewByslug = await Category.post(`/viewByslug`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return categoryviewByslug;
};

export const viewallCategory = async (data) => {
  var viewallCategory = await Category.post(`/viewallCategory`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewallCategory;
};

export const createCategory = async (data) => {
  var createCategory = await Category.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return createCategory;
};

export const viewByidCategory = async (data) => {
  var viewByidCategory = await Category.post(`/viewByid`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewByidCategory;
};
