import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createAppointment } from "../Api/appointment";
import { useNavigate } from "react-router-dom";
import { viewallstaff } from "../Api/staff";
import WestIcon from "@mui/icons-material/West";

const CreateAppointment = () => {
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [notes, setNotes] = useState("");
  const [meetingLink, setMeetingLink] = useState("");
  const [visibility, setVisibility] = useState("");
  const [category, setCategory] = useState("");
  const [attendee, setAttendee] = useState("");
  const [priority, setpriority] = useState("");
  const [startdate, setstartdate] = useState("");
  const [enddate, setenddate] = useState("");
  const [starttime, setstarttime] = useState("");
  const [endtime, setendtime] = useState("");
  const [disablebtn, setdisablebtn] = useState(false);
  const [staffdata, setstaffdata] = useState([]);

  useEffect(() => {
    fetchAppointments();
  }, []);

  const fetchAppointments = async () => {
    var allstaff = await viewallstaff();
    setstaffdata(allstaff);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleNotesChange = (e) => {
    setNotes(e.target.value);
  };

  const handleMeetingLinkChange = (e) => {
    setMeetingLink(e.target.value);
  };

  const handleVisibilityChange = (e) => {
    setVisibility(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  const handleAttendeeChange = (e) => {
    setAttendee(e.target.value);
  };
  const handleprioritySelect = (e) => {
    setpriority(e.target.value);
  };

  const handleDateSelect = (arg) => {
    alert("Date selected: " + arg.dateStr);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (name.length === 0) {
      toast.error("Please enter Name...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (notes.length === 0) {
      toast.error("Please enter Notes / Description...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (meetingLink.length === 0) {
      toast.error("Please enter Meeting Link...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (visibility.length === 0) {
      toast.error("Please enter Visibility...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (startdate.length === 0) {
      toast.error("Please Select Start date...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (enddate.length === 0) {
      toast.error("Please Select End date...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (starttime.length === 0) {
      toast.error("Please Select Start Time...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (endtime.length === 0) {
      toast.error("Please Select End Time...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (category.length === 0) {
      toast.error("Please Select Category...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (attendee.length === 0) {
      toast.error("Please Select Attendee...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (priority.length === 0) {
      toast.error("Please enter Priority...", {
        autoClose: 2000,
        transition: Slide,
      });
    }
    const newAppointment = {
      name: name,
      notes: notes,
      link: meetingLink,
      priority: priority,
      visibility: visibility,
      category: category,
      attendee: attendee,
      start: startdate,
      end: enddate,
      starttime: starttime,
      endtime: endtime,
    };
    var createdata = await createAppointment(newAppointment);
    if (createdata.message === "SUCCESS") {
      toast.success("Appointment Created Successfully...", {
        autoClose: 2000,
        transition: Slide,
      });
      setdisablebtn(false);
      setTimeout(() => {
        navigate("/appointments");
      }, 2000);
    }
  };

  return (
    <div className="flex w-full justify-evenly">
      <div className="w-1/2 ml-5">
        <div
          className="flex gap-2 items-center cursor-pointer"
          onClick={() => {
            navigate("/appointments");
          }}
        >
          <WestIcon />
          <h1 className="text-2xl py-4 font-black">Create New Appointment</h1>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col mb-4">
            <label htmlFor="name" className="text-gray-800 font-semibold mb-2">
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Name"
              value={name}
              onChange={handleNameChange}
              className="border border-gray-300 px-3 py-2 rounded"
            />
          </div>
          <div className="flex flex-col mb-4">
            <label htmlFor="notes" className="text-gray-800 font-semibold mb-2">
              Notes/Description
            </label>
            <textarea
              id="notes"
              name="notes"
              placeholder="Notes/Description"
              value={notes}
              onChange={handleNotesChange}
              className="border border-gray-300 px-3 py-2 rounded"
            />
          </div>
          <div className="flex flex-col mb-4">
            <label
              htmlFor="meetingLink"
              className="text-gray-800 font-semibold mb-2"
            >
              Meeting Link
            </label>
            <input
              type="text"
              placeholder="Meeting Link"
              id="meetingLink"
              name="meetingLink"
              value={meetingLink}
              onChange={handleMeetingLinkChange}
              className="border border-gray-300 px-3 py-2 rounded"
            />
          </div>
          <div className="flex flex-col mb-4">
            <label
              htmlFor="visibility"
              className="text-gray-800 font-semibold mb-2"
            >
              Visibility
            </label>

            <select
              id="visibility"
              name="visibility"
              value={visibility}
              onChange={handleVisibilityChange}
              className="border border-gray-300 px-3 py-2 rounded"
            >
              <option value="">Select Visibility</option>
              <option value="No">No</option>
              <option value="Yes">Yes</option>
            </select>
          </div>
          <div className="flex flex-col mb-4">
            <label
              htmlFor="visibility"
              className="text-gray-800 font-semibold mb-2"
            >
              Start Date
            </label>
            <input
              type="date"
              id="startdate"
              name="startdate"
              value={startdate}
              onChange={(e) => {
                setstartdate(e.target.value);
              }}
              className="border border-gray-300 px-3 py-2 rounded"
            />
          </div>

          <div className="flex flex-col mb-4">
            <label
              htmlFor="visibility"
              className="text-gray-800 font-semibold mb-2"
            >
              End Date
            </label>
            <input
              type="date"
              id="enddate"
              name="enddate"
              placeholder="End Date"
              value={enddate}
              onChange={(e) => {
                setenddate(e.target.value);
              }}
              className="border border-gray-300 px-3 py-2 rounded"
            />
          </div>
          <div className="flex flex-col mb-4">
            <label
              htmlFor="visibility"
              className="text-gray-800 font-semibold mb-2"
            >
              Start Time
            </label>
            <input
              type="time"
              id="starttime"
              name="starttime"
              value={starttime}
              onChange={(e) => {
                setstarttime(e.target.value);
              }}
              className="border border-gray-300 px-3 py-2 rounded"
            />
          </div>
          <div className="flex flex-col mb-4">
            <label
              htmlFor="visibility"
              className="text-gray-800 font-semibold mb-2"
            >
              End Time
            </label>
            <input
              type="time"
              id="endtime"
              name="endtime"
              value={endtime}
              onChange={(e) => {
                setendtime(e.target.value);
              }}
              className="border border-gray-300 px-3 py-2 rounded"
            />
          </div>
          <div className="flex flex-col mb-4">
            <label
              htmlFor="category"
              className="text-gray-800 font-semibold mb-2"
            >
              Category
            </label>
            <select
              id="category"
              name="category"
              value={category}
              onChange={handleCategoryChange}
              className="border border-gray-300 px-3 py-2 rounded"
            >
              <option value="">Select category</option>
              <option value="category1">Category 1</option>
              <option value="category2">Category 2</option>
              <option value="category3">Category 3</option>
            </select>
          </div>
          <div className="flex flex-col mb-4">
            <label
              htmlFor="category"
              className="text-gray-800 font-semibold mb-2"
            >
              Select Attendee
            </label>
            <select
              id="category"
              name="category"
              value={category}
              onChange={handleAttendeeChange}
              className="border border-gray-300 px-3 py-2 rounded"
            >
              <option value="">Select Attendee</option>
              {staffdata.length !== 0
                ? staffdata.map((data, index) => (
                    <option value={data.id} key={index}>
                      {data.firstname} {data.lastname}
                    </option>
                  ))
                : null}
            </select>
          </div>

          <div className="flex flex-col mb-4">
            <span className="text-gray-800 font-semibold mb-2">
              Set Priority
            </span>
            <div className="flex items-center">
              <label htmlFor="Urgent" className="mr-4">
                <input
                  type="radio"
                  id="Urgent"
                  name="priority"
                  value="Urgent"
                  checked={priority === "Urgent"}
                  onChange={handleprioritySelect}
                  className="mr-2"
                />
                Urgent
              </label>
              <label htmlFor="High" className="mr-4">
                <input
                  type="radio"
                  id="High"
                  name="priority"
                  value="High"
                  checked={priority === "High"}
                  onChange={handleprioritySelect}
                  className="mr-2"
                />
                High
              </label>
              <label htmlFor="Medium" className="mr-4">
                <input
                  type="radio"
                  id="Medium"
                  name="priority"
                  value="Medium"
                  checked={priority === "Medium"}
                  onChange={handleprioritySelect}
                  className="mr-2"
                />
                Medium
              </label>
              <label htmlFor="Low" className="mr-4">
                <input
                  type="radio"
                  id="Low"
                  name="priority"
                  value="Low"
                  checked={priority === "Low"}
                  onChange={handleprioritySelect}
                  className="mr-2"
                />
                Low
              </label>
            </div>
          </div>
          {disablebtn === false ? (
            <button
              type="submit"
              className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
            >
              Book Appointment
            </button>
          ) : (
            <button
              type="submit"
              className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
            >
              Please Wait...
            </button>
          )}
        </form>
      </div>
      <div className="w-1/2 ml-8 mr-4">
        <h2 className="text-2xl font-bold mb-4">Appointments</h2>
        <FullCalendar
          plugins={[dayGridPlugin]}
          initialView="dayGridMonth"
          selectable={true}
          events={[
            {
              title: "Event 1",
              date: "2023-09-15",
            },
            {
              title: "Event 2",
              date: "2023-09-20",
            },
          ]}
          select={(arg) => handleDateSelect(arg)}
        />
      </div>
      <ToastContainer />
    </div>
  );
};

export default CreateAppointment;
