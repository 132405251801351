/* eslint-disable no-restricted-globals */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import WestIcon from "@mui/icons-material/West";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import {
  createReturn,
  viewallReturn,
  updateReturn,
  destroyReturn,
} from "../../Api/return";
import moment from "moment";
import { IconButton } from "@mui/material";
import { Edit } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";

const Return = () => {
  const navigate = useNavigate();

  const [selectedRows, setSelectedRows] = useState([]);
  const [createstatus, setcreatestatus] = useState(false);
  const [formdata, setformdata] = useState({
    reason: "",
    date: "",
    cost: "",
    category: "",
  });
  const [alldata, setalldata] = useState([]);
  const [editoption, seteditoption] = useState(false);
  const [editid, seteditid] = useState(null);
  const [clicked, setclicked] = useState(false);

  useEffect(() => {
    getStaffdata();
  }, []);

  const getStaffdata = async () => {
    var alldata = await viewallReturn();
    if (alldata.length !== 0) {
      setalldata(alldata);
      var alldatanew = [];
      var month = moment().format("MM");
      var year = moment().format("YYYY");
      for (var i = 0; i < alldata.length; i++) {
        if (
          alldata[i].date.split("-")[1] == month &&
          alldata[i].date.split("-")[0] == year
        ) {
          alldatanew.push({
            id: i + 1,
            reason: alldata[i].reason,
            date: alldata[i].date,
            cost: alldata[i].cost,
            category: alldata[i].category,
            data: alldata[i].id,
          });
        }
      }
      setSelectedRows(alldatanew);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setformdata((values) => ({ ...values, [name]: value }));
  };

  const savebtn = async () => {
    if (formdata.reason.length === 0) {
      toast.error("Please enter Reason...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.date.length === 0) {
      toast.error("Please Select Date...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.cost.length === 0) {
      toast.error("Please enter Cost...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.category.length === 0) {
      toast.error("Please enter category...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      setclicked(true);
      const createdata = await createReturn(formdata);
      if (createdata.message === "SUCCESS") {
        toast.success("Return Details added Successfully...", {
          autoClose: 2000,
          transition: Slide,
        });
        setTimeout(() => {
          setclicked(false);
          getStaffdata();
          setcreatestatus(false);
        }, 2000);
      }
    }
  };

  const updatebtn = async () => {
    if (formdata.reason.length === 0) {
      toast.error("Please enter Reason...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.date.length === 0) {
      toast.error("Please Select Date...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.cost.length === 0) {
      toast.error("Please enter Cost...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.category.length === 0) {
      toast.error("Please enter category...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      setclicked(true);
      formdata["id"] = editid;
      const updatedata = await updateReturn(formdata);
      if (updatedata === "Updated Successfully") {
        toast.success("Return Details Updated Successfully...", {
          autoClose: 2000,
          transition: Slide,
        });
        setclicked(false);
        setTimeout(() => {
          getStaffdata();
          setcreatestatus(false);
          seteditoption(false);
          seteditid(null);
          setformdata({
            reason: "",
            date: "",
            cost: "",
            category: "",
          });
        }, 2000);
      }
    }
  };

  const editbtn = async (id) => {
    const returnItem = alldata.find((item) => item.id === id);
    if (returnItem) {
      setformdata({
        reason: returnItem.reason || "",
        date: returnItem.date || "",
        cost: returnItem.cost || "",
        category: returnItem.category || "",
      });
      seteditoption(true);
      setcreatestatus(true);
      seteditid(returnItem.id);
    }
  };

  const deletebtn = async (id) => {
    if (confirm('Are you sure you want to delete this Return?')){
      const response = await destroyReturn({ id });
      if (response === "Deleted Successfully") {
        toast.success("Return Deleted Successfully...", {
          autoClose: 2000,
          transition: Slide,
        });
        getStaffdata();
      } else {
        toast.error("Failed to delete the return. Please try again.", {
          autoClose: 2000,
          transition: Slide,
        });
      }
    }
  };

  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    { field: "reason", headerName: "Reason", width: 300 },
    { field: "date", headerName: "Date", width: 200 },
    { field: "cost", headerName: "Cost", width: 200 },
    { field: "category", headerName: "Category", width: 200 },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => (
        <>
          <IconButton>
            <Edit
              onClick={() => {
                editbtn(params.row.data);
              }}
            />
          </IconButton>
          <IconButton>
            <DeleteIcon
              onClick={() => {
                deletebtn(params.row.data);
              }}
            />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <div>
      {createstatus === false ? (
        <div className="px-14 min-h-screen">
          <div className="flex justify-between py-5 items-center">
            <div
              className="flex gap-2 items-center cursor-pointer"
              onClick={() => {
                navigate(`/finance`);
              }}
            >
              <WestIcon />
              <h1 className="text-2xl py-4 font-black">Return of the month</h1>
            </div>

            <button
              className="bg-black text-white rounded w-[10rem] font-md py-4"
              onClick={() => {
                setcreatestatus(true);
                setformdata({
                  reason: "",
                  date: "",
                  cost: "",
                  category: "",
                });
                seteditoption(false);
                seteditid(null);
              }}
            >
              Add New Return
            </button>
          </div>
          <DataGrid rows={selectedRows} columns={columns} />
        </div>
      ) : (
        <div className="px-14 min-h-screen">
          <div
            className="flex gap-2 items-center cursor-pointer"
            onClick={() => {
              setcreatestatus(false);
              setformdata({
                reason: "",
                date: "",
                cost: "",
                category: "",
              });
            }}
          >
            <WestIcon />
            <h1 className="text-2xl py-4 font-black">Return Form</h1>
          </div>
          <div className="w-full bg-white rounde shadow-2xl p-7 my-5 ">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-5">
              <div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Reason
                  </h1>
                  <input
                    placeholder="Reason"
                    type="text"
                    name="reason"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.reason}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Date
                  </h1>
                  <input
                    placeholder="Date"
                    type="date"
                    name="date"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.date}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Cost
                  </h1>
                  <input
                    placeholder="Cost"
                    type="number"
                    name="cost"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.cost}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Category
                  </h1>
                  <input
                    placeholder="Category"
                    type="text"
                    name="category"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.category}
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-end gap-4">
              {clicked === true ? (
                <button
                  className="bg-black text-white rounded w-[10rem] font-md py-4 cursor-not-allowed"
                  disabled
                >
                  Processing...
                </button>
              ) : editoption === false ? (
                <button
                  className="bg-black text-white rounded w-[10rem] font-md py-4"
                  onClick={savebtn}
                >
                  Save
                </button>
              ) : (
                <button
                  className="bg-black text-white rounded w-[10rem] font-md py-4"
                  onClick={updatebtn}
                >
                  Update
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default Return;
