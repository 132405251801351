/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { DataGrid, GridDeleteIcon } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { viewallLesson } from "../Api/lesson";
import { viewallModules } from "../Api/modules";
import { destroyCourses, viewallCourses } from "../Api/courses";
import { IconButton } from "@mui/material";
import { Edit } from "@mui/icons-material";

const CourseDetails = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [alllesson, setalllesson] = useState([]);
  const [allmodule, setallmodule] = useState([]);
  const [coursedata, setcoursedata] = useState([]);
  const [moduledata, setmoduledata] = useState([]);
  const [lessondata, setlessondata] = useState([]);
  const [allcoursedata, setallcoursedata] = useState([]);
  const [page, setpage] = useState("Course");

  useEffect(() => {
    getalldata();
  }, []);
  const getalldata = async () => {
    var alllesson = await viewallLesson();
    var allmodule = await viewallModules();
    var allcourse = await viewallCourses();
    setalllesson(alllesson);
    setallmodule(allmodule);
    setallcoursedata(allcourse);

    if (allcourse.length !== 0) {
      var rowdata = [];
      for (var i = 0; i < allcourse.length; i++) {
        rowdata.push({
          id: i + 1,
          title: allcourse[i].title,
          duration: allcourse[i].duration,
          price: allcourse[i].price,
          offerprice: allcourse[i].offerprice,
          author: allcourse[i].author,
          data: allcourse[i].id,
        });
      }
      setcoursedata(rowdata);
    }
    if (allmodule.length !== 0) {
      var rowdata1 = [];
      for (var i = 0; i < allmodule.length; i++) {
        rowdata1.push({
          id: i + 1,
          title: allmodule[i].title,
          duration: allmodule[i].duration,
          data: allmodule[i].id,
          author: allmodule[i].author,
        });
      }
      setmoduledata(rowdata1);
    }
    if (alllesson.length !== 0) {
      var rowdata2 = [];
      for (var i = 0; i < alllesson.length; i++) {
        rowdata2.push({
          id: i + 1,
          title: alllesson[i].title,
          duration: alllesson[i].duration,
          data: alllesson[i].id,
        });
      }
      setlessondata(rowdata2);
    }
  };
  // const changemodule = async (e) => {
  //   if (allcoursedata.length !== 0) {
  //     var newdata = [];
  //     for (var i = 0; i < allcoursedata.length; i++) {
  //       var lessondata = JSON.parse(allcoursedata[i].selectedmodule);
  //       var checkdata = await lessondata.filter((data) => {
  //         return data == e;
  //       });
  //       if (checkdata.length !== 0) {
  //         newdata.push({
  //           id: i + 1,
  //           title: allcoursedata[i].title,
  //           duration: allcoursedata[i].duration,
  //           price: allcoursedata[i].price,
  //           offerprice: allcoursedata[i].offerprice,
  //           author: allcoursedata[i].author,
  //           data: allcoursedata[i].id,
  //         });
  //       }
  //     }
  //     setcoursedata(newdata);
  //   }
  // };
  const columns = [
    { field: "id", headerName: "id", width: 70 },
    { field: "title", headerName: "Course Name", width: 250 },
    { field: "duration", headerName: "Duration", width: 250 },
    { field: "price", headerName: "Price", width: 250 },
    { field: "offerprice", headerName: "Offer Price", width: 250 },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => (
        <>
          <IconButton>
            <Edit
              onClick={() => {
                editbtn(params.row.data);
              }}
            />
          </IconButton>
          <IconButton>
            <GridDeleteIcon
              onClick={() => {
                deletebtn(params.row.data);
              }}
            />
          </IconButton>
        </>
      ),
    },
  ];
  const columns1 = [
    { field: "id", headerName: "id", width: 100 },
    { field: "title", headerName: "Module Name", width: 350 },
    { field: "duration", headerName: "Duration", width: 200 },
    { field: "author", headerName: "Author", width: 300 },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => (
        <>
          <IconButton>
            <Edit
              onClick={() => {
                editModuleBtn(params.row.data);
              }}
            />
          </IconButton>
          <IconButton>
            <GridDeleteIcon
              onClick={() => {
                deletebtn(params.row.data);
              }}
            />
          </IconButton>
        </>
      ),
    },
  ];
  const columns2 = [
    { field: "id", headerName: "id", width: 100 },
    { field: "title", headerName: "Lesson Name", width: 700 },
    { field: "duration", headerName: "Duration", width: 200 },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => (
        <>
          <IconButton>
            <Edit
              onClick={() => {
                editLessonsBtn(params.row.data);
              }}
            />
          </IconButton>
          <IconButton>
            <GridDeleteIcon
              onClick={() => {
                deletebtn(params.row.data);
              }}
            />
          </IconButton>
        </>
      ),
    },
  ];

  const deletebtn = async (e) => {
    var deletedata = await destroyCourses({ id: e });
    if (deletedata === "Deleted Successfully") {
      getalldata();
    }
  };
  const editbtn = async (e) => {
    navigate(`/editcourse/${e}`);
  };
  const editModuleBtn = async (e) => {
    navigate(`/editmodule/${e}`);
  };
  const editLessonsBtn = async (e) => {
    navigate(`/editlessons/${e}`);
  };
  return (
    <div>
      {page === "Course" ? (
        <h1 className="text-2xl ml-11 font-bold">List of Courses</h1>
      ) : page === "Module" ? (
        <h1 className="text-2xl ml-11 font-bold">List of Modules</h1>
      ) : (
        <h1 className="text-2xl ml-11 font-bold">List of Lessons</h1>
      )}

      <div className="flex items-center px-14 py-5">
        <div className="text-lg flex space-x-4">
          <button
            className={
              page === "Course"
                ? "border border-black rounded py-1 px-4 bg-black text-white"
                : "border border-black rounded py-1 px-4"
            }
            onClick={() => {
              setpage("Course");
            }}
          >
            Course
          </button>
          <button
            className={
              page === "Module"
                ? "border border-black rounded py-1 px-4 bg-black text-white"
                : "border border-black rounded py-1 px-4"
            }
            onClick={() => {
              setpage("Module");
            }}
          >
            Module
          </button>
          <button
            className={
              page === "Lesson"
                ? "border border-black rounded py-1 px-4 bg-black text-white"
                : "border border-black rounded py-1 px-4"
            }
            onClick={() => {
              setpage("Lesson");
            }}
          >
            Lesson
          </button>
          {/* <select
            onChange={(e) => {
              changelesson(e.target.value);
            }}
            className="h-11 rounded-xl p-2 w-64 text-xl flex justify-evenly items-center font-bold border-2 bg-white text-black border-black"
          >
            <option value="">Select a Lesson</option>
            {alllesson.length !== 0
              ? alllesson.map((data, index) => (
                  <option value={data.id} key={index}>
                    {data.title}
                  </option>
                ))
              : null}
          </select> */}
          {/* <select
            onChange={(e) => {
              changemodule(e.target.value);
            }}
            className="h-11 rounded-lg p-2 w-64 text-md flex justify-evenly items-center font-bold border-2 bg-white text-black border-black"
          >
            <option value="">Select a Module</option>
            {allmodule.length !== 0
              ? allmodule.map((data, index) => (
                  <option value={data.id} key={index}>
                    {data.title}
                  </option>
                ))
              : null}
          </select> */}
        </div>
        <div className="flex justify-end items-end w-full">
          <button
            className="border border-black rounded py-2 px-6 bg-black text-white"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            Create
          </button>
          {isOpen && (
            <ul className="absolute h-max	p-2 w-[15rem] flex flex-col mt-40 top-12 right-8 list-none z-10 text-xl text-white bg-slate-700 rounded-xl">
              <li
                className="mt-2 cursor-pointer hover:text-gray-500"
                onClick={() => {
                  navigate("/createlesson");
                }}
              >
                Create Lesson
              </li>
              <li
                className="mt-2 cursor-pointer hover:text-gray-500"
                onClick={() => {
                  navigate("/createmodule");
                }}
              >
                Create Module
              </li>
              <li
                className="mt-2 cursor-pointer hover:text-gray-500"
                onClick={() => {
                  navigate("/createcourse");
                }}
              >
                Create Course
              </li>
            </ul>
          )}
        </div>
      </div>
      <div className="px-14 min-h-screen">
        {page === "Course" ? (
          <DataGrid
            className="border-2 border-slate-500"
            rows={coursedata}
            columns={columns}
          />
        ) : page === "Module" ? (
          <DataGrid
            className="border-2 border-slate-500"
            rows={moduledata}
            columns={columns1}
          />
        ) : (
          <DataGrid
            className="border-2 border-slate-500"
            rows={lessondata}
            columns={columns2}
          />
        )}
      </div>
    </div>
  );
};

export default CourseDetails;
