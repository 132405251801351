import { Courses } from "../axios";

export const viewallCourses = async (data) => {
  var viewallCourses = await Courses.post(`/viewallCourses`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewallCourses;
};

export const createCourses = async (data) => {
  var createCourses = await Courses.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return createCourses;
};

export const destroyCourses = async (data) => {
  var destroyCourses = await Courses.post(`/destroy`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return destroyCourses;
};

export const viewByidCourses = async (data) => {
  var viewByidCourses = await Courses.post(`/viewByid`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewByidCourses;
};

export const updateCourses = async (data) => {
  var updateCourses = await Courses.post(`/update`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return updateCourses;
};
