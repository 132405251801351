import { Return } from "../axios";

export const viewallReturn = async (data) => {
  var viewallReturn = await Return.post(`/viewallReturn`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewallReturn;
};

export const createReturn = async (data) => {
  var createReturn = await Return.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return createReturn;
};
export const updateReturn = async (data) => {
  var updateReturn = await Return.post(`/update`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return updateReturn;
};

export const destroyReturn = async (data) => {
  var destroyReturn = await Return.post(`/destroy`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return destroyReturn;
};
