/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  AttendancebyStudent,
  viewallattendancebyStudent,
} from "../Api/attendance";
import { ViewAllStudents } from "../Api/student";

const Attendance = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [studentdata, setstudentdata] = useState([]);

  const columns = [
    { field: "id", headerName: "ID", width: 100 },

    {
      field: "name",
      headerName: "Student Name",
      width: 300,
    },
    {
      field: "date",
      headerName: "Check In Date",
      width: 250,
    },
    {
      field: "time",
      headerName: "Check In Time",
      width: 250,
    },
    {
      field: "status",
      headerName: "Status",
      width: 250,
    },
  ];

  useEffect(() => {
    getAttendancedata();
  }, []);
  const getAttendancedata = async () => {
    var myattendance = await viewallattendancebyStudent();
    if (myattendance.length !== 0) {
      var alldata = [];
      for (var i = 0; i < myattendance.length; i++) {
        alldata.push({
          id: i + 1,
          name: myattendance[i].Student.name,
          date: myattendance[i].date,
          time: myattendance[i].time,
          status: myattendance[i].status === "true" ? "Present" : "Absent",
        });
      }
      setSelectedRows(alldata);
    }
    var studentdata = await ViewAllStudents();
    setstudentdata(studentdata);
  };
  const changestudent = async (e) => {
    if (e.length !== 0) {
      var myattendance = await AttendancebyStudent({ id: e });
      if (myattendance.length !== 0) {
        var alldata = [];
        for (var i = 0; i < myattendance.length; i++) {
          alldata.push({
            id: i + 1,
            name: myattendance[i].Student.name,
            date: myattendance[i].date,
            time: myattendance[i].time,
            status: myattendance[i].status === "true" ? "Present" : "Absent",
          });
        }
        setSelectedRows(alldata);
      } else {
        setSelectedRows([]);
      }
    } else {
      getAttendancedata();
    }
  };
  return (
    <div className="w-full px-14 min-h-screen">
      <div className="flex justify-between py-5 items-center">
        <h1 className="text-2xl py-4 font-black">Attendance </h1>
      </div>
      <div className="pb-3 w-1/3">
        <h1 className="text-lg">Select Student</h1>
        <select
          name="student"
          className="border-2 p-2 w-full py-2 rounded"
          onChange={(e) => {
            changestudent(e.target.value);
          }}
        >
          <option value=""> Select Student</option>
          {studentdata.length !== 0
            ? studentdata.map((data, index) => (
                <option value={data.id} key={index}>
                  {data.name}
                </option>
              ))
            : null}
        </select>
      </div>
      <div style={{ width: "100%" }}>
        <DataGrid
          rows={selectedRows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          align={"center"}
          pageSizeOptions={[10, 20, 50]}
          // checkboxSelection
        />
      </div>
    </div>
  );
};

export default Attendance;
