/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Okimg from "../Assets/download__1_-removebg-preview.png";
import { createStudents } from "../Api/student";
import moment from "moment";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createAmount_split } from "../Api/amount_split";
import { createPayment } from "../Api/payment";

const Status = () => {
  useEffect(() => {
    const getStatusapi = async () => {
      var formdata = JSON.parse(sessionStorage.getItem("formdata"));
      const payamount = sessionStorage.getItem("payamount");
      const triggeramount = sessionStorage.getItem("triggeramount");
      formdata["pendingfees"] = Math.round(
        Number(payamount) - Number(triggeramount)
      );
      formdata["paidfees"] = Number(triggeramount);
      const createstudent = await createStudents(formdata);
      if (createstudent.message === "SUCCESS") {
        checkstatus(createstudent.data.id);
      }
    };
    getStatusapi();
  }, []);
  const checkstatus = async (id) => {
    const pricesplit = JSON.parse(sessionStorage.getItem("pricesplit"));
    const payamount = sessionStorage.getItem("payamount");
    const amount = sessionStorage.getItem("amount");
    const amountsplit = sessionStorage.getItem("amountsplit");
    for (var i = 0; i < pricesplit; i++) {
      if (amountsplit == 3) {
        if (pricesplit == 1) {
          var newdata = {
            student: id,
            amount: Number(amount) - (Number(amount) * 10) / 100,
            duedate: moment().format("YYYY-MM-DD"),
            status: "true",
          };
          createAmount_split(newdata);
        } else if (pricesplit == 2) {
          var newdata2 = {
            student: id,
            amount: Math.round(
              (Number(amount) - (Number(amount) * 5) / 100) / 2
            ),
            duedate: moment()
              .add(30 * i, "days")
              .format("YYYY-MM-DD"),
            status: i == 0 ? "true" : "false",
          };
          createAmount_split(newdata2);
        } else if (pricesplit == 3) {
          var newdata3 = {
            student: id,
            amount: Math.round(
              (Number(amount) - (Number(amount) * 0) / 100) / 3
            ),
            duedate: moment()
              .add(30 * i, "days")
              .format("YYYY-MM-DD"),
            status: i == 0 ? "true" : "false",
          };
          createAmount_split(newdata3);
        }
      } else if (amountsplit == 2) {
        if (pricesplit == 1) {
          var newdata = {
            student: id,
            amount: Number(amount) - (Number(amount) * 10) / 100,
            duedate: moment().format("YYYY-MM-DD"),
            status: "true",
          };
          createAmount_split(newdata);
        } else if (pricesplit == 2) {
          var newdata2 = {
            student: id,
            amount: Math.round(
              (Number(amount) - (Number(amount) * 0) / 100) / 2
            ),
            duedate: moment()
              .add(30 * i, "days")
              .format("YYYY-MM-DD"),
            status: i == 0 ? "true" : "false",
          };
          createAmount_split(newdata2);
        }
      } else if (amountsplit == 1) {
        if (pricesplit == 1) {
          var newdata = {
            student: id,
            amount: Number(amount) - (Number(amount) * 0) / 100,
            duedate: moment().format("YYYY-MM-DD"),
            status: "true",
          };
          createAmount_split(newdata);
        }
      }
    }
    if (amountsplit == 3) {
      if (pricesplit == 1) {
        var paymentobj = {
          student: id,
          cost: Math.round(amount),
          date: moment().format("YYYY-MM-DD"),
          pendingfees: 0,
          mode: "Online",
        };
        createPayment(paymentobj);
      } else if (pricesplit == 2) {
        var paymentobj2 = {
          student: id,
          cost: Math.round(Number(payamount / 2)),
          date: moment().format("YYYY-MM-DD"),
          pendingfees: Number(payamount / 2),
          mode: "Online",
        };
        createPayment(paymentobj2);
      } else if (pricesplit == 3) {
        var paymentobj3 = {
          student: id,
          cost: Math.round(Number(payamount / 3)),
          date: moment().format("YYYY-MM-DD"),
          pendingfees: Number(amount) - Number(payamount / 3),
          mode: "Online",
        };
        createPayment(paymentobj3);
      }
    } else if (amountsplit == 2) {
      if (pricesplit == 1) {
        var paymentobj = {
          student: id,
          cost: Math.round(amount),
          date: moment().format("YYYY-MM-DD"),
          pendingfees: 0,
          mode: "Online",
        };
        createPayment(paymentobj);
      } else if (pricesplit == 2) {
        var paymentobj2 = {
          student: id,
          cost: Math.round((Number(amount) - (Number(amount) * 0) / 100) / 2),
          date: moment().format("YYYY-MM-DD"),
          pendingfees: Math.round(
            (Number(amount) - (Number(amount) * 0) / 100) / 2
          ),
          mode: "Online",
        };
        createPayment(paymentobj2);
      }
    } else if (amountsplit == 1) {
      if (pricesplit == 1) {
        var paymentobj = {
          student: id,
          cost: Math.round(amount),
          date: moment().format("YYYY-MM-DD"),
          pendingfees: 0,
          mode: "Online",
        };
        createPayment(paymentobj);
      }
    }

    toast.success("Payment Updated Successfully...", {
      autoClose: 2000,
      transition: Slide,
    });
    localStorage.clear();
    sessionStorage.clear();
    setTimeout(() => {
      window.location.href = "/";
    }, 4000);
  };
  return (
    <>
      <div className="ErrorHandling">
        <div className="ErrorHandling_content">
          <img src={Okimg} alt="" className="okimg" />
          <h1 className="mt-5">Payment processed successfully.</h1>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Status;
