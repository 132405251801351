/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import Logo from "../Assets/Logo-03 1.png";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createStudents } from "../Api/student";
import Citylist from "../Pages/citylist.json";
import { viewallCourses } from "../Api/courses";

const Existing = () => {
  const [formdata, setformdata] = useState({
    name: "",
    email: "",
    phone: "",
    city: "",
    course: "",
    dob: "",
    joiningdate: "",
    qualification: "",
    occupation: "",
    mode: "",
  });
  const [status, setstatus] = useState(false);
  const [disablebtn, setdisablebtn] = useState(false);
  const [statelist, setstatelist] = useState([]);
  const [citylist, setcitylist] = useState([]);
  const [coursedata, setcoursedata] = useState([]);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setformdata((values) => ({ ...values, [name]: value }));
  };
  const savebtn = async () => {
    if (formdata.name.length === 0) {
      toast.error("Please enter Name...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.email.length === 0) {
      toast.error("Please enter Email...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.phone.length === 0) {
      toast.error("Please enter Phone Number...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.city.length === 0) {
      toast.error("Please enter City...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.course.length === 0) {
      toast.error("Please enter Cousrse...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.dob.length === 0) {
      toast.error("Please select Date Of birth...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.qualification.length === 0) {
      toast.error("Please enter Qualification...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.mode.length === 0) {
      toast.error("Please select Mode Of class...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      setdisablebtn(true);
      formdata["payment_status"] = "true";
      formdata["pendingfees"] = 0;
      formdata["paidfees"] = 0;
      formdata["fees"] = 0;
      formdata["password"] = "Test@123";
      var createstudent = await createStudents(formdata);
      if (createstudent.message === "SUCCESS") {
        toast.success("Student added Successfully...", {
          autoClose: 2000,
          transition: Slide,
        });
        setformdata({
          name: "",
          email: "",
          phone: "",
          city: "",
          course: "",
          dob: "",
          joiningdate: "",
          qualification: "",
          occupation: "",
          mode: "",
        });
        setstatus(true);
        setTimeout(() => {
          window.location.reload();
        }, 5000);
        // setTimeout(() => {
        //   window.location.reload();
        // }, 2000);
      }
    }
  };
  useEffect(() => {
    getstatedata();
  }, []);
  const getstatedata = async () => {
    setstatelist(Citylist.states);
    var allcourse = await viewallCourses();
    setcoursedata(allcourse);
  };

  const changestate = async (e) => {
    var checkdata = await Citylist.states.filter((data) => {
      return data.state == e.target.value;
    });
    if (checkdata.length !== 0) {
      setcitylist(checkdata[0].districts);
    }
  };
  return (
    <div className="h-screen w-screen flex flex-col items-center justify-center mt-8">
      <div className="md:w-4/12 w-full bg-white rounded-xl px-8 py-4 flex flex-col justify-center">
        <div className="w-full bg-white rounde shadow-2xl p-7 my-5 linkcreatediv">
          <div className="flex flex-wrap my-2 justify-center gap-6 md:px-32">
            <div className="flex flex-col justify-center items-center">
              <img
                className="flex-shrink-0 object-cover object-center"
                src={Logo}
                alt=""
              />
            </div>
          </div>

          <div>
            <p className="md:text-xl font-bold text-sm md:w-3/4 mt-2">
              Application Form
            </p>
            <div className="py-3">
              <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                Name
              </h1>
              <input
                placeholder="Name"
                type="text"
                name="name"
                className="border-2 p-2 w-full py-2 rounded"
                onChange={handleChange}
                defaultValue={formdata.name}
              />
            </div>
            <div className="py-3">
              <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                Email
              </h1>
              <input
                placeholder="Email"
                type="text"
                name="email"
                className="border-2 p-2 w-full py-2 rounded"
                onChange={handleChange}
                defaultValue={formdata.email}
              />
            </div>
            <div className="py-3">
              <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                Phone Number
              </h1>
              <input
                placeholder="Phone Number"
                type="text"
                name="phone"
                className="border-2 p-2 w-full py-2 rounded"
                onChange={handleChange}
                defaultValue={formdata.phone}
              />
            </div>
            <div className="py-3">
              <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                State
              </h1>
              <select
                name="state"
                id="state"
                className="border-2 p-2 w-full py-2 rounded"
                required=""
                defaultValue={formdata.state}
                onChange={changestate}
              >
                <option value="">Select State</option>
                {statelist.length !== 0
                  ? statelist.map((data, index) => (
                      <option value={data.state} key={index}>
                        {data.state}
                      </option>
                    ))
                  : null}
              </select>
            </div>
            <div className="py-3">
              <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                City
              </h1>
              <select
                name="city"
                id="city"
                className="border-2 p-2 w-full py-2 rounded"
                required=""
                defaultValue={formdata.city}
                onChange={handleChange}
              >
                <option value="">Select City</option>
                {citylist.length !== 0
                  ? citylist.map((data, index) => (
                      <option value={data} key={index}>
                        {data}
                      </option>
                    ))
                  : null}
              </select>
            </div>
            <div className="py-3">
              <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                Course
              </h1>
              <select
                name="course"
                id=""
                className="border-2 p-2 w-full py-2 rounded"
                onChange={handleChange}
                defaultValue={formdata.course}
              >
                <option value="">Select Course</option>
                {coursedata.length !== 0
                  ? coursedata.map((data, index) => (
                      <option value={data.title} key={index}>
                        {data.title}
                      </option>
                    ))
                  : null}
              </select>
            </div>
            <div className="py-3">
              <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                Date Of Birth
              </h1>
              <input
                placeholder="Email"
                type="date"
                name="dob"
                className="border-2 p-2 w-full py-2 rounded"
                onChange={handleChange}
                defaultValue={formdata.dob}
              />
            </div>
            <div className="py-3">
              <h1 className="text-lg">Joining Date</h1>
              <input
                placeholder="Joining Date"
                type="date"
                name="joiningdate"
                className="border-2 p-2 w-full py-2 rounded"
                onChange={handleChange}
                defaultValue={formdata.joiningdate}
              />
            </div>
            <div className="py-3">
              <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                Qualification
              </h1>
              <input
                placeholder="Qualification"
                type="text"
                name="qualification"
                className="border-2 p-2 w-full py-2 rounded"
                onChange={handleChange}
                defaultValue={formdata.qualification}
              />
            </div>
            <div className="py-3">
              <h1 className="text-lg">Occupation</h1>
              <select
                name="occupation"
                className="border-2 p-2 w-full py-2 rounded"
                onChange={handleChange}
                defaultValue={formdata.occupation}
              >
                <option>Select Occupation</option>
                <option value="Student">Student</option>
                <option value="Unemployed">Unemployed</option>
                <option value="Working Professional">
                  Working Professional
                </option>
              </select>
            </div>
            <div className="py-3">
              <h1 className="text-lg">How you find us ?</h1>

              <select
                name="howyoufind"
                id=""
                className="border-2 p-2 w-full py-2 rounded"
                onChange={handleChange}
                defaultValue={formdata.howyoufind}
              >
                <option value="">Select</option>
                <option value="Facebook">Facebook</option>
                <option value="Instagram">Instagram</option>
                <option value="Google">Google</option>
                <option value="Local Advertisement">Local Advertisement</option>
                <option value="Recommendation from a friend">
                  Recommendation from a friend
                </option>
                <option value="Others">Others</option>
              </select>
            </div>
            <div className="py-3">
              <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                Mode Of class
              </h1>
              <select
                name="mode"
                id=""
                className="border-2 p-2 w-full py-2 rounded"
                onChange={handleChange}
                defaultValue={formdata.mode}
              >
                <option value="">Select Mode</option>
                <option value="Online">Online</option>
                <option value="Offline">Offline</option>
              </select>
            </div>

            <div className="py-3">
              {disablebtn === true ? (
                <button
                  className="w-full bg-black text-white font-md py-3 rounded-xl"
                  disabled
                >
                  Submit
                </button>
              ) : (
                <button
                  className="w-full bg-black text-white font-md py-3 rounded-xl"
                  onClick={savebtn}
                >
                  Submit
                </button>
              )}
            </div>
            {status === true ? (
              <p className="text-green-600">Student added Successfully...</p>
            ) : null}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Existing;
