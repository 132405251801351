import React from "react";
import SideBar from "../Components/SideBar";
import TopNav from "../Components/TopNav";
import Index from "../Router/Index";
import Login from "../Pages/Login";
import LinkCreate from "../Pages/LinkCreate";
import Existing from "../Pages/Existing";
import Status from "../Pages/Status";
import PrivacyPolicy from "../Pages/PrivacyPolicy";
import Termscondition from "../Pages/Termscondition";
import Refundpolicy from "../Pages/Refundpolicy";
import FailStatus from "../Pages/FailStatus";

const Layout = () => {
  var adminid =
    sessionStorage.getItem("adminid") || localStorage.getItem("adminid");
  const pathname = window.location.hash;

  return (
    <>
      {adminid === null ? (
        <>
          {pathname === "#/create_by_link" ? (
            <LinkCreate />
          ) : pathname === "#/add_existing_student" ? (
            <Existing />
          ) : pathname === "#/privacypolicy" ? (
            <PrivacyPolicy />
          ) : pathname === "#/terms" ? (
            <Termscondition />
          ) : pathname === "#/refundpolicy" ? (
            <Refundpolicy />
          ) : pathname.split("/")[1] === "success" ? (
            <Status />
          ) : pathname.split("/")[1] === "failure" ? (
            <FailStatus />
          ) : (
            <Login />
          )}
        </>
      ) : pathname === "#/create_by_link" ? (
        <LinkCreate />
      ) : pathname === "#/add_existing_student" ? (
        <Existing />
      ) : pathname === "#/privacypolicy" ? (
        <PrivacyPolicy />
      ) : pathname === "#/terms" ? (
        <Termscondition />
      ) : pathname === "#/refundpolicy" ? (
        <Refundpolicy />
      ) : pathname.split("/")[2] !== null ? (
        <div className="flex w-screen">
          <SideBar />
          <div className="w-10/12 rightside">
            <TopNav />
            <Index />
          </div>
        </div>
      ) : (
        <div className="flex w-screen">
          <SideBar />
          <div className="w-10/12 rightside">
            <TopNav />
            <Index />
          </div>
        </div>
      )}
    </>
  );
};

export default Layout;
