/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import "./Quiz.css";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createCategory, viewByidCategory } from "../../Api/category";
import { IoMdTrash, IoIosAddCircle } from "react-icons/io";
import AddIcon from "@mui/icons-material/Add";
import { firebase } from "../../database/firebase";
import { createQuestion } from "../../Api/question";
import { FaArrowLeft } from "react-icons/fa";

const Studentpage = () => {
  const [title, settitle] = useState("");
  const [categoryadded, setcategoryadded] = useState(false);
  const [addoption, setaddoption] = useState(false);
  const [row, setrow] = useState([
    {
      title: "",
      option1: "",
      option2: "",
      option3: "",
      option4: "",
      answer: "",
      image: "",
    },
  ]);
  const addcount = () => {
    var newobj = {
      title: "",
      option1: "",
      option2: "",
      option3: "",
      option4: "",
      answer: "",
      image: "",
    };
    setrow((prevState) => [...prevState, newobj]);
  };
  const [clicked, setclicked] = useState(false);
  const deleterow = (e) => {
    const arrayOfNumbers = row;
    const previousSecondElementOfTheArray = arrayOfNumbers.splice(1, e);
    setrow([]);
    setTimeout(() => {
      setrow(arrayOfNumbers);
    }, 200);
  };
  const changecontent = (e) => {
    row[e.target.id][e.target.name] = e.target.value;
    setrow(row);
  };
  const submitbtn = async () => {
    if (title.length === 0) {
      toast.error("Please enter Title...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      var str = title
        .toLowerCase()
        .replace(/ /g, "_")
        .replace(/[^\w-]+/g, "");
      var createdata = await createCategory({ name: title, slug: str });
      if (createdata.message === "SUCCESS") {
        setcategoryadded(true);
        sessionStorage.setItem("categoryid", createdata.data.id);
      }
    }
  };
  useEffect(() => {
    getdata();
  }, []);
  const getdata = async () => {
    var categoryid = sessionStorage.getItem("categoryid");
    var checkdata = await viewByidCategory({ id: categoryid });
    if (checkdata.length !== 0) {
      setcategoryadded(true);
      settitle(checkdata[0].name);
    }
  };
  const submitbtnnew = async () => {
    if (row.length !== 0) {
      setclicked(true);
      for (var i = 0; i < row.length; i++) {
        if (
          row[i].title.length !== 0 ||
          row[i].option1.length !== 0 ||
          row[i].option2.length !== 0 ||
          row[i].option3.length !== 0 ||
          row[i].option4.length !== 0 ||
          row[i].answer.length !== 0
        ) {
          var newobj = {
            title: row[i].title,
            option1: row[i].option1,
            option2: row[i].option2,
            option3: row[i].option3,
            option4: row[i].option4,
            answer: row[i].answer,
            image: row[i].image,
            category: sessionStorage.getItem("categoryid"),
          };
          await createQuestion(newobj);
        }
      }
      toast.success("Questions Added Successfully...", {
        autoClose: 2000,
        transition: Slide,
      });
      setclicked(false);
      sessionStorage.removeItem("categoryid");
      setTimeout(() => {
        window.location.replace("/#/quiz");
      }, 2000);
    }
  };
  const geturl = async (e) => {
    toast.info("Please Wait Image is uploading...", {
      autoClose: 2000,
      transition: Slide,
    });
    const fileSize = e.target.files[0].size;
    const fileMb = fileSize / 1024 ** 2;
    if (fileMb >= 2) {
      toast.error("Please select a file less than 2MB.", {
        autoClose: 5000,
        transition: Slide,
      });
    } else {
      let file = e.target.files;
      let file13 = new Promise((resolve, reject) => {
        var storageRef = firebase.storage().ref("course/" + file[0].name);
        storageRef.put(file[0]).then(function (snapshot) {
          storageRef.getDownloadURL().then(function (url) {
            setTimeout(() => resolve(url), 1000);
          });
        });
      });
      var imgurl1 = await file13;
      if (imgurl1 !== null) {
        toast.success("Image uploaded...", {
          autoClose: 5000,
          transition: Slide,
        });
        row[e.target.id][e.target.name] = imgurl1;
      }
    }
  };
  const addbtn = async () => {
    if (categoryadded === true) {
      setaddoption(true);
    } else {
      toast.error("Please Create Category...", {
        autoClose: 5000,
        transition: Slide,
      });
    }
  };
  return (
    <>
      <div className="Quiz">
        <div className="Quiz1">
          <div className="quiz-program">
            <div
              className="flex gap-2 items-center cursor-pointer"
              onClick={() => {
                window.location.replace("/#/quiz");
              }}
            >
              <FaArrowLeft className="text-xl" />
              <h1>Quiz Programs</h1>
            </div>
            <div className="quiz-box">
              <h3>Add New Quiz Program</h3>
              <p>Title</p>
              <input
                type="text"
                defaultValue={title}
                onChange={(e) => settitle(e.target.value)}
              />
              {categoryadded === false ? (
                <button onClick={submitbtn}>Create</button>
              ) : null}
            </div>
            <h2>Questions</h2>
            {addoption === false ? (
              <div className="Quiz-add cursor-pointer" onClick={addbtn}>
                <h1>+ Add</h1>
              </div>
            ) : (
              <div className="quiz-boxnew">
                {row.length !== 0
                  ? row.map((data, index) => (
                      <>
                        <div className="flex gap-4 items-center donoradd w-full">
                          <div className="form-control w-full mt-4">
                            <label className="label">
                              <span className="label-text text-base-content undefined font-bold">
                                Title
                              </span>
                            </label>
                            <input
                              type="text"
                              placeholder="Title"
                              className="input input-bordered w-full "
                              name="title"
                              id={index}
                              defaultValue={data.title}
                              onChange={changecontent}
                            />
                          </div>
                          <div className="form-control w-full mt-4">
                            <label className="label">
                              <span className="label-text text-base-content undefined font-bold">
                                Image (optional)
                              </span>
                            </label>
                            <input
                              type="file"
                              placeholder="Title"
                              className="input input-bordered w-full "
                              name="image"
                              id={index}
                              onChange={geturl}
                            />
                          </div>
                        </div>
                        <div className="flex gap-4 items-center donoradd w-full">
                          <div className="form-control w-full mt-4">
                            <label className="label">
                              <span className="label-text text-base-content undefined font-bold">
                                Option 1
                              </span>
                            </label>
                            <input
                              type="text"
                              placeholder="  Option 1"
                              className="input input-bordered w-full "
                              name="option1"
                              id={index}
                              defaultValue={data.option1}
                              onChange={changecontent}
                            />
                          </div>
                          <div className="form-control w-full mt-4">
                            <label className="label">
                              <span className="label-text text-base-content undefined font-bold">
                                Option 2
                              </span>
                            </label>
                            <input
                              type="text"
                              placeholder="Option 2"
                              className="input input-bordered w-full "
                              name="option2"
                              id={index}
                              defaultValue={data.option1}
                              onChange={changecontent}
                            />
                          </div>
                        </div>
                        <div className="flex gap-4 items-center donoradd w-full">
                          <div className="form-control w-full mt-4">
                            <label className="label">
                              <span className="label-text text-base-content undefined font-bold">
                                Option 3
                              </span>
                            </label>
                            <input
                              type="text"
                              placeholder=" Option 3"
                              className="input input-bordered w-full "
                              name="option3"
                              id={index}
                              defaultValue={data.option3}
                              onChange={changecontent}
                            />
                          </div>
                          <div className="form-control w-full mt-4">
                            <label className="label">
                              <span className="label-text text-base-content undefined font-bold">
                                Option 4
                              </span>
                            </label>
                            <input
                              type="text"
                              placeholder=" Option 4"
                              className="input input-bordered w-full "
                              name="option4"
                              id={index}
                              defaultValue={data.option3}
                              onChange={changecontent}
                            />
                          </div>
                        </div>
                        <div className="flex gap-4 items-center donoradd w-full">
                          <div className="form-control w-full mt-4">
                            <label className="label">
                              <span className="label-text text-base-content undefined font-bold">
                                Answer
                              </span>
                            </label>
                            <br />
                            <input
                              type="text"
                              placeholder="Answer"
                              className="input input-bordered w-1/2"
                              name="answer"
                              id={index}
                              defaultValue={data.answer}
                              onChange={changecontent}
                            />
                          </div>
                        </div>
                        <div className="flex mt-2 gap-2">
                          <IoIosAddCircle
                            className="text-2xl w-8 h-8 bg-blue-500 text-white rounded-full"
                            onClick={addcount}
                          />
                          <IoMdTrash
                            className="text-2xl p-2 w-8 h-8 bg-red-500 text-white rounded-full"
                            onClick={() => {
                              deleterow(index);
                            }}
                          />
                        </div>
                      </>
                    ))
                  : null}
                {clicked === true ? (
                  <button className="mt-5 bg-black text-white rounded w-1/2 py-2">
                    Please Wait...
                  </button>
                ) : (
                  <button
                    className="mt-5 bg-black text-white rounded w-1/2 py-2"
                    onClick={submitbtnnew}
                  >
                    Submit
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Studentpage;
