/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { updateTicket, viewallTicket } from "../Api/ticket";
import { AiOutlineClose } from "react-icons/ai";
import Modal from "react-modal";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HTMLReactParser from "html-react-parser";
import { createTicket_reply, ticket_replybyticket } from "../Api/ticket_reply";
import JoditEditor from "jodit-react";

const Ticket = () => {
  const editorRef = useRef(null);

  const [projectdata, setprojectdata] = useState([]);
  const [newmodel, setnewmodel] = useState(false);
  const [singledata, setsingledata] = useState([]);
  const [replydata, setreplydata] = useState([]);
  const [replystatus, setreplystatus] = useState(false);
  const [message, setmessage] = useState("");

  var adminid =
    sessionStorage.getItem("adminid") || localStorage.getItem("adminid");

  useEffect(() => {
    getAllticket();
  }, []);
  const getAllticket = async () => {
    var allticket = await viewallTicket();
    setprojectdata(allticket);
  };
  const openmodel = async (data) => {
    setnewmodel(true);
    setsingledata([data]);
    var checkreplydata = await ticket_replybyticket({ id: data.id });
    setreplydata(checkreplydata);
  };

  function closemodel() {
    setnewmodel(false);
    window.location.reload();
  }
  const sendbtn = async () => {
    var newobj = {
      student: singledata[0].Student.id,
      message: message,
      admin: adminid,
      read: "false",
      ticket: singledata[0].id,
      from: "admin",
    };
    var createdata = await createTicket_reply(newobj);
    if (createdata.message === "SUCCESS") {
      var checkreplydata = await ticket_replybyticket({ id: singledata[0].id });
      setreplydata(checkreplydata, "checkreplydata");
      setreplystatus(false);
    }
  };
  useEffect(() => {
    getchatdata();
  }, [newmodel]);
  const getchatdata = async () => {
    if (newmodel === true) {
      if (singledata.length !== 0) {
        var checkreplydata = await ticket_replybyticket({
          id: singledata[0].id,
        });
        setreplydata(checkreplydata);
      }
      // setTimeout(() => {
      //   getchatdata();
      // }, 2000);
    }
  };
  const closeticket = (e) => {
    var newobj = {
      status: "false",
      id: e,
    };
    updateTicket(newobj);
    setnewmodel(false);
    getAllticket();
  };
  const config = {
    // Toolbar options
    toolbar: true, // Show the toolbar
    toolbarAdaptive: false, // Adaptive toolbar based on window width
    toolbarSticky: true, // Stick the toolbar to the top of the editor
    toolbarButtonSize: "middle", // Size of toolbar buttons: 'small', 'middle', 'big'
    toolbarButtonIcons: true, // Show icons on toolbar buttons
    toolbarButtonText: false, // Show text on toolbar buttons

    // Editor appearance
    theme: "default", // Editor theme: 'default', 'dark'
    language: "en", // Editor language

    // Editor behavior
    spellcheck: true, // Enable spellchecking
    showCharsCounter: true, // Show character counter
    showWordsCounter: true, // Show word counter
    hidePlaceholderOnFocus: false, // Hide the placeholder when the editor gains focus
    showXPathInStatusbar: true, // Show the XPath of the selected element in the status bar

    // Editor content
    allowScript: true, // Allow <script> tags in the editor content
    sanitize: false, // Sanitize the editor content to remove potentially unsafe elements and attributes

    // Plugins and features
    enableDragAndDropFileToEditor: true, // Enable dragging and dropping files into the editor to insert them
    enableResizeEditor: true, // Enable resizing of the editor
    enableSearchReplace: true, // Enable search and replace functionality
    enableCodeMirror: true, // Enable CodeMirror syntax highlighting for HTML source mode
  };
  return (
    <>
      <div className="px-14 pb-5">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl py-4 font-black pb-5">My Ticket</h1>
        </div>
        <hr className="py-5 border-black" />
        <div className="grid md:grid-cols-1 grid-cols-1 gap-6">
          {projectdata.length !== 0
            ? projectdata.map((data, index) => (
                <div
                  className="flex flex-col rounded-sm justify-evenly shadow-2xl bg-white rounded-xl cursor-pointer"
                  key={index}
                  onClick={() => {
                    openmodel(data);
                  }}
                >
                  <div className="flex gap-2 p-2 items-center">
                    <img
                      src={data.Student.profile}
                      alt=""
                      className="h-12 w-12 rounded-full"
                    />
                    <div>
                      {" "}
                      <h6 className="text-sm font-bold">{data.Student.name}</h6>
                      <h6 className="text-xs">{data.Student.email}</h6>
                    </div>
                  </div>
                  <div className="px-3">
                    <div className="flex gap-4 items-center">
                      <h1 className="text-lg pt-2 font-bold">{data.title}</h1>
                      {data.status === "false" ? (
                        <h6 className="text-red-600 text-md font-bold mt-2">
                          ( Closed )
                        </h6>
                      ) : null}
                    </div>
                    <div className="pb-5">
                      <p className="mt-2 smalltextsubject">
                        {HTMLReactParser(data.subject)}
                      </p>
                    </div>
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>

      <Modal
        isOpen={newmodel}
        onRequestClose={closemodel}
        className="accountmodel"
      >
        {singledata.length !== 0 ? (
          <>
            <div className="flex justify-between px-4 py-2">
              <h2 className="font-medium text-xl">{singledata[0].title} </h2>
              <button onClick={closemodel}>
                <AiOutlineClose className="h-6 w-6" />
              </button>
            </div>
            <hr />
            <div className="px-3">
              <div className="py-5">
                <div className="flex justify-between">
                  <p className="font-bold text-xl">Subject </p>
                  {singledata[0].status === "true" ? (
                    <button
                      className="px-4 py-2 bg-red-500 rounded text-white text-sm"
                      onClick={() => {
                        closeticket(singledata[0].id);
                      }}
                    >
                      Close Ticket
                    </button>
                  ) : null}
                </div>
                <p className="mt-2">{HTMLReactParser(singledata[0].subject)}</p>
              </div>
              <hr className="py-5 border-gray-500" />
              {replydata.length !== 0 ? (
                <div className="messagediv">
                  {replydata.length !== 0
                    ? replydata.map((data, index) =>
                        data.from === "admin" ? (
                          <div className="mt-2 px-4" key={index}>
                            {" "}
                            <div className="text-right float-right bg-green-400 px-4 rounded w-fit">
                              {data.message}
                            </div>
                            <br />
                          </div>
                        ) : (
                          <p className="bg-gray-400 text-left px-4 rounded w-fit">
                            {data.message}
                          </p>
                        )
                      )
                    : null}
                </div>
              ) : null}

              {singledata[0].status === "true" ? (
                replystatus === false ? (
                  <div className="flex gap-4 mt-5">
                    <button
                      className="px-4 py-2 bg-blue-500 rounded text-white"
                      onClick={() => {
                        setreplystatus(true);
                      }}
                    >
                      Reply
                    </button>
                  </div>
                ) : (
                  <>
                    <div className="py-3">
                      <JoditEditor
                        ref={editorRef}
                        value={message}
                        config={config}
                        tabIndex={1}
                        onBlur={(newContent1) => setmessage(newContent1)}
                        className="subjecttag"
                      />
                    </div>
                    <div className="flex gap-4 mt-3">
                      <button
                        className="px-4 py-2 bg-green-500 rounded text-white"
                        onClick={sendbtn}
                      >
                        Send
                      </button>
                      <button
                        className="px-4 py-2 bg-red-500 rounded text-white"
                        onClick={() => {
                          setreplystatus(false);
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </>
                )
              ) : (
                <h6 className="text-red-600 text-center text-xl font-bold">
                  Ticket Closed
                </h6>
              )}
            </div>
          </>
        ) : null}
      </Modal>
      <ToastContainer />
    </>
  );
};

export default Ticket;
