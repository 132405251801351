import { Admin } from "../axios";

export const LoginAdmin = async (data) => {
  var LoginAdmin = await Admin.post(`/login`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return LoginAdmin;
};

export const viewByidAdmin = async (data) => {
  var viewByidAdmin = await Admin.post(`/viewByid`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewByidAdmin;
};

export const checkpasswordAdmin = async (data) => {
  var checkpasswordAdmin = await Admin.post(`/checkpassword`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return checkpasswordAdmin;
};

export const updatepasswordAdmin = async (data) => {
  var updatepasswordAdmin = await Admin.post(`/updatepassword`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return updatepasswordAdmin;
};
